import { Component, Input, OnInit } from '@angular/core';
import { Lesson } from 'models/lesson';
import { Page } from 'models/page';

@Component({
  selector: 'ngx-lesson-pages-view-cell',
  templateUrl: './lesson-pages-view-cell.component.html',
  styleUrls: ['./lesson-pages-view-cell.component.scss'],
})
export class LessonPagesViewCellComponent implements OnInit {
  @Input() value: Page[];
  @Input() rowData: Lesson;

  constructor() {}
  ngOnInit(): void {}

  get Display(): string {
    return this.value.reduce<string>(
      (pre, curr) =>
        pre ? `${pre} , ${curr.index.toString()}` : `${curr.index.toString()}`,
      null,
    );
  }
}
