import { Constructor } from '@angular/cdk/table';
import { Injectable, Provider } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class Mailer {
  private mailbox: Map<string, Map<string, any>> = new Map([]);

  mail(address: string | Constructor<any>): Map<string, any>;
  mail(address: string, key: string, mail: any);
  mail(address: string, key?: string, mail?: any): Map<string, any> {
    if (!this.mailbox.has(address)) this.mailbox.set(address, new Map([]));
    if (key != null) {
      this.mailbox.get(address).set(key, mail);
    } else if (address) {
      return this.mailbox.get(address);
    }
  }
}

export const Mail: (componentOrPath: Constructor<any> | string) => Provider = (
  args,
) => ({
  provide: 'mail',
  deps: [Mailer],
  useFactory: (mailer: Mailer) => {
    let address;
    if (typeof args === 'string') address = args;
    else address = args.name;
    return mailer.mail(address);
  },
});
