<div style="margin: 25px; position: relative;">
    <div [formGroup]="section_group" style="display: flex; flex-flow: row; column-gap: 0.5rem;">
        <div>
            <input formControlName='from' type="number" fullWidth nbInput placeholder="from">
        </div>
        <div>
            <input formControlName='to' type="number" fullWidth nbInput placeholder="to">
        </div>
        <div id="mode-switch-buttons">
            <nb-toggle [formControl]="is_teacher_control">Teacher</nb-toggle>
            <button nbButton *ngIf="mode == 'view'" status="success">
                <nb-icon icon="eye-outline"></nb-icon>
            </button>
            <button nbButton ghost *ngIf="mode == 'edit'" status="info" (click)="switchToView()">
                <nb-icon icon="eye-outline"></nb-icon>
            </button>

            <button nbButton *ngIf="mode == 'edit'" status="success">
                <nb-icon icon="edit-outline"></nb-icon>
            </button>
            <button nbButton ghost *ngIf="mode == 'view'" status="info" (click)="switchToEdit()">
                <nb-icon icon="edit-outline"></nb-icon>
            </button>

        </div>
    </div>
    <svg class="svg-track" ngxTrackEditor *ngIf="howl" [howl]="howl" style="width: 100%; height: 20;">
        <rect id="ngx-track" x="0" y="7.5" rx="2.5" width="100%" height="5"></rect>
        <rect id="ngx-section" x="0" y="7.5" rx="2.5" width="50%" height="5"></rect>
        <rect id="ngx-progress" x="0" y="7.5" rx="2.5" width="50%" height="5"></rect>
        <circle id="ngx-begin-handler" class="ngx-handler" r="10" cx="10" cy="10"></circle>
        <circle id="ngx-end-handler" class="ngx-handler" r="10" cx="10" cy="10"></circle>
    </svg>
    <nb-actions class="track-actions" size="tiny" fullWidth>
        <nb-action nbTooltip="snap begin to current time" (click)="snapBegin()" icon="arrowhead-down-outline">
        </nb-action>
        <nb-action nbTooltip="backward 5 seconds" (click)="backward(5)" icon="rewind-left-outline">
        </nb-action>
        <nb-action [disabled]="this.isStopped" (click)="stop()" icon="stop-circle-outline">
        </nb-action>
        <nb-action (click)="replaySelection()" icon="refresh-outline">
        </nb-action>
        <nb-action (click)="toggle()" [icon]=" isPlaying ? 'pause-circle-outline' : 'play-circle-outline'">
        </nb-action>
        <nb-action nbTooltip="forward 5 seconds" (click)="forward(5)" icon="rewind-right-outline">
        </nb-action>
        <nb-action nbTooltip="snap end to current time" (click)="snapEnd()" icon="arrowhead-down-outline">
        </nb-action>
    </nb-actions>
</div>