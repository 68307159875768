import {
  Component,
  DoCheck,
  EventEmitter,
  Input,
  KeyValueDiffer,
  KeyValueDiffers,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Line } from 'models/line';
import { ReadingType } from 'models/reading';

@Component({
  selector: 'ngx-line-view-list-item',
  templateUrl: './line-view-list-item.component.html',
  styleUrls: ['./line-view-list-item.component.scss'],
})
export class LineViewListItemComponent implements OnInit, DoCheck {
  @Input()
  line: Line;

  @Input('reading')
  type: ReadingType;

  @Output('onSelect')
  select_event = new EventEmitter();
  @Output('onDelete')
  delete_event = new EventEmitter();

  duration_group = new FormGroup({
    start: new FormControl({ value: 0, disabled: true }),
    end: new FormControl({ value: 0, disabled: true }),
  });

  duration_differ: KeyValueDiffer<any, any>;

  constructor(private key_value_differs: KeyValueDiffers) {
    this.duration_differ = this.key_value_differs.find({}).create();
  }

  ngOnInit(): void {
    this.repatch();
  }
  ngDoCheck() {
    const diff = this.duration_differ.diff(this.line.duration[this.type]);
    if (diff) {
      this.repatch();
    }
  }

  public repatch() {
    this.duration_group.patchValue(
      {
        ...this.line.duration[this.type],
      },
      { emitEvent: false },
    );
  }

  public onDelete() {
    this.delete_event.emit();
  }
  public onSelect() {
    this.select_event.emit();
  }
}
