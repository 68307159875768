import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Voucher } from 'models/vouchers/voucher';

@Component({
  selector: 'fourqan-vouchers-prime-table',
  templateUrl: './vouchers-prime-table.component.html',
  styleUrls: ['./vouchers-prime-table.component.scss'],
})
export class VouchersPrimeTableComponent {
  @Input() totalCount!: number;
  @Input() data!: Voucher[];
  @Input() loading: boolean = false;

  @Output() pageEvent = new EventEmitter<{ limit: number; skip: number }>();
  @Output() add = new EventEmitter();
  @Output() edit = new EventEmitter<Voucher>();

  @Output() codeEvent = new EventEmitter<string | null>();
  @Output() titleEvent = new EventEmitter<string | null>();
  @Output() statusEvent = new EventEmitter<0 | 1 | null>();

  readonly COLUMNS: Array<string> = [
    'Title',
    'PromoCode',
    'MaxUsage',
    'Usage',
    'MaxUsage per user',
    'Starts at',
    'Ends at',
    'Disabled',
  ];

  readonly HEADER_FILLED_COLUMNS: Array<string> = ['', '', '', '', ''];

  filteredTitle: string | null = null;
  filteredCode: string | null = null;
  filteredStatus: 'YES' | 'NO' | '' = '';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public changePage(pageObject: any): void {
    this.pageEvent.emit({ limit: pageObject.rows, skip: pageObject.first });
  }

  public onAddVoucher(): void {
    this.add.emit();
  }

  public onEditVoucher(voucher: Voucher): void {
    this.edit.emit(voucher);
  }

  public changeStatus(status: string): void {
    this.statusEvent.emit(status === 'YES' ? 1 : status === 'NO' ? 0 : null);
  }

  public changeTitle(title: string | null): void {
    this.titleEvent.emit(title);
  }

  public changeCode(code: string | null): void {
    this.codeEvent.emit(code);
  }
}
