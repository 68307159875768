<p-calendar
  *ngIf="monthOnly"
  view="month"
  name="{{ maxDate }}"
  [(ngModel)]="dateControl"
  [showIcon]="true"
  [minDate]="minDate"
  [maxDate]="maxDate"
  dateFormat="MM yy"
  [readonlyInput]="true"
  (ngModelChange)="emitDateChange()"
></p-calendar>
<p-calendar
  *ngIf="!monthOnly"
  name="{{ maxDate }}"
  [(ngModel)]="dateControl"
  [showIcon]="true"
  [minDate]="minDate"
  [maxDate]="maxDate"
  dateFormat="MM dd, yy"
  [readonlyInput]="true"
  (ngModelChange)="emitDateChange()"
></p-calendar>
