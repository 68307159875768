/* eslint-disable prefer-const */
import { formatDate } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NbDateService } from '@nebular/theme';
import { OrderProfitChartSummary } from 'app/@core/data/orders-profit-chart';
import { ProfitChart } from 'app/@core/data/profit-chart';
import { ExcelService } from 'app/services/excel.service';
import { SubscriptionsService } from 'app/services/subscriptions.service';
import { UserSubscriptionsService } from 'app/services/user-subscriptions.service';
import { Entity } from 'models/entity';
import { Statistic } from 'models/statistic';
import { Subscription } from 'models/subscription';
import { User } from 'models/user';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { ProfitChartComponent } from '../../components/charts/profit-chart.component';
import { DashboardService } from './dashboard.service';
import { CompareColumnChartData } from 'types/chart-types';
import { EMPTY_PAGINATION_OBJ, PaginationDto } from 'dtos/pagination.dto';
import { catchError, switchMap, takeUntil, tap } from 'rxjs/operators';

interface DataTable {
  name: string;
  planName: string;
  startDate: Date;
  price: number;
  discount: number;
  paied: number;
  paymentReference?: string;
}
@Component({
  selector: 'ngx-dashboard-charts-container',
  templateUrl: './dashboard-charts-container.component.html',
  styleUrls: ['./dashboard-charts-container.component.scss'],
})
export class DashboardChartsContainerComponent implements OnInit, OnDestroy {
  private alive = true;
  statistic: Statistic[] = [];
  form = new FormGroup({
    from: new FormControl(),
    to: new FormControl(),
    slectOption: new FormControl(),
    checkBox: new FormGroup({
      hafs: new FormControl(false),
      warsh: new FormControl(false),
      caloun: new FormControl(false),
    }),
  });

  subscriptions: Entity<Subscription[]> = new Entity([]);

  claimedValue$ = new BehaviorSubject<number>(0);
  discountedValue$ = new BehaviorSubject<number>(0);

  datesList$ = new BehaviorSubject<Array<string>>([]);
  couponsValuesList$ = new BehaviorSubject<Array<CompareColumnChartData>>([]);

  datesGroup!: FormGroup;
  today: Date = new Date();
  fourYearsAgo = new Date(new Date().setMonth(new Date().getMonth() - 48));
  readonly FILTER_CONTROLS = {
    start: 'start',
    end: 'end',
  };

  // pagination
  title!: string;
  user!: string;
  limit: number = 10;
  skip: number = 0;
  code!: string;
  tableDatesGroup!: FormGroup;

  dataTable = new BehaviorSubject<DataTable[]>([]);
  createdCoupons$ = new BehaviorSubject<{ value: number; text: string }[]>([
    { value: 0, text: 'Total' },
    { value: 0, text: 'Active' },
  ]);
  benefitedUsers$ = new BehaviorSubject<{ value: number; text: string }[]>([
    { value: 0, text: 'Benefited Users' },
  ]);
  unusedCoupons$ = new BehaviorSubject<{ value: number; text: string }[]>([
    { value: 0, text: 'Total' },
  ]);

  chartPanelSummary = new BehaviorSubject<OrderProfitChartSummary[]>([
    {
      title: 'Subscription Sold',
      value: 0,
    },
    {
      title: 'Value Of Subscription Sold',
      value: 0,
    },
    {
      title: 'Total Discount Value ',
      value: 0,
    },
    {
      title: 'income',
      value: 0,
    },
  ]);
  ///
  profitChartData = new BehaviorSubject<ProfitChart>({
    chartLabel: [''],
    data: [[0], [0], [0]],
  });

  vouchers$ = new BehaviorSubject<
    Array<{
      createdAt: string;
      originalCost: number;
      discountedCost: number;
      promocode: {
        title: string;
        code: string;
        endDate: string;
      };
      user: {
        userName: string;
      };
    }>
  >([]);
  private voucherPagination$ = new BehaviorSubject<PaginationDto>(
    EMPTY_PAGINATION_OBJ,
  );
  private destroy$ = new Subject<void>();
  totalCount = new BehaviorSubject<number>(10);
  loading = new BehaviorSubject<boolean>(true);

  users: User[] = [];
  @ViewChild('profitChart', { static: true }) profitChart: ProfitChartComponent;

  constructor(
    private readonly _fb: FormBuilder,
    protected dateService: NbDateService<Date>,
    private adminService: UserSubscriptionsService,
    private subscription_Service: SubscriptionsService,
    private excelService: ExcelService,
    private readonly _dashboardService: DashboardService,
  ) {
    this.datesGroup = this._fb.group({
      start: this._fb.control(this.fourYearsAgo),
      end: this._fb.control(new Date()),
    });
    this.tableDatesGroup = this._fb.group({
      start: this._fb.control(this.fourYearsAgo),
      end: this._fb.control(new Date()),
    });

    this._dashboardService.getCreatedCoupons().subscribe((res) => {
      this.createdCoupons$.next([
        { value: res.totalCount, text: 'Total' },
        { value: res.activeCount, text: 'Active' },
      ]);
    });

    this._dashboardService.getBenefitedUsers().subscribe((res) => {
      this.benefitedUsers$.next([
        { value: res.count, text: 'Benefited Users' },
      ]);
    });

    this._dashboardService.getUnusedCoupons().subscribe((res) => {
      this.unusedCoupons$.next([
        { value: res.unusedPromocodeCount, text: 'Total' },
      ]);
    });

    this._dashboardService
      .getSubscriptionPie(
        this.getDateControl('start'),
        this.getDateControl('end'),
      )
      .subscribe((res) => {
        this.claimedValue$.next(+res.totalSumWithDiscount.toFixed(0));
        this.discountedValue$.next(
          +(res.totalSum - res.totalSumWithDiscount).toFixed(0),
        );
      });

    this._dashboardService
      .getSubscriptionColumns(
        this.getDateControl('start'),
        this.getDateControl('end'),
      )
      .subscribe((res) => {
        this.datesList$.next(
          res.labels.map((date) =>
            new Date(date).toISOString().substring(0, 7),
          ),
        );
        this.couponsValuesList$.next(res.data);
      });
  }

  public dateChanged(
    changedField: 'start' | 'end',
    newDate: Date | null,
  ): void {
    this.datesGroup.controls[changedField].setValue(newDate);
    this._dashboardService
      .getSubscriptionPie(
        this.getDateControl('start'),
        this.getDateControl('end'),
      )
      .subscribe((res) => {
        this.claimedValue$.next(res.totalSumWithDiscount);
        this.discountedValue$.next(res.totalSum - res.totalSumWithDiscount);
      });

    this._dashboardService
      .getSubscriptionColumns(
        this.getDateControl('start'),
        this.getDateControl('end'),
      )
      .subscribe((res) => {
        this.datesList$.next(
          res.labels.map((date) =>
            new Date(date).toISOString().substring(0, 7),
          ),
        );
        this.couponsValuesList$.next(res.data);
      });
  }

  public tableDateChanged(
    changedField: 'start' | 'end',
    newDate: Date | null,
  ): void {
    this.tableDatesGroup.controls[changedField].setValue(newDate);
    this.updatePagination();
  }

  private getDateControl(date: 'start' | 'end'): Date {
    return this.datesGroup.controls[date].value;
  }

  private fetchVouchers(): void {
    this.voucherPagination$
      .pipe(
        switchMap((pagination: PaginationDto) => {
          return this._dashboardService
            .getPromocodeUsage(
              pagination.limit,
              pagination.skip,
              pagination.from ?? this.fourYearsAgo,
              pagination.to ?? new Date(),
              this.user,
              this.title,
              this.code,
            )
            .pipe(
              tap((voucherList) => {
                this.loading.next(false);
                this.vouchers$.next(voucherList.promocodeUsages);
                this.totalCount.next(voucherList.totalCount);
              }),
              catchError(() => {
                this.loading.next(false);
                return of();
              }),
              takeUntil(this.destroy$),
            );
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.alive = false;
  }

  public changePage(event: { limit: number; skip: number }): void {
    this.limit = event.limit;
    this.skip = event.skip;
    this.updatePagination();
  }

  public searchForTitle(title: string | null): void {
    this.title = title;
    this.updatePagination();
  }

  public searchForName(name: string | null): void {
    this.user = name;
    this.updatePagination();
  }

  public searchForCode(code: string | null): void {
    this.code = code;
    this.updatePagination();
  }

  public updatePagination(): void {
    this.loading.next(true);
    const pagination: PaginationDto = {
      limit: this.limit,
      skip: this.skip,
      from: this.tableDatesGroup.controls[this.FILTER_CONTROLS.start].value,
      to: this.tableDatesGroup.controls[this.FILTER_CONTROLS.end].value,
      title: this.title,
      code: this.code,
      disabled: null,
      username: this.user,
    };
    this.voucherPagination$.next(pagination);
  }

  generateExcel() {
    const usersTable = this.dataTable.getValue();
    this.excelService.generateExcel(usersTable, 'UsersDashboard');
  }
  ngOnInit() {
    this.fetchingData();
    this.fetchVouchers();
    /// spmet data from controlers
    this.form.valueChanges.subscribe((item) => {
      this.fetchingData(
        [...this.setRecitals(item.checkBox)],
        item.from,
        item.to,
        item.slectOption,
      );
    });
    this.subscription_Service.list().subscribe((item) => {
      this.subscriptions.load(item);
    });
  }
  setRecitals(recital: any): any {
    const recitals = [];
    if (recital.hafs) {
      recitals.push('hafs');
    }
    if (recital.warsh) {
      recitals.push('warsh');
    }
    if (recital.caloun) {
      recitals.push('caloun');
    }
    return recitals;
  }

  fetchingData(
    recitals?: string[],
    from?: Date,
    to?: Date,
    subscriptionId?: number,
  ): any {
    this.adminService
      .getStatistics(recitals, from, to, subscriptionId)
      .subscribe((item) => {
        this.formattingData(item);
      });
  }

  formattingData(statistics: Statistic[]): any {
    let {
      valueSubscribtionSold,
      totalDiscount,
      income,
      dataTable,
      planPriceData,
      incomeData,
      discountData,
      profitChartData,
      subscribtionSold,
    }: {
      valueSubscribtionSold: number;
      totalDiscount: number;
      income: number;
      dataTable: DataTable[];
      planPriceData: any[];
      incomeData: any[];
      discountData: any[];
      profitChartData: ProfitChart;
      subscribtionSold: number;
    } = this.initVariable(statistics);

    statistics.map((item, index) => {
      index > 0 &&
      formatDate(item.creatAt, 'MM/dd/yyyy', 'en_US') ==
        formatDate(statistics[index - 1].creatAt, 'MM/dd/yyyy', 'en_US')
        ? ({ valueSubscribtionSold, totalDiscount, income } = this.inSameDay(
            valueSubscribtionSold,
            item,
            totalDiscount,
            income,
            dataTable,
            planPriceData,
            incomeData,
            discountData,
          ))
        : ({ valueSubscribtionSold, totalDiscount, income } = this.notInSameDay(
            valueSubscribtionSold,
            item,
            totalDiscount,
            income,
            dataTable,
            profitChartData,
            planPriceData,
            incomeData,
            discountData,
          ));
    });
    this.fillFormattingData(
      profitChartData,
      planPriceData,
      incomeData,
      discountData,
      dataTable,
      subscribtionSold,
      valueSubscribtionSold,
      totalDiscount,
      income,
    );
  }

  private initVariable(statistics: Statistic[]): any {
    const subscriptionSold = statistics.length;
    const valueSubscribtionSold = 0;
    const totalDiscount = 0;
    const income = 0;
    const dataTable: DataTable[] = [];
    const planPriceData = [];
    const incomeData = [];
    const discountData = [];
    const profitChartData: ProfitChart = { chartLabel: [''], data: [] };
    return {
      valueSubscribtionSold,
      totalDiscount,
      income,
      dataTable,
      planPriceData,
      incomeData,
      discountData,
      profitChartData,
      subscribtionSold: subscriptionSold,
    };
  }

  private fillFormattingData(
    profitChartData: ProfitChart,
    planPriceData: any[],
    incomeData: any[],
    discountData: any[],
    dataTable: DataTable[],
    subscribtionSold: number,
    valueSubscribtionSold: number,
    totalDiscount: number,
    income: number,
  ): any {
    profitChartData.chartLabel.shift();
    profitChartData.data = [
      [...planPriceData],
      [...incomeData],
      [...discountData],
    ];
    this.dataTable.next(dataTable);
    this.profitChartData.next(profitChartData);
    this.chartPanelSummary.next([
      {
        title: 'Subscribtion Sold',
        value: subscribtionSold,
      },
      {
        title: 'Value Of Subscribtion Sold',
        value: valueSubscribtionSold,
      },
      {
        title: 'Total Discount Value ',
        value: totalDiscount,
      },
      {
        title: 'income',
        value: income,
      },
    ]);
  }

  private notInSameDay(
    valueSubscribtionSold: number,
    item: Statistic,
    totalDiscount: number,
    income: number,
    dataTable: DataTable[],
    profitChartData: ProfitChart,
    planPriceData: any[],
    incomeData: any[],
    discountData: any[],
  ): any {
    valueSubscribtionSold += item.price;
    totalDiscount += item.discount;
    income += item.paied;
    dataTable.push({
      name: item.user.userName,
      planName: item.plan.name,
      startDate: item.creatAt,
      price: item.price,
      discount: item.discount,
      paied: item.paied,
      paymentReference: item.paymentReference,
    } as DataTable);
    profitChartData.chartLabel.push(
      formatDate(item.creatAt, 'MM/dd/yyyy', 'en_US'),
    );
    planPriceData.push(item.price);
    incomeData.push(item.paied);
    discountData.push(item.discount);
    return { valueSubscribtionSold, totalDiscount, income };
  }

  private inSameDay(
    valueSubscribtionSold: number,
    item: Statistic,
    totalDiscount: number,
    income: number,
    dataTable: DataTable[],
    planPriceData: any[],
    incomeData: any[],
    discountData: any[],
  ): any {
    valueSubscribtionSold += item.price;
    totalDiscount += item.discount;
    income += item.paied;

    dataTable.push({
      name: item.user.userName,
      planName: item.plan.name,
      startDate: item.creatAt,
      price: item.price,
      discount: item.discount,
      paied: item.paied,
      paymentReference: item.paymentReference,
    } as DataTable);
    const prevPlan = planPriceData.pop();
    const prevIncome = incomeData.pop();
    const prevDiscount = discountData.pop();

    planPriceData.push(prevPlan + item.price);
    incomeData.push(prevIncome + item.paied);
    discountData.push(prevDiscount + item.discount);
    return { valueSubscribtionSold, totalDiscount, income };
  }
}
