import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class UserTokensService {
  private _admin_endpoint = `${environment.server}/users/admin`;
  private _admin_delete_token = `${environment.server}/users/admin/tokens`;
  constructor(private http: HttpClient) {}

  deleteToken(id) {
    return this.http.delete(`${this._admin_delete_token}/${id}`);
  }
}
