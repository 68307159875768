import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Voucher } from 'models/vouchers/voucher';
import { Observable } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { VoucherAdapters } from './voucher-adapter';
import { VoucherDTO } from './voucher-dto';
import { PaginationDto } from 'dtos/pagination.dto';

@Injectable({
  providedIn: 'root',
})
export class VoucherGateway {
  private _endpoint = `${environment.server}/promocode`;

  constructor(private _http: HttpClient) {}

  list(filter?: PaginationDto): Observable<{ data: Voucher[]; count: number }> {
    let params = new HttpParams({});
    if (filter?.from) params = params.set('from', filter.from.toString());
    if (filter?.to) params = params.set('to', filter.to.toString());
    if (filter?.code && filter.code !== '')
      params = params.set('code', filter.code);
    if (filter?.disabled !== null)
      params = params.set('disabled', filter.disabled);
    if (filter?.limit) params = params.set('limit', filter.limit);
    if (filter?.skip) params = params.set('skip', filter.skip);
    if (filter?.title && filter.title !== '')
      params = params.set('title', filter.title);

    return this._http
      .get<{ data: VoucherDTO.Base[]; count: number }>(this._endpoint, {
        params: params,
      })
      .pipe(
        map((vouchers) => {
          return {
            data: VoucherAdapters.vouchers(vouchers.data),
            count: vouchers.count,
          };
        }),
      );
  }

  add(body: VoucherDTO.CreateBody): Observable<unknown> {
    return this._http.post(this._endpoint, body).pipe(delay(2000));
  }

  edit(voucher_id: number, body: VoucherDTO.UpdateBody): Observable<unknown> {
    return this._http.put(`${this._endpoint}/${voucher_id}`, body);
  }

  exists(code: string): Observable<boolean> {
    return this._http.get<boolean>(`${this._endpoint}/exists`, {
      params: { code },
    });
  }
}
