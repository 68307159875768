<form [formGroup]="this.group_control" #form="ngForm" aria-labelledby="title">

    <div class="form-control-group">
        <label class="label" for="input-email">{{'AUTH.EMAIL INPUT LABEL' | translate}}:</label>
        <input [status]="EmailControl.dirty ? (EmailControl.invalid  ? 'danger' : 'success') : 'basic'"
            formControlName="email" nbInput fullWidth id="input-email" placeholder="youremail@gmail.com"
            fieldSize="large" autofocus>
        <ng-container *ngIf="EmailControl.invalid && EmailControl.touched">
            <p class="caption status-danger" *ngIf="EmailControl.errors.required">
                {{'AUTH.REQUIRED EMAIL ERROR' | translate}}
            </p>
        </ng-container>
    </div>

    <div class="form-control-group">
        <span class="label-with-link">
            <label class="label" for="input-password">{{'AUTH.PASSWORD INPUT LABEL' | translate}}:</label>
            <a class="forgot-password caption-2"
                routerLink="../request-password">{{ 'AUTH.FORGOT PASSWORD CAPTION' | translate }}?</a>
        </span>
        <input formControlName="password"
            [status]="PasswordControl.dirty ? (PasswordControl.invalid  ? 'danger' : 'success') : 'basic'" nbInput
            fullWidth type="password" id="input-password" placeholder="password" fieldSize="large">
        <ng-container *ngIf="PasswordControl.invalid && PasswordControl.touched">
            <p class="caption status-danger" *ngIf="PasswordControl.errors?.required">
                {{'AUTH.REQUIRED PASSWORD ERROR' | translate}}
            </p>
        </ng-container>

    </div>

    <div class="form-control-group accept-group">
        <nb-checkbox formControlName="remember">{{'AUTH.REMEMBER ME CAPTION' | translate}}</nb-checkbox>
    </div>
</form>