<apx-chart
  [ngClass]="{
    'legend-right-or-left':
      legendPosition === 'right' || legendPosition === 'left'
  }"
  [series]="chartOptions.series ?? []"
  [chart]="
    chartOptions.chart ?? {
      height: 150,
      type: 'donut'
    }
  "
  [labels]="this.labelsList"
  [legend]="{ show: this.legendOn, position: this.legendPosition }"
  [colors]="chartOptions.colors ?? []"
  [tooltip]="chartOptions.tooltip"
></apx-chart>
