<nb-card>
    <nb-card-header>
        <div style="display: flex; flex-flow: row nowrap;">
            <span>Teachers</span>
            <div class="header-actions"> </div>
        </div>
    </nb-card-header>
    <nb-card-body>
        <ng2-smart-table [settings]="settings" [source]="source">
        </ng2-smart-table>
    </nb-card-body>
</nb-card>