import { CountryAdapter } from 'core/gateways/country/country-adapter';
import { CountryDTO } from 'core/gateways/country/country-dto';
import { Properties } from 'utils/properties.type';

export class Country {
  id: number;
  name: string;
  ISO: string;

  constructor(spec: Properties<Country>) {
    Object.assign(this,spec);
  }

  static fromDto(dto: CountryDTO.Base){
    const params = {
      id: dto.id,
      name: dto.nameEn,
      ISO: dto.ISO
    };
    return new Country(params);
  }

  /**
   * @deprecated used in classes with old architecture instead use {@link CountryAdapter.country}
   */
  static baseDTO(dto: CountryDTO.Base): Country;
  /**
   * @deprecated used in classes with old architecture instead use {@link CountryAdapter.countries}
   */
  static baseDTO(dto: CountryDTO.Base[]): Country[];
  static baseDTO(
    dto: CountryDTO.Base | CountryDTO.Base[],
  ): Country | Country[] {
    if (Array.isArray(dto)) {
      return CountryAdapter.countries(dto);
    } else return CountryAdapter.country(dto);
  }
}
