import { Component, isDevMode, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { FormsErrorNotifierService } from 'app/shared/forms-error-notifier/forms-error-notifier.service';
import { CountryFacade } from 'core/facade/country-facade.service';
import { Country } from 'models/country';
import { Recital } from 'models/page';
import { Subscription } from 'models/subscription';
import { SubscriptionPricing } from 'models/subscription-pricing';
import { DiscountType } from 'models/subscription-pricing-discount';
import { combineLatest, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { SubscriptionEditDialogErrorsToasterService } from './error-toaster.service';
import { SubscriptionEditDialogErrorsService } from './errors-handlers.service';

@Component({
  selector: 'ngx-subscription-edit-dialog',
  templateUrl: './subscription-edit-dialog.component.html',
  styleUrls: ['./subscription-edit-dialog.component.scss'],
  providers: [
    FormsErrorNotifierService,
    SubscriptionEditDialogErrorsService,
    SubscriptionEditDialogErrorsToasterService,
  ],
})
export class SubscriptionEditDialogComponent implements OnInit {
  subscription: Subscription;

  form = new FormGroup({
    nameAr: new FormControl('', [Validators.required]),
    nameFr: new FormControl('', [Validators.required]),
    nameEn: new FormControl('', [Validators.required]),
    descriptionEn: new FormControl('', [Validators.required]),
    descriptionAr: new FormControl('', [Validators.required]),
    descriptionFr: new FormControl('', [Validators.required]),
    discount: new FormGroup({
      value: new FormControl(0, [Validators.required]),
      type: new FormControl(DiscountType.Fixed),
    }),
    duration: new FormGroup({
      years: new FormControl('', [Validators.required]),
      months: new FormControl('', [Validators.required]),
      days: new FormControl('', [Validators.required]),
      hours: new FormControl('', [Validators.required]),
    }),
    allowance: new FormGroup({
      years: new FormControl(),
      months: new FormControl(),
      days: new FormControl(),
      hours: new FormControl(),
    }),
    countries: new FormControl([]),
    login_times: new FormControl(0, [Validators.required]),
    max_active_tokens: new FormControl(0, [Validators.required]),
    [Recital.Hafs]: new FormGroup({
      default: new FormControl(false, [Validators.required]),
      price: new FormControl(0, [Validators.required]),
    }),
    [Recital.Qanoun]: new FormGroup({
      default: new FormControl(false, [Validators.required]),
      price: new FormControl(0, [Validators.required]),
    }),
    [Recital.Warsh]: new FormGroup({
      default: new FormControl(false, [Validators.required]),
      price: new FormControl(0, [Validators.required]),
    }),
    discounts: new FormArray([
      new FormGroup({
        count: new FormControl(2),
        type: new FormControl(DiscountType.Fixed, [Validators.required]),
        discount: new FormControl(0, [Validators.required]),
      }),
      new FormGroup({
        count: new FormControl(3),
        type: new FormControl(DiscountType.Fixed, [Validators.required]),
        discount: new FormControl(0, [Validators.required]),
      }),
    ]),
    deactivated: new FormControl(false, [Validators.required]),
  });

  countries_auto_ctrl = new FormControl();
  countries_options$: Observable<Country[]>;
  private _countries: Country[] = [];

  constructor(
    private _ref: NbDialogRef<any>,
    private country_facade: CountryFacade,
    private _formsErrorNotifierService: FormsErrorNotifierService,
    errorsService: SubscriptionEditDialogErrorsService,
  ) {
    errorsService.addHandlers();
    this.countries_options$ = combineLatest([
      this.country_facade
        .list()
        .pipe(tap((countries) => (this._countries = countries))),
      this.countries_auto_ctrl.valueChanges,
    ]).pipe(
      map(([countries, search]) => {
        return this.UnselectedCountries.filter(
          this._getCountryFilterFor(search),
        );
      }),
    );
  }

  ngOnInit(): void {
    this._patchForm(this.subscription);
  }

  onDiscountTypeChange(value: DiscountType) {
    this.form.patchValue({
      discount: {
        type: value,
      },
    });
  }

  close() {
    this._ref.close();
  }
  submit() {
    if (this.form.invalid) {
      this._showErrors();
      return;
    }
    this._ref.close({
      form: {
        ...this._formValue(),
      },
    });
  }

  addCountry(ISO: string) {
    ISO = ISO.toUpperCase();
    const country = this.UnselectedCountries.find(
      (_country) => _country.ISO === ISO,
    );
    if (!country) {
      return;
    }
    const selected = this.SelectedCountries.concat(country);
    this.form.get('countries').setValue(selected);
    this.countries_auto_ctrl.setValue('');
  }
  removeCountry(ISO: string) {
    ISO = ISO.toUpperCase();
    const selected = this.SelectedCountries.concat();
    const index = selected.findIndex((country) => country.ISO === ISO);
    if (index === -1) {
      return;
    }
    selected.splice(index, 1);
    this.form.get('countries').setValue(selected);
  }

  private _formValue(): SubscriptionEditDialogResult {
    return this.form.value;
  }

  private _patchForm(subscription?: Subscription) {
    if (!subscription) {
      return;
    }
    const fv: Partial<SubscriptionFormValue> = {
      discount: {
        type: subscription.discount.type,
        value: subscription.discount.value * 100,
      },
      allowance: subscription.allowanceTime,
      duration: subscription.duration,
      countries: subscription.countries,
      login_times: subscription.login_times,
      descriptionAr: subscription.descriptionAr,
      descriptionEn: subscription.descriptionEn,
      descriptionFr: subscription.descriptionFr,
      max_active_tokens: subscription.max_active_tokens,
      nameAr: subscription.nameAr,
      nameEn: subscription.nameEn,
      nameFr: subscription.nameFr,
      deactivated: subscription.deactivated,
      discounts: subscription.pricings_discounts
        .sort((a, b) => a.count - b.count)
        .map(({ type, discount, count }) => ({
          count,
          discount: type === DiscountType.Fixed ? discount : discount * 100,
          type,
        })),
    };
    subscription.pricings.forEach((pricing) => {
      fv[pricing.recital] = fromPricing(pricing);
    });
    this.form.patchValue(fv);

    function fromPricing(pricing: SubscriptionPricing): RecitalFormValue {
      return {
        default: pricing.enabled_by_default,
        price: pricing.price,
      };
    }
  }

  private _getCountryFilterFor(search: string) {
    return (country: Country) => {
      return (
        country.name.toLowerCase().includes(search.toLowerCase()) ||
        country.ISO.toLowerCase().includes(search.toLowerCase())
      );
    };
  }

  private _showErrors() {
    this._formsErrorNotifierService.validateAndNotifyFormGroup(this.form);
  }

  get SelectedCountries(): Country[] {
    return this.form.get('countries').value;
  }

  get UnselectedCountries(): Country[] {
    const selected_ISOs = this.SelectedCountries.map(({ ISO }) => ISO);
    return this._countries.filter(({ ISO }) => !selected_ISOs.includes(ISO));
  }

  get Recitals(): Recital[] {
    return Object.values(Recital);
  }

  get DiscountsCtrl(): FormArray {
    return this.form.get('discounts') as FormArray;
  }

  get $Discount(): typeof DiscountType {
    return DiscountType;
  }

  get DiscountType(): DiscountType {
    const type = this.form.get('discount').get('type').value;
    return type;
  }
  get DiscountTypeIcon(): string {
    const type = this.form.get('discount').get('type').value;
    switch (type) {
      case DiscountType.Fixed:
        return 'credit-card';
      case DiscountType.Percentage:
        return 'percent';
      default:
        if (isDevMode()) {
          throw new Error(`no icon for ${type}`);
        }
        return 'credit-card';
    }
  }
}

export interface SubscriptionEditDialogData {
  subscription: Subscription;
}
export interface SubscriptionEditDialogResult {
  form: SubscriptionFormValue;
}

export interface SubscriptionFormValue
  extends Record<Recital, RecitalFormValue> {
  nameAr: string;
  nameFr: string;
  nameEn: string;
  descriptionEn: string;
  descriptionAr: string;
  descriptionFr: string;
  discount: {
    type: DiscountType;
    value: number;
  };
  duration: {
    years: number;
    months: number;
    days: number;
    hours: number;
  };
  allowance: {
    years: number;
    months: number;
    days: number;
    hours: number;
  };
  countries: Country[];
  login_times: number;
  max_active_tokens: number;
  discounts: PricingDiscountFormValue[];
  deactivated: boolean;
}

export interface RecitalFormValue {
  default: boolean;
  price: number;
}

export interface PricingDiscountFormValue {
  count: number;
  /**
   * @description between 0 and 100
   */
  discount: number;
  type?: DiscountType;
}
