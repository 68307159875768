import { NbMenuItem } from '@nebular/theme';

const Menu: NbMenuItem[] = [
  {
    title: 'Dashboard',
    icon: 'file-outline',
    link: 'dashboard',
    hidden:true
  },
  {
    title: 'Lessons',
    icon: 'file-outline',
    link: 'lessons',
  },
  {
    title: 'Pages',
    icon: 'file-outline',
    link: 'pages',
  },
  {
    title: 'Users',
    icon: 'people-outline',
    link: 'users',
  },
  {
    title: 'Subscriptions',
    icon: 'shopping-bag-outline',
    link: 'subscriptions',
  },
  {
    title: 'Vouchers',
    icon: 'pricetags-outline',
    link: 'vouchers',
  },
  {
    title: 'Ads',
    icon: 'tv-outline',
    link: 'ads',
  },
];

export default Menu;
