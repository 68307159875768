import { Component, Input, OnInit } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { UserTokensService } from 'app/services/user_tokens.service';
import { UserToken } from 'models/userToken';
import { LocalDataSource } from 'ng2-smart-table';
import { filter, switchMap } from 'rxjs/operators';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'ngx-user-tokens-listing',
  templateUrl: './user-tokens-listing.component.html',
  styleUrls: ['./user-tokens-listing.component.scss']
})
export class UserTokensListingComponent implements OnInit {
  settings={}
  source = new LocalDataSource([]);


  @Input()
  public set tokens(v: UserToken[]) {
    this._token = v ||[];
    this.loadTokens();
  }
  public get tokens(): UserToken[] {
    return this._token;
  }
  private _token:UserToken[]
  constructor(private dialog_service: NbDialogService,
    private _user_token_gateway:UserTokensService) { }

  ngOnInit(): void {
    this.initSettings();
  }

  initSettings() {
    this.settings['hideSubHeader'] = true;
    this.settings['actions'] = {
      add: false,
      edit: false,
      delete: true,
    };
    this.settings['delete'] = {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true,
    };
    this.settings['columns'] = {
      id: {
        title: 'ID',
        type: 'text',
      },
      granted_subscription: {
        title: 'Granted Subscription',
        type: 'text',
      },
      created_at: {
        title: 'CreatedAt',
        type: 'text',
      },
      deleted_at: {
        title: 'DeletedAt',
        type: 'text',
      },
      updated_at: {
        title: 'UpdatedAt',
        type: 'text',
      },
      user_id:{
        title:'User Id',
        type:'text'
      }
      
    } as Partial<Record<keyof UserToken, any>>;
  }
  loadTokens() {
    const data = this.tokens.map((token) => ({
      id: token.id,
      granted_subscription:token.granted_subscription,
      created_at: token.created_at? token.created_at.toDateString():'',
      deleted_at:token.deleted_at? token.deleted_at.toDateString():'',
      updated_at: token.updated_at?token.updated_at.toDateString():'',
      user_id:token.user_id
    }));
    this.source.load(data);
  }
  onDeleteToken($event:any):void{
    const ref = this.dialog_service.open(ConfirmationDialogComponent);
    ref.onClose
    .pipe(
      filter((confirmed) => confirmed),
      switchMap(() => {
        return this._user_token_gateway.deleteToken(
          $event.data.id,
        );
      }),
    )
    .subscribe({
      next: () => $event.confirm.resolve(),
      error: () => $event.confirm.reject(),
    });
  }
}
