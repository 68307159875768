<nb-card [nbSpinner]="loading" nbSpinnerSize="giant">
  <nb-card-header>
    <div style="display: flex; flex-flow: row nowrap">
      <!-- <nb-user
        [picture]="user?.photo"
        size="giant"
        [name]="user?.username"
        [title]="user?.role | ngxCapitalize"
      ></nb-user> -->
      <div class="d-flex gap-1rem align-items-center">
        <img
          src="assets/icons/null.svg"
          alt="user image"
          style="width: 40px; height: 40px; border-radius: 50%"
        />
        <div class="d-flex flex-column">
          <span>
            {{ user?.username }}
          </span>
          <span>
            {{ user?.role | ngxCapitalize }}
          </span>
        </div>
      </div>

      <div class="header-actions">
        <div class="tool-tip-icon">
          <img
            style="width: 24px; height: 24px"
            [src]="'assets/icons/' + user?.subscriptionType + '.svg'"
            alt="subscription type icon"
          />
          <span class="tool-tip">{{ user?.subscriptionType }}</span>
        </div>

        <button
          class="action"
          (click)="mode === 'edit' ? onCancelEdit() : onToggleUserActivity()"
          nbButton
          ghost
          [status]="user?.is_active ? 'danger' : 'success'"
        >
          <nb-icon *ngIf="mode === 'edit'" icon="slash-outline"></nb-icon>
          <ng-container *ngIf="mode === 'display'">
            {{ user?.is_active ? 'Deactivate' : 'Activate' }}
          </ng-container>
        </button>
        <button
          class="action"
          (click)="mode === 'edit' ? onConfirmEdit() : onStartEdit()"
          nbButton
          ghost
          status="info"
        >
          <nb-icon
            [icon]="mode === 'edit' ? 'checkmark-outline' : 'edit'"
          ></nb-icon>
        </button>
        <button
          class="action"
          (click)="this.onReloadUser()"
          nbButton
          ghost
          status="primary"
        >
          <nb-icon icon="refresh"></nb-icon>
        </button>
      </div>
    </div>
  </nb-card-header>
  <nb-card-body>
    <form class="row form-style w-100" [formGroup]="form">
      <div style="display: flex; flex-flow: row wrap" class="col-12 gap-1rem">
        <div class="form-field">
          <label>Username: </label>
          <input nbInput formControlName="username" />
        </div>

        <div class="form-field">
          <label>Email: </label>
          <input nbInput formControlName="email" />
        </div>

        <div class="form-field">
          <label>Gender: </label>
          <nb-select formControlName="gender">
            <nb-option
              *ngFor="let gender of $Gender | keyvalue"
              [value]="gender.value"
              >{{ gender.value }}</nb-option
            >
          </nb-select>
        </div>

        <div class="form-field">
          <label>Role: </label>
          <nb-select formControlName="role">
            <nb-option
              [disabled]="!roles.includes(role.value)"
              *ngFor="let role of $Roles | keyvalue"
              [value]="role.value"
              >{{ role.value | uppercase }}
            </nb-option>
          </nb-select>
        </div>

        <div class="form-field">
          <label>Phonenumber: </label>
          <input nbInput formControlName="phonenumber" />
        </div>

        <div class="form-field">
          <label>Country: </label>
          <nb-select formControlName="countryId">
            <nb-option
              *ngFor="let country of countries$ | async"
              [value]="country.id"
              >{{ country.name }}</nb-option
            >
          </nb-select>
        </div>
      </div>
    </form>
  </nb-card-body>
</nb-card>
