import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ContentCardComponent } from './content-card.component';

@NgModule({
  declarations: [ContentCardComponent],
  imports: [CommonModule],
  exports: [ContentCardComponent],
})
export class ContentCardModule {}
