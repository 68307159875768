<apx-chart
  [series]="chartOptions.series ?? []"
  [chart]="
    chartOptions.chart ?? {
      height: 150,
      type: 'radialBar'
    }
  "
  [labels]="this.labelList"
  [colors]="colorList ?? []"
  [plotOptions]="{
    radialBar: {
      hollow: {
        size: '55%'
      }
    }
  }"
></apx-chart>
