import { Injectable } from '@angular/core';
import { VoucherDTO } from 'core/gateways/voucher/voucher-dto';
import { VoucherGateway } from 'core/gateways/voucher/voucher-gateway.service';
import { PaginationDto } from 'dtos/pagination.dto';
import { Voucher } from 'models/vouchers/voucher';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VoucherFacade {
  constructor(private _gateway: VoucherGateway) {}

  list(filter?: PaginationDto): Observable<{ data: Voucher[]; count: number }> {
    return this._gateway.list(filter);
  }

  create(body: VoucherDTO.CreateBody): Observable<any> {
    return this._gateway.add(body);
  }

  exists(code: string): Observable<boolean> {
    return this._gateway.exists(code);
  }

  edit(voucher_id: number, body: VoucherDTO.UpdateBody): Observable<any> {
    return this._gateway.edit(voucher_id, body);
  }
}
