import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { AdsFacade } from 'core/facade/ads-facade.service';
import { AdsDTO } from 'core/gateways/ads/ads-dto';
import { Ad } from 'models/ads/ad';
import { AdMediaType, formatAdMediaType } from 'models/ads/ad-media-type';
import { AdType, formatAdType } from 'models/ads/ad-type';
import { Observable } from 'rxjs';

@Component({
  selector: 'ngx-add-edit-ad-dialog',
  templateUrl: './add-edit-ad-dialog.component.html',
  styleUrls: ['./add-edit-ad-dialog.component.scss'],
})
export class AddEditAdDialogComponent implements OnInit {
  ad?: Ad;

  form: FormGroup;

  loading: boolean = false;

  mediaTypeOptions: SelectOption[];
  adtypeOptions: SelectOption[];

  selectedMediaType?: AdMediaType;

  constructor(
    private _ref: NbDialogRef<any>,
    private _adsFacade: AdsFacade,
    private _nbToast: NbToastrService,
  ) {
    this.form = this._buildForm();
    this.mediaTypeOptions = this.MediaTypeOptions;
    this.adtypeOptions = this.AdtypeOptions;
  }

  ngOnInit(): void {
    if (this.ad) {
      this._patchAd(this.ad);
    }
  }

  submit() {
    this.loading = true;
    (this.IsAddMode ? this._add() : this._edit()).subscribe({
      next: () => {
        this.loading = false;
        this._nbToast.success(
          ``,
          `Ad is ${this.IsAddMode ? 'Added' : 'Edited'}`,
        );
        this._ref.close();
      },
      error: (error) => {
        this.loading = false;
        this._nbToast.danger(`try submiting once again`, `Ad was not saved`);
      },
    });
  }
  close() {
    this._ref.close();
  }

  handleBackgroundColorChange(color: string) {
    this.form.patchValue({
      backgroundColor: color,
    });
  }

  private _add(): Observable<any> {
    return this._adsFacade.add(
      this._value() as AdsDTO.AdAddBody,
      this._mediaFile(),
    );
  }
  private _edit(): Observable<any> {
    const mediaFile = this._mediaFile();
    // if mediaFile is a string then it was not set by the user but by the response from the API
    return this._adsFacade.edit(
      this.ad.id,
      this._value() as AdsDTO.AdEditBody,
      typeof mediaFile === 'string' ? undefined : mediaFile,
    );
  }

  private _value(): AdsDTO.AdAddBody | AdsDTO.AdEditBody {
    return {
      name: this.form.get('name').value,
      activeAfter: this.form.get('activeAfter').value,
      title: this.form.get('title').value,
      closeAtEnd: this.form.get('closeAtEnd').value,
      closeButton: this.form.get('closeButton').value,
      coolDownTime: this.form.get('coolDownTime').value,
      deactivated: this.form.get('deactivated').value,
      inactiveAfter: this.form.get('inactiveAfter').value,
      type: this.form.get('type').value,
      showAtStart: this.form.get('showAtStart').value,
      timeOnScreen: this.form.get('timeOnScreen').value,
      backgroundColor: this.form.get('backgroundColor').value,
      text: this.form.get('text').value,
      removeMedia: (this.ad?.media && !this._mediaFile()) ?? false,
    };
  }

  private _mediaFile(): File | undefined {
    return this.form.get('media').value;
  }

  get IsAddMode(): boolean {
    return !this.ad;
  }
  get IsEditMode(): boolean {
    return !!this.ad;
  }
  get ColorPickerBackgroundColor(): string {
    return this.form.value.backgroundColor;
  }

  get MediaTypeOptions(): SelectOption[] {
    return Object.values(AdMediaType).map((type) => ({
      value: type,
      display: formatAdMediaType(type),
    }));
  }
  get AdtypeOptions(): SelectOption[] {
    return Object.values(AdType).map((type) => ({
      value: type,
      display: formatAdType(type),
    }));
  }
  get MediaType(): typeof AdMediaType {
    return AdMediaType;
  }

  private _buildForm(): FormGroup {
    return new FormGroup({
      activeAfter: new FormControl(),
      closeAtEnd: new FormControl(false),
      closeButton: new FormControl(false),
      coolDownTime: new FormControl(0, Validators.required),
      deactivated: new FormControl(false),
      inactiveAfter: new FormControl(),
      media: new FormControl(),
      type: new FormControl(AdType.Fullscreen, Validators.required),
      name: new FormControl('', Validators.required),
      showAtStart: new FormControl(false),
      timeOnScreen: new FormControl(0, Validators.required),
      backgroundColor: new FormControl(),
      text: new FormControl(),
      title: new FormControl(),
    });
  }
  private _patchAd(ad: Ad): void {
    this.form.patchValue({
      title: ad.title,
      activeAfter: ad.activeAfter,
      closeAtEnd: ad.closeAtEnd,
      closeButton: ad.closeButton,
      coolDownTime: ad.coolDownTime,
      deactivated: ad.deactivated,
      inactiveAfter: ad.inactiveAfter,
      media: ad.media ? ad.media.url : undefined,
      type: ad.type,
      name: ad.name,
      showAtStart: ad.showAtStart,
      timeOnScreen: ad.timeOnScreen,
      backgroundColor: ad.backgroundColor,
      text: ad.text,
    });
    this.selectedMediaType = ad.media ? ad.media.type : undefined;
  }
}

interface SelectOption {
  value: AdMediaType | AdType;
  display: string;
}
