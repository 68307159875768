import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { NbToastrService } from '@nebular/theme';
import { Profile } from 'app/services/profile.service';
import { Observable } from 'rxjs';
import { UserRoles } from '../models/user-role';

@Injectable({
  providedIn: 'root',
})
export class AuthorizedGuard implements CanActivate, CanActivateChild {
  constructor(
    private profile: Profile,
    private toastr: NbToastrService,
    private router: Router,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const auth = this.auth(route.data);
    let allowed: UserRoles[] = [];
    let denied: UserRoles[] = [];
    if ('allowed' in auth) {
      allowed = auth.allowed;
    } else if ('denied' in auth) {
      denied = auth.denied;
    } else {
      return true;
    }
    return this.process(allowed, denied, auth.informative);
  }

  canActivateChild(
    child_route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ) {
    const auth = this.auth(child_route.data);
    const pauth = this.auth(child_route.parent.data);
    let allowed: UserRoles[] = [];
    let denied: UserRoles[] = [];
    if ('allowed' in auth) {
      allowed = auth.allowed;
    } else if ('denied' in auth) {
      denied = auth.denied;
    } else if ('allowed' in pauth) {
      allowed = pauth.allowed;
    } else if ('denied' in pauth) {
      denied = pauth.denied;
    } else {
      return true;
    }
    return this.process(
      allowed,
      denied,
      auth.informative != null ? auth.informative : pauth.informative,
    );
  }

  private async process(
    allowed: UserRoles[],
    denied: UserRoles[],
    informative: boolean,
  ) {
    if (!this.profile.isAuthenticated) return this.router.createUrlTree(['']);
    const role = await this.profile.Me(false).then((user) => user.role);
    if (allowed.includes(role)) return true;
    else if (denied.includes(role)) {
      if (informative)
        this.toastr.warning('can not access this page.', 'Unauthorized!');
      return this.router.createUrlTree(['']);
    } else {
      if (informative)
        this.toastr.warning('can not access this page.', 'Unauthorized!');
      return this.router.createUrlTree(['']);
    }
  }

  private auth(data: any): GuardAuthOjbect['auth'] {
    return data.auth || {};
  }

  public static auth(
    allowed: UserRoles[],
    state: 'allow',
    informative: boolean,
  ): GuardAuthOjbect;
  public static auth(
    denied: UserRoles[],
    state: 'deny',
    informative: boolean,
  ): GuardAuthOjbect;
  public static auth(
    roles: UserRoles[],
    state: 'deny' | 'allow',
    informative: boolean = true,
  ): GuardAuthOjbect {
    const auth = {
      allowed: [],
      denied: [],
      informative,
    };
    state === 'allow' ? (auth['allowed'] = roles) : (auth['denied'] = roles);
    return {
      auth,
    };
  }
}

interface GuardAuthOjbect {
  auth: {
    denied?: UserRoles[];
    allowed?: UserRoles[];
    informative?: boolean;
  };
}
