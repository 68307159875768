import { SubscriptionAdapter } from 'core/gateways/subscription/subscription-adapter';
import { SubscriptionDTO } from 'core/gateways/subscription/subscription-dto';
import { Payment } from './payment/payment';
import { Subscription } from './subscription';
import { User, UserDTO } from './user';

export class UserSubscription {
  id: number;
  user_id: number;
  duration: number;
  expiry_date: Date;
  login_times: number;
  user: User;
  subscription_id: number;
  subscription: Subscription;
  is_active: boolean;
  created_at: Date;
  payment: Payment;

  public static fromDTO(dto: UserSubscriptionDTO[]): UserSubscription[];
  public static fromDTO(dto: UserSubscriptionDTO): UserSubscription;
  public static fromDTO(
    dto: UserSubscriptionDTO | UserSubscriptionDTO[],
  ): UserSubscription | UserSubscription[] {
    if (Array.isArray(dto)) return dto?.map((one) => this.fromDTO(one));

    const user_subscription = new UserSubscription();
    user_subscription.id = dto.id;
    user_subscription.user_id = dto.userId;
    user_subscription.duration = dto.duration;
    user_subscription.expiry_date = new Date(dto.expiryDate);
    user_subscription.login_times = dto.loginTimes;
    user_subscription.is_active = dto.isActive;
    user_subscription.created_at = new Date(dto.createdAt);

    user_subscription.user_id = dto.userId;
    user_subscription.user = dto.user ? User.fromDTO(dto.user) : null;

    user_subscription.subscription_id = dto.subscriptionId;
    user_subscription.subscription = dto.subscription
      ? SubscriptionAdapter.subscription(dto.subscription)
      : null;

    user_subscription.payment = dto.payment ? new Payment(dto.payment) : null;

    return user_subscription;
  }

  public static toDTO(
    user_subscription: UserSubscription[],
  ): UserSubscriptionDTO[];
  public static toDTO(user_subscription: UserSubscription): UserSubscriptionDTO;
  public static toDTO(
    user_subscription: UserSubscription | UserSubscription[],
  ): UserSubscriptionDTO | UserSubscriptionDTO[] {
    if (Array.isArray(user_subscription))
      return user_subscription?.map((sub) => this.toDTO(sub));
    else
      return {
        id: user_subscription.id,
        userId: user_subscription.user_id,
        subscriptionId: user_subscription.subscription_id,
        payment: user_subscription.payment,
        duration: user_subscription.duration,
        expiryDate: user_subscription.expiry_date.toISOString(),
        isActive: user_subscription.is_active,
        loginTimes: user_subscription.login_times,
        createdAt: user_subscription.created_at.toISOString(),
      };
  }
}

export interface UserSubscriptionDTO {
  id: number;
  duration: number;
  expiryDate: string;
  loginTimes: number;
  isActive: boolean;

  user?: UserDTO;
  userId: number;

  subscriptionId: number;
  subscription?: SubscriptionDTO.Base;
  payment: Payment;
  createdAt: string;
}
