import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { UsersService } from 'app/services/users.service';
import { User } from 'models/user';
import { UserRoles } from 'models/user-role';
import { LocalDataSource } from 'ng2-smart-table';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'ngx-user-students-listing',
  templateUrl: './user-students-listing.component.html',
  styleUrls: ['./user-students-listing.component.scss'],
})
export class UserStudentsListingComponent implements OnInit {
  settings = {};
  source = new LocalDataSource([]);
  autocomplete_options: User[] = [];
  autocomplete_form = new FormControl();

  @Input('students')
  public set students(v: User[]) {
    this._students = v || [];
    this.update();
  }
  public get students(): User[] {
    return this._students;
  }
  private _students: User[];

  @Input('loading')
  loading: boolean = false;

  @Output('onAssignStudent')
  public assign_student_event = new EventEmitter<User>();

  @Output('onDeleteStudent')
  public delete_student_event = new EventEmitter<User>();

  constructor(private users_service: UsersService) {}

  ngOnInit(): void {
    this.initSettings();
    this.autocomplete_form.valueChanges
      .pipe(distinctUntilChanged(), debounceTime(500))
      .subscribe(() => this.updateAutocompleteOptions());
  }

  initSettings() {
    this.settings['hideSubHeader'] = true;
    this.settings['actions'] = {
      add: false,
      edit: false,
      delete: true,
    };
    this.settings['delete'] = {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true,
    };
    this.settings['columns'] = {
      username: {
        title: 'Name',
        type: 'text',
      },
      email: {
        title: 'Email',
        type: 'text',
      },
      gender: {
        title: 'Gender',
        type: 'text',
      },
    } as Partial<Record<keyof User, any>>;
  }

  update() {
    this.source.load(this.students);
  }

  onSelectStudent(student: User) {
    this.autocomplete_form.setValue('');
    this.autocomplete_options = [];
    this.assign_student_event.emit(student);
  }

  onDeleteStudent($event) {
    this.delete_student_event.emit($event.data);
  }

  updateAutocompleteOptions() {
    this.users_service
      .list({ username: this.autocomplete_form.value, role: UserRoles.User })
      .subscribe(
        (users) =>
          (this.autocomplete_options = users.data.filter(
            (user) =>
              !this.students.map((student) => student.id).includes(user.id),
          )),
      );
  }
}
