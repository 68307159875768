<p-table
  #dt
  [value]="data"
  styleClass="p-datatable-striped p-datatable-sm p-datatable-gridlines"
  currentPageReportTemplate="Showing {first} to {last}"
  [showCurrentPageReport]="true"
  dataKey="id"
  [lazy]="true"
  [paginator]="true"
  [scrollable]="true"
  [loading]="loading"
  [rowHover]="true"
  [rows]="10"
  (onLazyLoad)="changePage($event)"
  scrollHeight="flex"
  responsiveLayout="scroll"
  [totalRecords]="usersTotalCount"
>
  <!-- <ng-template pTemplate="caption">
    <div class="w-100 d-flex">
      <div class="flex-1 d-flex justify-content-end"></div>
    </div>
  </ng-template> -->

  <ng-template pTemplate="header">
    <tr>
      <th class="width-80 bb-0" header>
        <span></span>
      </th>
      <th class="bb-0" *ngFor="let column of COLUMNS" header>
        <span>{{ column }}</span>
      </th>
      <th class="width-80 bb-0" header>
        <span>View</span>
      </th>
    </tr>

    <!--! FILTERS -->
    <tr>
      <th class="width-80 bb-0" header></th>
      <th class="bb-0" header>
        <input
          class="w-100"
          pInputText
          placeholder="Name"
          type="text"
          [(ngModel)]="filteredUserName"
          (ngModelChange)="changeName($event)"
        />
      </th>
      <th class="bb-0" header></th>
      <th class="bb-0" header></th>

      <th class="bb-0" header>
        <p-dropdown
          [ngClass]="{
            'custom-dropdown':
              !filteredCountry || filteredCountry?.name === 'Country'
          }"
          class="w-100"
          [options]="countries"
          [(ngModel)]="filteredCountry"
          optionLabel="name"
          [showClear]="filteredCountry && filteredCountry?.name !== 'Country'"
          (ngModelChange)="changeCountry($event)"
        ></p-dropdown>
      </th>

      <th class="bb-0" header>
        <p-dropdown
          [ngClass]="{
            'custom-dropdown': !filteredRole || filteredRole === 'Role'
          }"
          class="w-100"
          [options]="rolesFilter"
          [(ngModel)]="filteredRole"
          [showClear]="filteredRole && filteredRole !== 'Role'"
          (ngModelChange)="changeRole($event)"
        ></p-dropdown>
      </th>

      <th class="bb-0" header></th>
      <th class="width-80 bb-0" header></th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-user let-rowIndex="rowIndex">
    <tr [formGroup]="userEditForm">
      <td class="width-80">
        <fourqan-round-button
          *ngIf="!editing"
          [icon]="'pi pi-pencil'"
          [color]="'p-button-grey'"
          (click)="onRowEditInit(user)"
        ></fourqan-round-button>
        <fourqan-round-button
          *ngIf="editing && user.id === id"
          [icon]="'pi pi-check'"
          [color]="'p-button-green'"
          (click)="onRowEditSave()"
          [disabled]="!userEditForm.valid"
        ></fourqan-round-button>
        <fourqan-round-button
          *ngIf="editing && user.id === id"
          [icon]="'pi pi-times'"
          [color]="'p-button-red'"
          (click)="onRowEditCancel()"
        ></fourqan-round-button>
      </td>

      <td>
        <input
          class="w-100"
          *ngIf="user.id === id && editing"
          pInputText
          type="text"
          formControlName="name"
        />
        <div
          class="d-flex w-100 gap-05rem justify-content-between align-items-center"
        >
          <span *ngIf="user.id !== id" class="cell-width">{{
            user.username
          }}</span>

          <div class="tool-tip-icon">
            <img
              style="width: 24px; height: 24px;"
              [src]="'assets/icons/' + user.subscriptionType + '.svg'"
              alt="subscription type icon"
            />
            <span class="tool-tip">{{ user.subscriptionType }}</span>
          </div>
        </div>
      </td>

      <td>
        <input
          class="w-100"
          *ngIf="user.id === id && editing"
          pInputText
          type="email"
          formControlName="email"
        />
        <span *ngIf="user.id !== id" class="cell-width">{{ user.email }}</span>
      </td>

      <td>
        <input
          class="w-100"
          formControlName="phone"
          *ngIf="user.id === id && editing"
          pInputText
          type="tel"
        />
        <span *ngIf="user.id !== id">{{ user.phonenumber }}</span>
      </td>

      <td>
        <p-dropdown
          class="w-100"
          [options]="countries"
          *ngIf="user.id === id && editing"
          formControlName="country"
          optionLabel="name"
        ></p-dropdown>
        <span *ngIf="user.id !== id" class="my-fs-2 cell-width">
          {{ user.country.name }}
        </span>
      </td>

      <td>
        <p-dropdown
          class="w-100"
          [options]="roles"
          *ngIf="user.id === id && editing"
          formControlName="role"
        ></p-dropdown>
        <span *ngIf="user.id !== id" class="my-fs-2 cell-width">
          {{ user.role }}
        </span>
      </td>

      <td>
        <p-dropdown
          class="w-100"
          [options]="['female', 'male']"
          *ngIf="user.id === id && editing"
          formControlName="gender"
        ></p-dropdown>
        <span class="d-flex justify-content-center" *ngIf="user.id !== id">
          {{ user.gender }}
        </span>
      </td>

      <td class="width-80">
        <fourqan-round-button
          [icon]="'pi pi-eye'"
          [color]="'p-button-grey'"
          (click)="onRowSelect(user)"
        ></fourqan-round-button>
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="100%">No Users found.</td>
    </tr>
  </ng-template>
</p-table>
