import { Component, Input, OnInit } from '@angular/core';
import { User } from 'models/user';
import { LocalDataSource } from 'ng2-smart-table';

@Component({
  selector: 'ngx-teacher-listing',
  templateUrl: './teacher-listing.component.html',
  styleUrls: ['./teacher-listing.component.scss'],
})
export class TeacherListingComponent implements OnInit {
  settings = {};
  source = new LocalDataSource([]);

  @Input('teachers')
  public set teachers(v: User[] | undefined) {
    this._teachers = v ?? [];
    this.update();
  }
  public get teachers(): User[] {
    return this._teachers;
  }
  private _teachers: User[];

  constructor() {}

  ngOnInit(): void {
    this.initSettings();
  }

  initSettings() {
    this.settings['hideSubHeader'] = true;
    this.settings['actions'] = {
      add: false,
      edit: false,
      delete: false,
    };
    this.settings['columns'] = {
      username: {
        title: 'Name',
        type: 'text',
      },
      email: {
        title: 'Email',
        type: 'text',
      },
      gender: {
        title: 'Gender',
        type: 'text',
      },
    } as Partial<Record<keyof User, any>>;
  }

  update() {
    this.source.load(this.teachers);
  }
}
