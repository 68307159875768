<div
  *ngIf="['loading', 'saving'].includes(page_editor_service.state)"
  class="progress-container"
>
  <nb-card>
    <nb-card-body>
      <h4>
        {{ page_editor_service.state === 'loading' ? 'downloading' : 'saving' }}
        lesson..
      </h4>
      <nb-progress-bar
        status="success"
        [displayValue]="true"
        [value]="page_editor_service.progress.toFixed(2)"
      >
      </nb-progress-bar>
    </nb-card-body>
  </nb-card>
</div>
<div
  class="row"
  *ngIf="['loaded', 'saving'].includes(page_editor_service.state)"
>
  <div class="col-8">
    <div style="margin-bottom: 0.5rem" id="readings-selection" class="col-12">
      <button
        nbButton
        (click)="onSelectReading($ReadingType.NormalReadingWithOutSpelling)"
        size="small"
        shape="round"
        ghost
        [status]="
          current_reading_type == $ReadingType.NormalReadingWithOutSpelling
            ? 'success'
            : 'info'
        "
      >
        Normal
      </button>
      <button
        nbButton
        (click)="onSelectReading($ReadingType.NormalReadingWithSpelling)"
        size="small"
        shape="round"
        ghost
        [status]="
          current_reading_type == $ReadingType.NormalReadingWithSpelling
            ? 'success'
            : 'info'
        "
      >
        Normal & Spelling
      </button>
    </div>
    <div id="lesson-svg" class="col-12">
      <nb-card>
        <nb-card-body style="padding: 25px">
          <div style="margin-bottom: 1rem" *ngIf="page_editor_service.svg">
            <ngx-page-svg-editor
              (onDarkBackgroundChange)="onBackgroundGenerated('dark', $event)"
              (onLightBackgroundChange)="onBackgroundGenerated('light', $event)"
              (onSeparatorsChange)="onSeparatorCalculated($event)"
              (afterSvgInit)="afterSvgInit()"
              [svg]="page_editor_service.svg"
              [lines]="page_editor_service.page.lines"
            >
            </ngx-page-svg-editor>
          </div>
          <ng-container>
            <button
              (click)="svgupload.click()"
              style="margin: auto; display: block"
              nbButton
              ghost
              size="giant"
            >
              <nb-icon icon="upload-outline" pack="eva"></nb-icon>
              Upload Svg
            </button>
            <input
              #svgupload
              type="file"
              (change)="onSvgFileChange($event.target.files.item(0))"
              hidden
            />
          </ng-container>
        </nb-card-body>
      </nb-card>
    </div>
    <div id="lesson-float-bar" class="col-12">
      <!-- <div id="mode-switch-buttons">
                <nb-toggle [(ngModel)]="is_teacher_reading">Teacher</nb-toggle>
                <button nbButton *ngIf="mode == 'view'" status="success">
                    <nb-icon icon="eye-outline"></nb-icon>
                </button>
                <button nbButton ghost *ngIf="mode == 'edit'" status="info" (click)="onSwitchMode('view')">
                    <nb-icon icon="eye-outline"></nb-icon>
                </button>

                <button nbButton *ngIf="mode == 'edit'" status="success">
                    <nb-icon icon="edit-outline"></nb-icon>
                </button>
                <button nbButton ghost *ngIf="mode == 'view'" status="info" (click)="onSwitchMode('edit')">
                    <nb-icon icon="edit-outline"></nb-icon>
                </button>

            </div> -->
      <nb-card *ngIf="mode === 'edit'" id="lesson-actions-card">
        <nb-card-body>
          <div class="lesson-actions">
            <button
              [disabled]="!can_make_line"
              (click)="convertSelectedToLine()"
              nbButton
              size="tiny"
              outline
              status="info"
            >
              Make Line
            </button>
            <button
              [disabled]="!can_make_word"
              (click)="convertSelectedToWord()"
              nbButton
              size="tiny"
              outline
              status="info"
            >
              Make Word
            </button>
            <button
              [disabled]="!can_animate || !CurrentReading?.audio_url"
              (click)="onAnimateSelected()"
              nbButton
              size="tiny"
              outline
              status="info"
            >
              Animate
            </button>
            <button
              (click)="onSetWordsTiming()"
              [disabled]="!can_set_word_timing"
              nbButton
              size="tiny"
              outline
              status="info"
            >
              Set Words Timing
            </button>
            <button
              (click)="onSetLinesTiming()"
              [disabled]="!can_set_line_timing"
              nbButton
              size="tiny"
              outline
              status="info"
            >
              Set Lines Timing
            </button>
            <button
              (click)="makeSelectedWordTitle()"
              [disabled]="!can_set_line_timing"
              nbButton
              size="tiny"
              outline
              status="info"
            >
              Make a title
            </button>
            <button
              (click)="onSaveLesson()"
              nbButton
              size="tiny"
              outline
              status="info"
            >
              Save
            </button>
            <button
              (click)="onInsertPause()"
              nbButton
              size="tiny"
              outline
              status="info"
            >
              Add Pause
            </button>
            <button
              [disabled]="!can_link_word"
              (click)="linkSelectedWords()"
              nbButton
              size="tiny"
              outline
              status="info"
            >
              Link
            </button>
            <button
              [disabled]="!can_unlink_word"
              (click)="unlinkSelectedWords()"
              nbButton
              size="tiny"
              outline
              status="info"
            >
              Unlink
            </button>
            <button
              *ngIf="line_left_break_text$ | async as text"
              (click)="setLineBreakLeft()"
              nbButton
              size="tiny"
              outline
              status="info"
            >
              {{ text }}
            </button>
            <button
              *ngIf="line_right_break_text$ | async as text"
              (click)="setLineBreakRight()"
              nbButton
              size="tiny"
              outline
              status="info"
            >
              {{ text }}
            </button>
          </div>
        </nb-card-body>
      </nb-card>

      <nb-card id="lesson-track">
        <nb-card-body
          style="display: flex; flex-flow: row nowrap; row-gap: 0.5rem"
        >
          <ng-container *ngIf="CurrentReading?.audio_url">
            <ngx-page-track-editor
              [teacherReading]="is_teacher_reading"
              (teacherReadingChange)="is_teacher_reading = $event"
              (changeMode)="onSwitchMode($event)"
              [mode]="mode"
              style="flex-grow: 1"
              (onPlay)="onTrackPlay()"
              (onTick)="onTrackTick($event)"
              [url]="CurrentReading.audio_url"
            >
            </ngx-page-track-editor>
          </ng-container>
          <ng-container #uploadTrack>
            <button
              (click)="audioupload.click()"
              style="margin: auto; display: block"
              nbButton
              ghost
              size="small"
            >
              <nb-icon icon="upload-outline" pack="eva"></nb-icon>
            </button>
            <input
              accept=".m4a"
              #audioupload
              type="file"
              (change)="readAudioFile($event.target.files)"
              hidden
            />
          </ng-container>
        </nb-card-body>
      </nb-card>
    </div>
  </div>
  <div id="lesson-tweens" class="col-4">
    <nb-card>
      <nb-card-body style="padding: 25px; overflow-y: auto">
        <nb-tabset style="height: 100%" fullWidth>
          <nb-tab
            class="words-tab"
            tabId="words"
            #words
            style="padding: 1rem 0"
            tabTitle="Word"
          >
            <div *ngIf="words.active" class="words-list">
              <ng-container *ngFor="let line of page_editor_service.page.lines">
                <ng-container *ngFor="let word of line.words">
                  <ngx-word-view-list-item
                    (onSeekPause)="onSeekPause($event)"
                    (onDeletePause)="onDeletePause($event, word)"
                    (onSelect)="onClickWordViewListItem(word)"
                    (onDelete)="onDeleteWord(word)"
                    [reading]="current_reading_type"
                    [word]="word"
                    show="pauses"
                  >
                  </ngx-word-view-list-item>
                  <hr style="margin: 0; width: 100%" />
                </ng-container>
              </ng-container>
            </div>
          </nb-tab>
          <nb-tab
            class="letters-tab"
            tabId="letters"
            #letters
            style="padding: 1rem 0"
            tabTitle="Letter"
          >
            <div class="words-list" *ngIf="letters.active">
              <ng-container *ngFor="let line of page_editor_service.page.lines">
                <ng-container *ngFor="let word of line.words">
                  <ngx-word-view-list-item
                    (onViewLetter)="onViewLetter($event)"
                    (onDeleteAnimation)="onDeleteAnimation($event)"
                    (onSeekTween)="onSeekTween($event)"
                    (onTweenEdit)="onTweenEdit($event)"
                    (onTweenDelete)="onDeleteTween($event)"
                    (onSelect)="onClickWordViewListItem(word)"
                    (onDelete)="onDeleteWord(word)"
                    [reading]="current_reading_type"
                    [word]="word"
                    [tweens]="tweenFor(word)"
                    show="tweens"
                  >
                  </ngx-word-view-list-item>
                </ng-container>
              </ng-container>
            </div>
          </nb-tab>
          <nb-tab
            class="lines-tab"
            tabId="lines"
            #lines
            style="padding: 1rem 0"
            tabTitle="Line"
          >
            <ng-container *ngIf="lines.active">
              <div
                class="line-view"
                *ngFor="let line of page_editor_service.page.lines"
              >
                <ngx-line-view-list-item
                  (onSelect)="this.onSelectLine(line)"
                  (onDelete)="this.onDeleteLine(line)"
                  [line]="line"
                  [reading]="CurrentReading?.type"
                >
                </ngx-line-view-list-item>
              </div>
            </ng-container>
          </nb-tab>
        </nb-tabset>
      </nb-card-body>
    </nb-card>
  </div>
</div>
