import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NbDialogService } from '@nebular/theme';
import { UserSubscriptionsService } from 'app/services/user-subscriptions.service';
import { Subscription } from 'models/subscription';
import { UserSubscription } from 'models/user-subscription';
import { LocalDataSource } from 'ng2-smart-table';
import { filter, switchMap } from 'rxjs/operators';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'ngx-user-subscription-listing',
  templateUrl: './user-subscription-listing.component.html',
  styleUrls: ['./user-subscription-listing.component.scss'],
})
export class UserSubscriptionListingComponent implements OnInit {
  settings = {};
  source = new LocalDataSource([]);

  @Input()
  public set subscription(v: UserSubscription[]) {
    this._subscription = v || [];
    this.loadSubscription();
  }
  public get subscription(): UserSubscription[] {
    return this._subscription;
  }
  private _subscription: UserSubscription[];

  @Output('onAddSubscription')
  add_subscription_event = new EventEmitter();

  control = new FormControl();

  constructor(
    private dialog_service: NbDialogService,
    private _user_subscription_gateway: UserSubscriptionsService,
  ) {}

  ngOnInit(): void {
    this.initSettings();
  }

  initSettings() {
    this.settings['hideSubHeader'] = true;
    this.settings['actions'] = {
      add: false,
      edit: false,
      delete: true,
    };
    this.settings['delete'] = {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true,
    };
    this.settings['columns'] = {
      name: {
        title: 'Title',
        type: 'text',
      },
      created_at: {
        title: 'Subscribed',
        type: 'text',
      },
      ends_at: {
        title: 'Ends',
        type: 'text',
      },
      login_times: {
        title: 'LogIn times',
        type: 'text',
      },
      is_active:{
        title:'Is Active',
        type:'text'
      },
      ['payment.reference']: {
        title: 'Payment reference',
        type: 'text',
      },
      ['payment.externalId']: {
        title: 'Checkout payment',
        type: 'text',
      },
    } as Partial<Record<keyof Subscription, any>>;
  }

  loadSubscription() {
    const data = this.subscription.map((sub) => ({
      id: sub.id,
      name: sub.subscription?.name || 'name',
      created_at: sub.created_at.toDateString(),
      ends_at: sub.expiry_date.toDateString(),
      login_times: sub.login_times,
      is_active:sub.is_active,
      'payment.reference': sub.payment?.reference,
      'payment.externalId': sub.payment?.externalId,
    }));
    this.source.load(data);
  }

  onAddSubscription() {
    this.add_subscription_event.emit();
  }

  onDeleteSubscription($event: any): void {
    const ref = this.dialog_service.open(ConfirmationDialogComponent);
    ref.onClose
      .pipe(
        filter((confirmed) => confirmed),
        switchMap(() => {
          return this._user_subscription_gateway.cancelUserSubscription(
            $event.data.id,
          );
        }),
      )
      .subscribe({
        next: () => $event.confirm.resolve(),
        error: () => $event.confirm.reject(),
      });
  }
}
