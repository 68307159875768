export enum AdType {
  TopBanner = 'TOP_BANNER',
  Fullscreen = 'FULLSCREEN',
}

export function formatAdType(adType: AdType): string {
  switch (adType) {
    case AdType.Fullscreen:
      return 'Full screen';
    case AdType.TopBanner:
      return 'Top banner';
    default:
      return 'Unknown';
  }
}
