export function toMinutes(
  years: number = 0,
  months: number = 0,
  weeks: number = 0,
  days: number = 0,
  hours: number = 0,
  minutes: number = 0,
) {
  const years_mins = years * 12 * 30 * 24 * 60 || 0;
  const months_mins = months * 30 * 24 * 60 || 0;
  const weeks_mins = weeks * 7 * 24 * 60 || 0;
  const days_mins = days * 24 * 60 || 0;
  const hours_mins = hours * 60 || 0;
  return (
    years_mins + months_mins + weeks_mins + days_mins + hours_mins + minutes
  );
}

export function fromMinutes(
  mins: number,
  only: {
    years?: boolean;
    months?: boolean;
    weeks?: boolean;
    days?: boolean;
    hours?: boolean;
    minutes?: boolean;
  } = {},
) {
  let years, months, days, weeks, hours, minutes;
  if (only.years) {
    years = Math.floor(mins / (12 * 30 * 24 * 60)) || 0;
    mins -= years * (12 * 30 * 24 * 60);
  }
  if (only.months) {
    months = Math.floor(mins / (30 * 24 * 60)) || 0;
    mins -= months * (30 * 24 * 60);
  }
  if (only.weeks) {
    weeks = Math.floor(mins / (7 * 24 * 60)) || 0;
    mins -= weeks * (7 * 24 * 60);
  }
  if (only.days) {
    days = Math.floor(mins / (24 * 60)) || 0;
    mins -= days * (24 * 60);
  }
  if (only.hours) {
    hours = Math.floor(mins / 60) || 0;
    mins -= hours * 60;
  }
  if (only.minutes) {
    minutes = mins;
    mins -= mins;
  }
  return { years, months, days, weeks, hours, minutes };
}
