import { fromDartColor, toDartColor } from 'utils';

export class Attribute {
  constructor(public type: string, public value: string) {}
  public toFileJson(): AttributeJson {
    switch (this.type) {
      case 'color':
        return {
          type: this.type,
          value: toDartColor(this.value),
        };
      default:
        return {
          type: this.type,
          value: this.value,
        };
    }
  }
  public static fromFileJson(attr: AttributeJson): Attribute {
    switch (attr.type) {
      case 'color':
        return new Attribute(attr.type, fromDartColor(attr.value));
      default:
        return new Attribute(attr.type, attr.value);
    }
  }
}

export interface AttributeJson {
  type: string;
  value: string;
}
