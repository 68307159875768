import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthorizedGuard } from 'guards/authorized.guard';
import { PagesResolver } from 'resolvers/pages.resolver';
import { UserRoles } from 'models/user-role';
import { AdsContainerComponent } from './containers/ads-container/ads-container.component';
import { DashboardChartsContainerComponent } from './containers/dashboard-charts-container/dashboard-charts-container.component';
import { LessonsListingContainerComponent } from './containers/lessons-listing-container/lessons-listing-container.component';
import { PageEditorContainerComponent } from './containers/page-editor-container/page-editor-container.component';
import { PagesListingContainerComponent } from './containers/pages-listing-container/pages-listing-container.component';
import { SubscriptionContainerComponent } from './containers/subscription-container/subscription-container.component';
import { UserProfileContainerComponent } from './containers/user-profile-container/user-profile-container.component';
import { UsersListingContainerComponent } from './containers/users-listing-container/users-listing-container.component';
import { VouchersContainerComponent } from './containers/vouchers-container/vouchers-container.component';
import { MainLayoutComponent } from './main-layout/main-layout.component';

const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      {
        path: 'dashboard',
        canActivateChild: [AuthorizedGuard],
        data: {
          ...AuthorizedGuard.auth(
            [ UserRoles.SuperAdmin],
            'allow',
            true,
          ),
        },
        children: [
          {
            path: '',
            component: DashboardChartsContainerComponent,
          }
        ],
      },
      {
        path: 'pages',
        canActivateChild: [AuthorizedGuard],
        data: {
          ...AuthorizedGuard.auth(
            [UserRoles.Admin, UserRoles.Editor, UserRoles.SuperAdmin],
            'allow',
            true,
          ),
        },
        children: [
          {
            path: '',
            component: PagesListingContainerComponent,
          },
          {
            path: 'editor',
            component: PageEditorContainerComponent,
            data: {
              add: true,
            },
          },
          {
            path: ':id',
            component: PageEditorContainerComponent,
            data: {
              edit: true,
            },
          },
        ],
      },
      {
        path: 'lessons',
        canActivateChild: [AuthorizedGuard],
        data: {
          ...AuthorizedGuard.auth(
            [UserRoles.Admin, UserRoles.Editor, UserRoles.SuperAdmin],
            'allow',
            true,
          ),
        },
        children: [
          {
            path: '',
            component: LessonsListingContainerComponent,
            resolve: {
              pages: PagesResolver,
            },
          },
        ],
      },
      {
        path: 'users',
        children: [
          {
            path: '',
            component: UsersListingContainerComponent,
          },
          {
            path: 'me',
            data: {
              mine: true,
            },
            component: UserProfileContainerComponent,
          },
          {
            path: ':id',
            component: UserProfileContainerComponent,
          },
        ],
      },
      {
        path: 'subscriptions',
        children: [
          {
            path: '',
            component: SubscriptionContainerComponent,
          },
        ],
      },
      {
        path: 'vouchers',
        children: [
          {
            path: '',
            component: VouchersContainerComponent,
          },
        ],
      },
      {
        path: 'ads',
        children: [
          {
            path: '',
            component: AdsContainerComponent,
          },
        ],
      },
      {
        path: '',
        redirectTo: 'users',
        pathMatch: 'full',
      },
      {
        path: '**',
        redirectTo: 'users',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [PagesResolver],
})
export class NgxMainRouting {}
