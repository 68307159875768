import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'fourqan-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
})
export class DatePickerComponent {
  @Input() minDate: Date = new Date('2000-01-01T01:01:01.000Z');
  @Input() monthOnly: boolean = false;
  @Input() maxDate!: Date;
  @Input() dateControl!: Date;

  @Output() dateChange = new EventEmitter<Date>();

  constructor() {}

  public emitDateChange(): void {
    this.dateChange.emit(this.dateControl);
  }
}
