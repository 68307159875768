import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UsersPrimeTableComponent } from './users-prime-table.component';
import { TableModule } from 'primeng/table';
import { InputTextModule } from 'primeng/inputtext';
import { RoundButtonModule } from 'app/shared/round-button/round-button.module';
import { DropdownModule } from 'primeng/dropdown';

@NgModule({
  declarations: [UsersPrimeTableComponent],
  imports: [
    TableModule,
    CommonModule,
    InputTextModule,
    RoundButtonModule,
    ReactiveFormsModule,
    FormsModule,
    DropdownModule,
  ],
  exports: [UsersPrimeTableComponent],
})
export class UsersPrimeTableModule {}
