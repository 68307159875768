import { Box } from '@svgdotjs/svg.js';
import { PageMetaFile } from 'core/page-meta-file-resolver/index';
import * as JSZip from 'jszip';
import * as _ from 'lodash';
import * as MetaImporter from '../core/page-meta-file-resolver/importers/importer';
import { Line } from './line';
import { Reading, ReadingType } from './reading';
import { Word, WordDTO } from './word';
export class Page {
  public id: number;
  public name: string = '';
  public resource_url: string;
  public zip_url: string;
  public index: number;
  public is_published: boolean = false;
  public has_spelling_reading: boolean;

  public readings: Reading[] = [];
  public recitals: Recital[];
  public lines: Line[] = [];

  constructor(id?: number) {
    this.id = id;
    this.initReadings();
  }

  private initReadings() {
    const types = Object.values(ReadingType);
    types.forEach((type) => {
      const reading = new Reading(this, type);
      this.readings.push(reading);
    });
  }

  public addLine(line: Line) {
    line.page = this;
    this.lines.push(line);
    const center = line.relativeCenter();
    this.line_boundaries.forEach((boundy) => {
      if (center.cy >= boundy.y_top && center.cy <= boundy.y_bottom) {
        line.top_bound = boundy.y_top;
        line.bottom_bound = boundy.y_bottom;
      }
    });
    this.lines.sort((a, b) => {
      return a.relativeCenter().cy - b.relativeCenter().cy;
    });
  }

  public addReading(reading: Reading) {
    const old_reading_index = this.readings.findIndex(
      (old_reading) => old_reading.type == reading.type,
    );
    if (old_reading_index >= 0)
      this.readings.splice(old_reading_index, 1, reading);
  }

  public Reading(type: ReadingType): Reading | null {
    return this.readings.find((reading) => reading.type == type);
  }

  public words(): Word[] {
    return _.flatten(this.lines.map((line) => line.words));
  }

  private light_background: string;
  public get LightBackground(): string {
    return this.light_background;
  }
  public set LightBackground(v: string) {
    this.light_background = v;
  }
  private dark_background: string;
  public get DarkBackground(): string {
    return this.dark_background;
  }
  public set DarkBackground(v: string) {
    this.dark_background = v;
  }

  public deleteLine(line_id: string) {
    const index = this.lines.findIndex((line) => line.id == line_id);
    this.lines[index].discard();
    this.lines.splice(index, 1);
  }

  private viewbox: Box;
  public get Viewbox(): Box {
    return this.viewbox;
  }
  public set Viewbox(v: Box) {
    this.viewbox = v;
  }

  private bbox: Box;
  public get Bbox(): Box {
    return this.bbox;
  }
  public set Bbox(v: Box) {
    this.bbox = v;
  }

  private line_boundaries: { y_top: number; y_bottom: number }[] = [];
  public addBoundry(y_top: number, y_bottom: number) {
    this.line_boundaries.push({ y_top, y_bottom });
    this.line_boundaries.sort((a, b) => a.y_top - b.y_top);
  }

  public async toZipFile(): Promise<Blob> {
    const zip = new JSZip();
    const zipreadings = this.readings
      .filter((reading) => reading.audio_url != undefined)
      .map((reading) =>
        reading.audioToFile().then((content) => {
          if (content != null) zip.file(reading.type + '.m4a', content);
        }),
      );
    const filejson: PageMetaFile = {
      version: '5',
      readings: this.readings
        .filter((reading) => reading.audio_url != undefined)
        .map((reading) => ({
          ...reading.toFileJson(),
          pauses: reading.Pauses.filter((pause) => pause.duration > 0)
            .sort((a, b) => a.start - b.start)
            .map((pause) => pause.toFileJson()),
        })),
      light_background_svg: this.LightBackground,
      dark_background_svg: this.DarkBackground,
      lines: this.lines.map((line) => line.toFileJson()),
    };

    const filestrjson = JSON.stringify(filejson);
    zip.file('meta.json', filestrjson);
    await Promise.all(zipreadings);
    return zip.generateAsync({ type: 'blob' });
  }
  public async fromZipFile(buffer: ArrayBuffer) {
    const zip = new JSZip();
    const ziped = await zip.loadAsync(buffer);
    return ziped
      .file('meta.json')
      .async('string')
      .then<PageMetaFile>((sjson) => JSON.parse(sjson))
      .then((json) => {
        return MetaImporter.from(json);
      })
      .then((meta) => {
        meta.lines.forEach((line_json) => {
          const line = Line.fromFileJson(line_json, {
            [ReadingType.NormalReadingWithOutSpelling]:
              meta.readings.find(
                (reading) =>
                  reading.type === ReadingType.NormalReadingWithOutSpelling,
              )?.pauses ?? [],
            [ReadingType.NormalReadingWithSpelling]:
              meta.readings.find(
                (reading) =>
                  reading.type === ReadingType.NormalReadingWithSpelling,
              )?.pauses ?? [],
          });
          this.addLine(line);
        });
        const readings = this._buildReadingFromMeta(meta);
        readings.forEach((reading) => this.addReading(reading));
        const audio_promises = readings.map((reading) => {
          const metaReading = meta.readings.find(
            (metaReading) => metaReading.type === reading.type,
          );
          if (!metaReading) return;
          return ziped
            .file(metaReading.filename)
            ?.async('blob')
            .then((blob) => (reading.audio = blob));
        });
        return Promise.all(audio_promises);
      });
  }

  private draft: string;
  public get Draft(): string {
    return this.draft;
  }
  public set Draft(v: string) {
    this.draft = v;
  }

  public static fromDTO(dto: PageDTO): Page {
    const page = new Page(dto.id);
    page.zip_url = dto.filePath;
    page.resource_url = dto.resourceFilePath;
    page.index = dto.index;
    page.recitals = dto.reciters
      ? (dto.reciters.split(',') as Recital[])
      : (dto.availableReciters as Recital[]);
    return page;
  }
  public toDTO() {
    return Page.toDTO(this);
  }
  public static toDTO(page: Partial<Page>): PageDTO {
    return {
      id: page.id,
      index: page.index,
      filePath: page.zip_url,
      hasSpellingReading: page.has_spelling_reading,
      words: _.flatten(
        page?.lines?.map((line) =>
          line.words.map((word) => Word.toDTO(word)),
        ) || [],
      ),
      reciters: page?.recitals?.join(',') || '',
      resourceFilePath: page.resource_url,
    };
  }
  public static fromArrayDTO(dtos: PageDTO[]): Page[] {
    return dtos.map((dto) => Page.fromDTO(dto));
  }
  public indexOf(line: Line): number {
    return this.lines.indexOf(line);
  }

  /**
   * @description uses meta to build readings, for missing readings in meta it builds default reading
   */
  private _buildReadingFromMeta(meta: PageMetaFile) {
    return Object.values(ReadingType).map((readingType) => {
      const metaReading = meta.readings.find(
        (reading) => reading.type === readingType,
      );
      if (!metaReading) return new Reading(this, readingType);
      else {
        const letters = _.flatten(this.lines.map((line) => line.letters));
        const reading = Reading.fromFileJson(this, metaReading, letters);
        return reading;
      }
    });
  }
}
export interface PageDTO {
  id: number;
  /**
   * @description supported recitals as csv
   */
  availableReciters?: Recital[];
  reciters?: string;
  filePath: string;
  words: WordDTO[];
  hasSpellingReading: boolean;
  resourceFilePath: string;
  index: number;
}

export enum Recital {
  Hafs = 'Hafs',
  Warsh = 'Warsh',
  Qanoun = 'Qanoun',
}
