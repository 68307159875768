import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { Profile } from 'app/services/profile.service';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class NgxAuthService {
  endpoint = `${environment.server}/auth`;

  constructor(
    private fireauth: AngularFireAuth,
    private http: HttpClient,
    private profile: Profile,
    private _router: Router,
  ) {}

  /**
   *
   * @param email
   * @param password
   * @param remember keeps user loged in after he closed the webstie
   */
  async login(email: string, password: string, remember: boolean) {
    const { user } = await this.fireauth.signInWithEmailAndPassword(
      email,
      password,
    );
    const id_token = await user.getIdToken();
    const result = await this._login(id_token);
    this.profile.setPayload(
      {
        id: result.id,
        email: result.email,
        is_new: result.isNew,
        access_token: result.access_token,
      },
      remember,
    );
  }
  async logout() {
    this._router.navigate(['/auth']);
    this.profile.setPayload(null, true);
    location.reload();
  }

  private async _login(id_token: string): Promise<LoginOutJson> {
    return this.http
      .post<LoginOutJson>(`${this.endpoint}/login`, {
        token: id_token,
      } as LoginInJson)
      .toPromise();
  }
}

interface LoginInJson {
  token: string;
}
interface LoginOutJson {
  id: string;
  email: string;
  access_token: string;
  isNew: string;
}
