import { Injectable } from '@angular/core';
import { Pause } from 'models/pause';
import { Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class PageEditorPausesService {
  private pause_subject = new Subject<void>();
  private play_subject = new Subject<void>();

  private $discarded = new Subject<void>();

  private _pauses: Pause[];
  public get pauses(): Pause[] {
    return this._pauses;
  }
  public set pauses(v: Pause[]) {
    this._pauses = v;
    this._pauses.sort((a, b) => a.start - b.start);
  }

  private previous_seek: number = -Infinity;

  onPause() {
    return this.pause_subject.asObservable();
  }
  onPlay() {
    return this.play_subject.asObservable();
  }

  discard() {
    this.$discarded.next();
  }

  doCheck(seek: number) {
    if (seek === this.previous_seek) return;
    this.pauses.some((pause) => {
      const is_between =
        pause.start >= this.previous_seek && pause.start <= seek;
      if (is_between) {
        this.pause(pause);
        return true;
      }
      return false;
    });
    this.previous_seek = seek;
  }

  pause(pause: Pause) {
    this.pause_subject.next();
    timer(pause.duration * 1000)
      .pipe(takeUntil(this.$discarded))
      .subscribe(() => this.play_subject.next());
  }
}
