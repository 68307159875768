import { Properties } from 'utils/properties.type';

export class WordLink {
  linked_word_id: string;
  linked_position: WordLinkPosition;

  constructor(spec: Properties<WordLink>) {
    Object.assign(this, spec);
  }
}

export enum WordLinkPosition {
  Before = 'before',
  After = 'after',
}
