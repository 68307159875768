import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SubscriptionAdapter } from 'core/gateways/subscription/subscription-adapter';
import { SubscriptionDTO } from 'core/gateways/subscription/subscription-dto';
import { environment } from 'environments/environment';
import { Subscription } from 'models/subscription';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionsService {
  private _endpoint = `${environment.server}/subscription`;
  private _admin_endpoint = `${environment.server}/admin/subscription`;
  constructor(private http: HttpClient) {}

  list(): Observable<Subscription[]> {
    return this.http
      .get<{ data: SubscriptionDTO.Base[] }>(this._admin_endpoint)
      .pipe(map(({ data }) => SubscriptionAdapter.subscriptions(data)));
  }

  save(subscription: SubscriptionDTO.CreateBody) {
    return this.http.post<SubscriptionDTO.Base>(this._admin_endpoint, {
      ...subscription,
    });
    // .pipe(map((_dto) => Subscription.fromDTO(_dto)));
  }

  edit(
    subscription_id: number,
    dto: SubscriptionDTO.UpdateBody,
  ): Observable<any> {
    return this.http.put(`${this._admin_endpoint}/${subscription_id}`, dto);
  }
}
