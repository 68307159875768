import { Component, forwardRef, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ImagePickerValue } from '../image-picker/image-picker.component';

@Component({
  selector: 'ngx-video-picker',
  templateUrl: './video-picker.component.html',
  styleUrls: ['./video-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => VideoPickerComponent),
      multi: true,
    },
  ],
})
export class VideoPickerComponent implements OnInit, ControlValueAccessor {
  fileControl = new FormControl();
  previewUrl?: string;

  constructor(private _domSanitizer: DomSanitizer) {}

  ngOnInit(): void {}

  handleFileChange($event: InputEvent) {
    const files: FileList = $event.target['files'];
    if (files.length === 0) {
      return;
    }
    this._useFile(files.item(0));
  }
  clearValue() {
    this.fileControl.setValue(undefined);
    this.previewUrl = undefined;
  }

  writeValue(value: ImagePickerValue) {
    if (!value) {
      this.clearValue();
      return;
    }
    if (typeof value === 'string') {
      this._useUrl(value);
      return;
    }
    this._useFile(value);
  }
  registerOnChange(changeFn: any) {
    this.fileControl.valueChanges.subscribe(changeFn);
  }
  registerOnTouched() {}
  setDisabledState() {}

  get IsPreview(): boolean {
    return !!this.previewUrl;
  }

  private _useFile(file: File) {
    this.fileControl.setValue(file);
    this.previewUrl = this._domSanitizer.bypassSecurityTrustUrl(
      URL.createObjectURL(file),
    ) as string;
  }
  private _useUrl(url: string) {
    this.fileControl.setValue(url);
    this.previewUrl = this._domSanitizer.bypassSecurityTrustUrl(url) as string;
  }
}
