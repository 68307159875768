/**
 *
 * @description base64 encoding with escaped uri
 */
export function encode(s: string): string {
  return btoa(encodeURI(s));
}
/**
 *
 * @description base64 decoding with escaped uri
 */
export function decode(s: string): string {
  return decodeURI(atob(s));
}
