<nb-card class="subscription">
  <nb-card-header>
    {{ subscription.name }}
  </nb-card-header>
  <nb-card-body>
    <div [formGroup]="selectedRecitals" class="recitals">
      <nb-checkbox [formControlName]="Recital.Hafs">Hafs</nb-checkbox>
      <nb-checkbox [formControlName]="Recital.Qanoun">Qaloun</nb-checkbox>
      <nb-checkbox [formControlName]="Recital.Warsh">Warsh</nb-checkbox>
    </div>
    <div class="sub emphasis">
      Gives access for
      <ng-container *ngIf="subscription.duration.years > 0"
        ><span class="show info" style="font-size: 1rem">
          {{ subscription.duration.years }} </span
        >years</ng-container
      >
      <ng-container *ngIf="subscription.duration.months > 0"
        ><span class="show info" style="font-size: 1rem">
          {{ subscription.duration.months }} </span
        >months</ng-container
      >
      <ng-container *ngIf="subscription.duration.days > 0"
        ><span class="show info" style="font-size: 1rem">
          {{ subscription.duration.days }} </span
        >days</ng-container
      >
      <ng-container *ngIf="subscription.duration.hours > 0">
        and
        <span class="show info" style="font-size: 1rem">
          {{ subscription.duration.hours }} </span
        >hours</ng-container
      >
    </div>
  </nb-card-body>
  <nb-card-footer>
    <button
      [disabled]="!(canSubmit$ | async)"
      (click)="onSubmit()"
      nbButton
      status="success"
    >
      Gift User
    </button>
  </nb-card-footer>
</nb-card>
