import { AbstractControl, ValidatorFn } from '@angular/forms';

export function gt(value: number): ValidatorFn {
  return (control: AbstractControl) => {
    if (control.value == null) {
      return null; // don't validate empty values to allow optional controls
    }
    const controlValue = parseFloat(control.value);
    const valid = controlValue > value;
    return valid
      ? null
      : {
          gt: {
            actual: controlValue,
            required: value,
          },
        };
  };
}
