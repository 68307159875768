import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  NbActionsModule,
  NbAutocompleteModule,
  NbButtonGroupModule,
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbDatepickerModule,
  NbDialogModule,
  NbFormFieldModule,
  NbIconModule,
  NbInputModule,
  NbPopoverModule,
  NbSelectModule,
  NbSpinnerModule,
  NbTagModule,
  NbToggleModule,
  NbTooltipModule,
  NbUserModule,
} from '@nebular/theme';
import { ThemeModule } from 'app/@theme/theme.module';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { AddUserDialogComponent } from '../dialogs/add-user-dialog/add-user-dialog.component';
import { SubscriptionEditDialogComponent } from '../dialogs/subscription-edit-dialog/subscription-edit-dialog.component';
import { VoucherEditDialogComponent } from '../dialogs/voucher-edit-dialog/voucher-edit-dialog.component';
import { NgxMainDirectivesModule } from '../directives/main.directives.module';
import { AddUserSubscriptionDialogComponent } from './add-user-subscription-dialog/add-user-subscription-dialog.component';
import { AnimationViewListItemComponent } from './animation-view-list-item/animation-view-list-item.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { LessonPagesEditCellComponent } from './lesson-pages-edit-cell/lesson-pages-edit-cell.component';
import { LessonPagesViewCellComponent } from './lesson-pages-view-cell/lesson-pages-view-cell.component';
import { LessonSubmitionDialogComponent } from './lesson-submition-dialog/lesson-submition-dialog.component';
import { LineViewListItemComponent } from './line-view-list-item/line-view-list-item.component';
import { MakeWordFormDialogComponent } from './make-word-form-dialog/make-word-form-dialog.component';
import { PageSubmitionDialogComponent } from './page-submition-dialog/page-submition-dialog.component';
import { PageSvgEditorComponent } from './page-svg-editor/page-svg-editor.component';
import { PageTrackEditorComponent } from './page-track-editor/page-track-editor.component';
import { SubscriptionCardComponent } from './subscription-card/subscription-card.component';
import { SubscriptionListingComponent } from './subscription-listing/subscription-listing.component';
import { TableCustomToggleCellComponent } from './table-custom-toggle-cell/table-custom-toggle-cell.component';
import { TeacherListingComponent } from './teacher-listing/teacher-listing.component';
import { TweenViewListItemComponent } from './tween-view-list-item/tween-view-list-item.component';
import { UserProfileFormComponent } from './user-profile-form/user-profile-form.component';
import { UserStudentsListingComponent } from './user-students-listing/user-students-listing.component';
import { UserSubscriptionListingComponent } from './user-subscription-listing/user-subscription-listing.component';
import { VoucherCardComponent } from './voucher-card/voucher-card.component';
import { WordViewListItemComponent } from './word-view-list-item/word-view-list-item.component';
import { AddUserSubscriptionCardComponent } from './add-user-subscription-dialog/add-user-subscription-card/add-user-subscription-card.component';
import { ChartPanelHeaderComponent } from './chart-panel-header/chart-panel-header.component';
import { ChartPanelSummaryComponent } from './chart-panel-summary/chart-panel-summary.component';
import { ProfitChartComponent } from './charts/profit-chart.component';
import { LegendChartComponent } from './legend-chart/legend-chart.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ChartModule } from 'angular2-chartjs';
import { UserTokensListingComponent } from './user-tokens-listing/user-tokens-listing.component';
import { PagesRecitersViewCellComponent } from './pages-reciters-view-cell/pages-reciters-view-cell.component';
@NgModule({
  imports: [
    ThemeModule,
    NgxMainDirectivesModule,
    CommonModule,
    NbInputModule,
    FormsModule,
    NbButtonModule,
    NbIconModule,
    NbActionsModule,
    ReactiveFormsModule,
    NbTooltipModule,
    NbCardModule,
    NbCheckboxModule,
    NbUserModule,
    NbSelectModule,
    Ng2SmartTableModule,
    NbPopoverModule,
    NbAutocompleteModule,
    NbSpinnerModule,
    NbToggleModule,
    NbTagModule,
    NbButtonGroupModule,
    NbFormFieldModule,
    NgxEchartsModule,ChartModule,NgxChartsModule,
    NbDialogModule.forChild(),
    NbDatepickerModule.forRoot(),
  ],
  declarations: [
    PageSvgEditorComponent,
    PageTrackEditorComponent,
    AnimationViewListItemComponent,
    WordViewListItemComponent,
    LineViewListItemComponent,
    LessonSubmitionDialogComponent,
    UserProfileFormComponent,
    TeacherListingComponent,
    UserSubscriptionListingComponent,
    UserStudentsListingComponent,
    SubscriptionListingComponent,
    SubscriptionCardComponent,
    MakeWordFormDialogComponent,
    PageSubmitionDialogComponent,
    LessonPagesEditCellComponent,
    LessonPagesViewCellComponent,
    TableCustomToggleCellComponent,
    AddUserSubscriptionDialogComponent,
    TweenViewListItemComponent,
    ConfirmationDialogComponent,
    SubscriptionEditDialogComponent,
    VoucherCardComponent,
    VoucherEditDialogComponent,
    AddUserDialogComponent,
    AddUserSubscriptionCardComponent,
    ChartPanelHeaderComponent,
    ChartPanelSummaryComponent,
    ProfitChartComponent,
    LegendChartComponent,
    UserTokensListingComponent,
    PagesRecitersViewCellComponent,
  ],
  exports: [
    PageSvgEditorComponent,
    PageTrackEditorComponent,
    AnimationViewListItemComponent,
    WordViewListItemComponent,
    LineViewListItemComponent,
    LessonSubmitionDialogComponent,
    UserProfileFormComponent,
    TeacherListingComponent,
    UserSubscriptionListingComponent,
    UserStudentsListingComponent,
    SubscriptionCardComponent,
    MakeWordFormDialogComponent,
    VoucherCardComponent,
    ChartPanelHeaderComponent,
    ChartPanelSummaryComponent,
    ProfitChartComponent,
    UserTokensListingComponent,
  ],
})
export class NgxMainComponentsModule {}
