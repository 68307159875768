// import { Country } from 'models/country';
import { Statistic } from 'models/statistic';
import { StatisticDTO } from './statistic-dto';
// import { CountryDTO } from './country-dto';

export namespace StatisticsAdapter {
  export function statistic(dto: StatisticDTO.Base): Statistic {
    return new Statistic({
      creatAt: new Date(dto.createdAt),
      paied:dto.paid,
      price:dto.price,
      discount:dto.discount,
      plan:{
          name:dto.plan.nameEn,
          planDiscount:dto.plan.discount
      },
      user:{
          userName:dto.user.userName
      },
      paymentReference:(dto.paymentReference)?dto.paymentReference:""
    });
  }
  export function statistics(dtos:any): Statistic[] {
    return dtos.statistics.map(dto => statistic(dto)) ;
  }
}
