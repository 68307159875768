import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { Profile } from 'app/services/profile.service';
import { UsersService } from 'app/services/users.service';
import { Gender } from 'models/gender';
import { UserRoles } from 'models/user-role';

@Component({
  selector: 'ngx-add-user-dialog',
  templateUrl: './add-user-dialog.component.html',
  styleUrls: ['./add-user-dialog.component.scss'],
})
export class AddUserDialogComponent implements OnInit {
  form: FormGroup;

  loading: boolean = false;

  rolesOptions: [title: string, role: UserRoles][] = [];

  constructor(
    fb: FormBuilder,
    private _userService: UsersService,
    private _profile: Profile,
    private _ref: NbDialogRef<AddUserDialogComponent>,
    private _nbToast: NbToastrService,
  ) {
    this.form = fb.group({
      username: ['', Validators.required],
      email: ['', Validators.required],
      password: ['', Validators.required],
      role: ['', Validators.required],
      gender: ['', Validators.required],
    });
    this._profile.Me().then((me) => {
      this.rolesOptions = _userService
        .grantableRoles(me.role)
        .map((role) => [this._roleName(role), role]);
    });
  }

  ngOnInit(): void {}

  addUser() {
    if (this.form.invalid) {
      this._nbToast.danger(
        'Please fill all required fields',
        'All fields are required',
      );
      return;
    }
    this.loading = true;
    this._userService
      .add({
        ...this._value(),
      })
      .subscribe(
        () => {
          this._ref.close({
            added: true,
          });
        },
        (error: HttpErrorResponse) => {
          this._nbToast.danger(error.error.message, error.status, {
            duration: 5000,
          });
          this.loading = false;
        },
      );
  }
  cancel() {
    this._ref.close({
      added: false,
    });
  }

  private _value() {
    const value = this.form.value;
    return {
      username: value.username,
      email: value.email,
      password: value.password,
      gender: value.gender,
      role: value.role,
    };
  }
  private _roleName(role: UserRoles): string {
    switch (role) {
      case UserRoles.SuperAdmin:
        return 'Super admin';
      case UserRoles.Admin:
        return 'Admin';
      case UserRoles.Editor:
        return 'Editor';
      case UserRoles.Teacher:
        return 'Treacher';
      case UserRoles.User:
        return 'User';
    }
  }

  get Gender(): typeof Gender {
    return Gender;
  }
  get Role(): typeof UserRoles {
    return UserRoles;
  }
}

export interface AddUserDialogResult {
  added: boolean;
}
