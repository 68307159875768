import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
  HttpProgressEvent,
  HttpRequest,
  HttpResponse,
  HttpSentEvent,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Page, PageDTO as PageDTO } from 'models/page';
import { BehaviorSubject, merge, Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class NgxPageService {
  private $in_progress: Record<number | string, InProgress> = {};

  private endpoint = `${environment.server}/page`;

  constructor(private http: HttpClient) {}

  list(): Observable<Page[]> {
    return this.http
      .get<{ data: PageDTO[] }>(this.endpoint)
      .pipe(map(({ data }) => Page.fromArrayDTO(data)));
  }

  update(id: number, lesson: Partial<Page>): Observable<Page> {
    const formdata = new FormData();
    this.appendLessonDTO(lesson, formdata);
    return this.http
      .put<PageDTO>(`${this.endpoint}/${id}`, formdata)
      .pipe(map((dto) => Page.fromDTO(dto)));
  }
  async fullUpdate(id: number, page: Page): Promise<InProgress> {
    const old_in_progress = this.$in_progress[id];
    if (old_in_progress) old_in_progress.abort();
    const [zip_file] = await Promise.all([page.toZipFile()]);

    const edit_blob = new Blob([page.Draft], {
      type: 'text/html',
    });

    const formdata = new FormData();
    formdata.append('page', zip_file);
    formdata.append('resource', edit_blob);
    this.appendLessonDTO(page, formdata, false);
    const req = new HttpRequest('PUT', `${this.endpoint}/${id}`, formdata, {
      reportProgress: true,
    });
    const req_obs = this.http.request<{ data: PageDTO }>(req).pipe(
      map((event) => {
        if (event.type === HttpEventType.Response)
          return event.clone({ body: Page.fromDTO(event.body.data) });
        else return event;
      }),
    );
    const in_progress = new InProgress(req_obs);
    this.$in_progress[id] = in_progress;
    merge(
      in_progress.onAbort,
      in_progress.onError,
      in_progress.onResponse,
    ).subscribe(() => {
      this.$in_progress[id] = null;
    });
    return in_progress;
  }
  async save(page: Page): Promise<InProgress> {
    const old_in_progress = this.$in_progress[`new_${page.name}`];

    if (old_in_progress) old_in_progress.abort();
    const [zip_file] = await Promise.all([page.toZipFile()]);

    const edit_blob = new Blob([page.Draft], {
      type: 'text/html',
    });

    const formdata = new FormData();
    formdata.append('page', zip_file);
    formdata.append('resource', edit_blob);
    this.appendLessonDTO(page, formdata, true);

    const req = new HttpRequest('POST', `${this.endpoint}`, formdata, {
      reportProgress: true,
    });
    const req_obs = this.http.request<{ data: PageDTO }>(req).pipe(
      map((event) => {
        if (event.type === HttpEventType.Response)
          return event.clone({ body: Page.fromDTO(event.body.data) });
        else return event;
      }),
    );
    const in_progress = new InProgress(req_obs);
    this.$in_progress[`new_${page.name}`] = in_progress;
    merge(
      in_progress.onAbort,
      in_progress.onError,
      in_progress.onResponse,
    ).subscribe(() => {
      this.$in_progress[`new_${page.name}`] = null;
    });
    return in_progress;
  }
  one(id: number): Observable<Page> {
    return this.http
      .get<{ data: PageDTO }>(`${this.endpoint}/${id}`)
      .pipe(map(({ data }) => Page.fromDTO(data)));
  }
  download(filename: string): Observable<HttpEvent<Blob>> {
    const req = new HttpRequest(
      'GET',
      `${this.endpoint}/resource/${filename}`,
      {
        reportProgress: true,
        responseType: 'blob',
      },
    );
    return this.http.request(req);
  }

  delete(page_id: number): Observable<Page> {
    return this.http
      .delete<PageDTO>(`${this.endpoint}/${page_id}`)
      .pipe(map((dto) => Page.fromDTO(dto)));
  }

  private appendLessonDTO(
    lesson: Partial<Page>,
    formdata: FormData,
    include_empty: boolean = false,
  ): void {
    const lesson_dto = Page.toDTO(lesson);
    if (include_empty || lesson_dto.index != null)
      formdata.set('index', lesson_dto.index?.toString());
    if (include_empty || lesson_dto.reciters != null)
      formdata.set('reciters', lesson_dto.reciters);
    if (include_empty || lesson_dto.words != null)
      formdata.set('words', JSON.stringify(lesson_dto.words));
    formdata.set(
      'hasSpellingReading',
      lesson.has_spelling_reading ? 'true' : 'false',
    );
    formdata.set('isPublished', 'false');
  }
}

export class InProgress {
  private _sent_subject: BehaviorSubject<HttpSentEvent> = new BehaviorSubject(
    null,
  );
  private _progress_subject: BehaviorSubject<HttpProgressEvent> =
    new BehaviorSubject(null);
  private _response_subject: BehaviorSubject<HttpResponse<Page>> =
    new BehaviorSubject(null);
  private _error_subject: BehaviorSubject<HttpErrorResponse> =
    new BehaviorSubject(null);
  private _aborted_subject: BehaviorSubject<boolean> = new BehaviorSubject(
    false,
  );

  private subscription: Subscription;
  constructor(private _request: Observable<HttpEvent<Page>>) {}

  abort() {
    this.subscription.unsubscribe();
    this._aborted_subject.next(true);
    this.complete();
  }

  run() {
    if (this.subscription != null) return;
    this.subscription = this._request.subscribe({
      next: (event) => {
        switch (event.type) {
          case HttpEventType.Sent:
            this.handleSent(event);
            break;

          case HttpEventType.UploadProgress:
          case HttpEventType.DownloadProgress:
            this.handleProgress(event);
            break;

          case HttpEventType.Response:
            this.handleResponse(event);
            break;

          default:
            break;
        }
      },
      error: (event) => this.handleError(event),
      complete: () => this.complete(),
    });
  }

  get onSent() {
    return this._sent_subject
      .asObservable()
      .pipe(filter((value) => value != null));
  }
  get onProgress() {
    return this._progress_subject
      .asObservable()
      .pipe(filter((value) => value != null));
  }
  get onResponse() {
    return this._response_subject
      .asObservable()
      .pipe(filter((value) => value != null));
  }
  get onError() {
    return this._error_subject
      .asObservable()
      .pipe(filter((value) => value != null));
  }
  get onAbort() {
    return this._aborted_subject
      .asObservable()
      .pipe(filter((value) => value == true));
  }

  private handleSent(event: HttpSentEvent) {
    this._sent_subject.next(event);
    this._sent_subject.complete();
  }
  private handleProgress(event: HttpProgressEvent) {
    this._progress_subject.next(event);
  }
  private handleResponse(event: HttpResponse<Page>) {
    this._response_subject.next(event);
    this._response_subject.complete();
  }
  private handleError(event: HttpErrorResponse) {
    this._error_subject.next(event);
    this._error_subject.complete();
  }
  private complete() {
    if (!this._sent_subject.closed) this._sent_subject.complete();
    if (!this._progress_subject.closed) this._progress_subject.complete();
    if (!this._response_subject.closed) this._response_subject.complete();
    if (!this._error_subject.closed) this._error_subject.complete();
    if (!this._aborted_subject.closed) this._aborted_subject.complete();
  }
}
