import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { G, Svg, SVG } from '@svgdotjs/svg.js';
import { Animation } from 'models/animation';

@Component({
  selector: 'ngx-animation-view-list-item',
  templateUrl: './animation-view-list-item.component.html',
  styleUrls: ['./animation-view-list-item.component.scss'],
})
export class AnimationViewListItemComponent implements OnInit, AfterViewInit {
  @Input('animation')
  animation: Animation;

  @Output('onDelete')
  delete_event = new EventEmitter();

  @Output('onLetterClick')
  letter_click_event = new EventEmitter();

  id: string;

  @ViewChild('container') container: ElementRef<SVGElement>;

  constructor() {}

  ngOnInit(): void {
    this.id = `animation-${this.animation.letter.id}`;
  }

  onLetterClick() {
    this.letter_click_event.emit();
  }
  onDelete() {
    this.delete_event.emit();
  }

  ngAfterViewInit() {
    const container: Svg = SVG(this.container.nativeElement) as Svg;
    const wrap = new G();
    container.add(wrap);
    container.viewbox(0, 0, 100, 170).width('30px').height('30px');
    wrap.add(this.animation.letter.el.clone());
    wrap.cx(50);
    wrap.cy(85);
    wrap.scale(2.5, 2.5);
  }

  reposition() {
    return;
  }
}
