<p-table
  #dt
  [value]="data"
  styleClass="p-datatable-striped p-datatable-sm p-datatable-gridlines"
  currentPageReportTemplate="Showing {first} to {last}"
  [showCurrentPageReport]="true"
  dataKey="id"
  [lazy]="true"
  [paginator]="true"
  [scrollable]="true"
  [loading]="loading"
  [rowHover]="true"
  [rows]="10"
  (onLazyLoad)="changePage($event)"
  scrollHeight="flex"
  responsiveLayout="scroll"
  [totalRecords]="totalCount"
>
  <ng-template pTemplate="header">
    <tr>
      <th class="bb-0" *ngFor="let column of COLUMNS" header>
        <span>{{ column }}</span>
      </th>
    </tr>

    <!--! FILTERS -->
    <tr>
      <th class="bb-0" header>
        <input
          class="w-100"
          pInputText
          placeholder="Title"
          type="text"
          [(ngModel)]="filteredTitle"
          (ngModelChange)="changeTitle($event)"
        />
      </th>
      <th class="bb-0" header>
        <input
          class="w-100"
          pInputText
          placeholder="Code"
          type="text"
          [(ngModel)]="filteredCode"
          (ngModelChange)="changeCode($event)"
        />
      </th>
      <th class="bb-0" header></th>
      <th class="bb-0" header></th>
      <th class="bb-0" header></th>
      <th class="bb-0" header>
        <input
          class="w-100"
          pInputText
          placeholder="Name"
          type="text"
          [(ngModel)]="filteredUserName"
          (ngModelChange)="changeName($event)"
        />
      </th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-coupon let-rowIndex="rowIndex">
    <tr>
      <td>
        <span class="cell-width">{{ coupon.promocode.title }}</span>
      </td>

      <td>
        <span class="cell-width primary-text">{{ coupon.promocode.code }}</span>
      </td>

      <td>
        <div class="d-flex align-items-start price gap-05rem cell-width">
          <span class="discount-price">{{
            coupon.discountedCost | number
          }}</span>
          <span class="original-price">{{ coupon.originalCost | number }}</span>
        </div>
      </td>

      <td>
        <span class="badge table-column-grey-text text-nowrap">{{
          coupon.createdAt | date: 'MMM d y'
        }}</span>
      </td>

      <td>
        <span class="badge table-column-grey-text text-nowrap">{{
          coupon.promocode.endDate | date: 'MMM d y'
        }}</span>
      </td>

      <td>
        <span class="my-fs-2 cell-width">
          {{ coupon.user.userName }}
        </span>
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="100%">No Coupons found.</td>
    </tr>
  </ng-template>
</p-table>
