<p-table
  #dt
  [value]="data"
  styleClass="p-datatable-striped p-datatable-sm p-datatable-gridlines"
  currentPageReportTemplate="Showing {first} to {last}"
  [showCurrentPageReport]="true"
  dataKey="id"
  [loading]="loading"
  [rows]="10"
  [rowHover]="true"
  [paginator]="true"
  responsiveLayout="scroll"
  (onLazyLoad)="changePage($event)"
  [totalRecords]="totalCount"
  [lazy]="true"
  scrollHeight="flex"
  [scrollable]="true"
>
  <ng-template pTemplate="header">
    <tr>
      <th header class="width-80 bb-0">
        <span>Action</span>
      </th>
      <th header class="bb-0" *ngFor="let column of COLUMNS">
        <span>{{ column }}</span>
      </th>
    </tr>

    <!--! filters -->
    <tr>
      <th header class="width-80 bb-0">
        <div class="w-100 d-flex justify-content-center">
          <button
            pButton
            type="button"
            icon="pi pi-plus"
            class="p-button-success"
            (click)="onAddVoucher()"
          ></button>
        </div>
      </th>
      <th class="bb-0" header>
        <input
          class="w-100"
          pInputText
          placeholder="Title"
          type="text"
          [(ngModel)]="filteredTitle"
          (ngModelChange)="changeTitle($event)"
        />
      </th>
      <th class="bb-0" header>
        <input
          class="w-100"
          pInputText
          placeholder="Code"
          type="text"
          [(ngModel)]="filteredCode"
          (ngModelChange)="changeCode($event)"
        />
      </th>
      <th header class="bb-0" *ngFor="let column of HEADER_FILLED_COLUMNS"></th>
      <th class="bb-0" header>
        <p-dropdown
          [ngClass]="{
            'custom-dropdown': filteredStatus === ''
          }"
          class="w-100"
          [options]="['', 'YES', 'NO']"
          [(ngModel)]="filteredStatus"
          (ngModelChange)="changeStatus($event)"
        ></p-dropdown>
      </th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-voucher let-rowIndex="rowIndex">
    <tr>
      <td class="width-80">
        <div class="w-100 d-flex justify-content-center">
          <fourqan-round-button
            [icon]="'pi pi-pencil'"
            [color]="'p-button-grey'"
            (click)="onEditVoucher(voucher)"
          ></fourqan-round-button>
        </div>
      </td>

      <td>
        <span class="cell-width">{{ voucher.title }}</span>
      </td>

      <td>
        <span class="cell-width">{{ voucher.code }}</span>
      </td>

      <td>
        <span>{{ voucher.max_number_of_usages }}</span>
      </td>

      <td>
        <span class="my-fs-2 cell-width">
          {{ voucher.number_of_usages }}
        </span>
      </td>

      <td>
        <span class="my-fs-2 cell-width">
          {{ voucher.max_number_of_usages_per_user }}
        </span>
      </td>

      <td>
        <span class="d-flex justify-content-center">
          {{ voucher.start_date | date }}
        </span>
      </td>

      <td>
        <span class="d-flex justify-content-center">
          {{ voucher.end_date | date }}
        </span>
      </td>

      <td>
        <span class="d-flex justify-content-center">
          {{ voucher.deactivated ? 'Yes' : 'No' }}
        </span>
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="100%">No Promo Codes found.</td>
    </tr>
  </ng-template>
</p-table>
