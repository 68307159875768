import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';

import { ChartOptions } from '../../../../types/chart-types';

@Component({
  selector: 'fourqan-donut-chart',
  templateUrl: './donut-chart.component.html',
  styleUrls: ['./donut-chart.component.scss'],
})
export class DonutChartComponent implements OnInit, OnChanges {
  @Input() valuesList!: Array<number>;
  @Input() labelsList!: Array<string>;
  @Input() height!: number;
  @Input() colorsList!: Array<string>;
  @Input() legendOn: boolean = true;
  @Input() legendPosition: 'right' | 'left' | 'top' | 'bottom' = 'right';

  chartOptions!: ChartOptions;

  ngOnInit(): void {
    this.chartOptions = {
      series: this.valuesList,
      chart: {
        height: this.height,
        type: 'donut',
      },
      colors: this.colorsList,
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['valuesList'] && this.valuesList) {
      this.chartOptions = {
        series: changes['valuesList'].currentValue,
        chart: {
          height: this.height,
          type: 'donut',
        },
        colors: this.colorsList,
      };
    }
  }
}
