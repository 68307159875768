import { Properties } from 'utils/properties.type';
import { Country } from './country';
import { SubscriptionPricing } from './subscription-pricing';
import {
  DiscountType,
  SubscriptionPricingDiscount,
} from './subscription-pricing-discount';

export class Subscription {
  id: number;
  name: string;
  nameEn: string;
  nameAr: string;
  nameFr: string;
  discount: {
    type: DiscountType;
    /**
     * @description between 0 and 1 in case of percentage discount
     */
    value: number;
  };
  duration: {
    years: number;
    days: number;
    months: number;
    hours: number;
    minutes: number;
  };
  login_times: number;
  max_active_tokens: number;
  descriptionEn: string;
  descriptionFr: string;
  descriptionAr: string;
  /**
   * @description time to renew after expiration
   * in minutes
   */
  allowanceTime: {
    years: number;
    days: number;
    months: number;
    hours: number;
    minutes: number;
  };
  countries: Country[];
  pricings: SubscriptionPricing[];
  pricings_discounts: SubscriptionPricingDiscount[];
  deactivated: boolean;

  constructor(spec: Properties<Subscription>) {
    Object.assign(this, spec);
    this.countries = spec.countries.map((country) => new Country(country));
    this.pricings = spec.pricings.map(
      (pricing) => new SubscriptionPricing(pricing),
    );
    this.pricings_discounts = spec.pricings_discounts.map(
      (discount) => new SubscriptionPricingDiscount(discount),
    );
  }
}
