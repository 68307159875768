/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginationDto } from 'dtos/pagination.dto';
import { environment } from 'environments/environment';
import * as _ from 'lodash';
import { User, UserDTO } from 'models/user';
import { UserRoles } from 'models/user-role';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Properties } from 'utils/properties.type';

@Injectable({ providedIn: 'root' })
export class UsersService {
  private endpoint = `${environment.server}/users`;

  constructor(private http: HttpClient) {}

  public list(
    filter?: PaginationDto,
  ): Observable<{ data: User[]; count: number }> {
    let params = new HttpParams({});
    if (filter?.from) params = params.set('from', filter.from.toString());
    if (filter?.to) params = params.set('to', filter.to.toString());
    if (filter?.username && filter.username !== '')
      params = params.set('userName', filter.username);
    if (filter?.role) params = params.set('role', filter.role);
    if (filter?.limit) params = params.set('limit', filter.limit);
    if (filter?.skip) params = params.set('skip', filter.skip);
    if (filter?.countryId) params = params.set('countryId', filter.countryId);

    return this.http
      .get<{ data: UserDTO[]; count: number }>(`${this.endpoint}/all`, {
        params: params,
      })
      .pipe(
        map((jsons) => {
          return {
            data: User.fromDTOArray(jsons.data),
            count: jsons.count,
          };
        }),
      );
  }

  public getUsersCount(): Observable<number> {
    return this.http.get<number>(`${this.endpoint}/all/count`);
  }

  update(userId: number, user: Partial<User>): Observable<User> {
    const user_dto = User.toDTO(user);
    const trimed = _.pickBy(user_dto, _.identity);
    return this.http
      .put<UserDTO>(`${this.endpoint}/${userId}`, trimed)
      .pipe(map((dto) => User.fromDTO(dto)));
  }

  updateMe(user: Partial<User>): Observable<User> {
    const user_dto = User.toDTO(user);
    const body = {
      gender: user_dto.gender,
      userName: user_dto.userName,
    } as UpdateUserInput;
    return this.http
      .put<UserDTO>(`${this.endpoint}/`, body)
      .pipe(map((dto) => User.fromDTO(dto)));
  }

  grantableRoles(role: UserRoles): UserRoles[] {
    const roles = Object.values(UserRoles);
    switch (role) {
      case UserRoles.SuperAdmin:
        return roles;
      case UserRoles.Admin:
        return [UserRoles.Editor, UserRoles.Teacher, UserRoles.User];
      case UserRoles.Editor:
      case UserRoles.Teacher:
      case UserRoles.User:
        return [];
      default:
        console.error(`role ${role} has no case.`);
        return [];
    }
  }

  canAssign(my_role: UserRoles, user_role: UserRoles): boolean {
    const roles = this.grantableRoles(my_role);
    if (roles.includes(user_role)) return true;
    else false;
  }

  public teachers(user_id: number): Observable<any> {
    return this.http
      .get<UserDTO[]>(`${this.endpoint}/${user_id}/teachers`)
      .pipe(map((dtos) => User.fromDTOArray(dtos)));
  }

  public students(user_id: number): Observable<any> {
    return this.http
      .get<UserDTO[]>(`${this.endpoint}/${user_id}/students`)
      .pipe(map((dtos) => User.fromDTOArray(dtos)));
  }

  public one(user_id: number): Observable<any> {
    return this.http
      .get<UserDTO>(`${this.endpoint}/admin/${user_id}`)
      .pipe(map((dto) => User.fromDTO(dto)));
  }

  assignStudentToTeacher(
    student_id: number,
    teacher_id: number,
  ): Observable<any> {
    return this.http.post(`${this.endpoint}/${student_id}/teachers`, {
      teacherId: teacher_id,
    });
  }

  dischargeTeacherOfStudent(
    student_id: number,
    teacher_id: number,
  ): Observable<any> {
    return this.http.delete(
      `${this.endpoint}/${student_id}/teachers/${teacher_id}`,
    );
  }

  add(
    user: Partial<Properties<User> & { password: string }>,
  ): Observable<User> {
    return this.http
      .post<UserDTO>(`${this.endpoint}/admin`, {
        username: user.username,
        email: user.email,
        role: user.role,
        password: user.password,
        gender: user.gender,
      })
      .pipe(map((dto) => User.fromDTO(dto)));
  }

  deactivate(user_id: number): Observable<User> {
    return this.http
      .put<UserDTO>(`${this.endpoint}/admin/${user_id}/deactivate`, {})
      .pipe(map((dto) => User.fromDTO(dto)));
  }

  activate(user_id: number): Observable<User> {
    return this.http
      .put<UserDTO>(`${this.endpoint}/admin/${user_id}/activate`, {})
      .pipe(map((dto) => User.fromDTO(dto)));
  }
}

interface UpdateUserInput {
  userName: string;
  gender: string;
}
