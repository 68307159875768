import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DashboardAdapter } from 'adapters/dashboard.adapter';
import { CompareColumnChartData } from 'core/page-meta-file-resolver/importers/version 1/types';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class DashboardService {
  private readonly CREATED_COUPONS_ENDPOINT: string =
    '/promocode/getTotalPromocodeCreatedAndActive';
  private readonly BENEFITTED_USERS_ENDPOINT: string =
    '/promocode/getTotalBenefitedPromocodeUsersCount';
  private readonly UNUSED_COUPONS_ENDPOINT: string =
    '/promocode/getUnusedPromocodeCount';
  private readonly SUBSCRIPTIONS_PIE: string = '/userSubscriptions/salesValue';
  private readonly SUBSCRIPTIONS_COLUMNS: string =
    '/userSubscriptions/salesValueGroupedByMonth';
  private readonly PROMOCODE_TABLE: string = '/promocode/promocodeUsages';

  constructor(
    private readonly _http: HttpClient,
    private readonly _dashboardAdapter: DashboardAdapter,
  ) {}

  public getCreatedCoupons(): Observable<{
    totalCount: number;
    activeCount: number;
  }> {
    return this._http.get<{
      totalCount: number;
      activeCount: number;
    }>(`${environment.server}${this.CREATED_COUPONS_ENDPOINT}`);
  }

  public getBenefitedUsers(): Observable<{
    count: number;
  }> {
    return this._http.get<{
      count: number;
    }>(`${environment.server}${this.BENEFITTED_USERS_ENDPOINT}`);
  }

  public getUnusedCoupons(): Observable<{
    unusedPromocodeCount: number;
  }> {
    return this._http.get<{
      unusedPromocodeCount: number;
    }>(`${environment.server}${this.UNUSED_COUPONS_ENDPOINT}`);
  }

  public getSubscriptionPie(
    startDate: Date,
    endDate: Date,
  ): Observable<{
    totalSum: number;
    totalSumWithDiscount: number;
  }> {
    const queryParam: HttpParams = new HttpParams().appendAll({
      from: startDate.toISOString(),
      to: endDate.toISOString(),
    });

    return this._http.get<{
      totalSum: number;
      totalSumWithDiscount: number;
    }>(`${environment.server}${this.SUBSCRIPTIONS_PIE}`, {
      params: queryParam,
    });
  }

  public getSubscriptionColumns(
    startDate: Date,
    endDate: Date,
  ): Observable<{
    labels: Array<string>;
    data: Array<CompareColumnChartData>;
  }> {
    const queryParam: HttpParams = new HttpParams().appendAll({
      from: startDate.toISOString(),
      to: endDate.toISOString(),
    });

    return this._http
      .get<
        Array<{
          month: string;
          totalSum: number;
          totalSumWithDiscount: number;
        }>
      >(`${environment.server}${this.SUBSCRIPTIONS_COLUMNS}`, {
        params: queryParam,
      })
      .pipe(map((res) => this._dashboardAdapter.mapTo(res)));
  }

  public getPromocodeUsage(
    limit: number,
    skip: number,
    startDate: Date,
    endDate: Date,
    name: string | null | undefined,
    title: string | null | undefined,
    code: string | null | undefined,
  ): Observable<{
    promocodeUsages: Array<{
      createdAt: string;
      originalCost: number;
      discountedCost: number;
      promocode: {
        title: string;
        code: string;
        endDate: string;
      };
      user: {
        userName: string;
      };
    }>;
    totalCount: number;
  }> {
    let queryParam: HttpParams = new HttpParams().appendAll({
      limit: limit,
      skip: skip,
      from: startDate.toISOString(),
      to: endDate.toISOString(),
    });

    if (name && name.length > 0)
      queryParam = queryParam.append('userName', name);

    if (title && title.length > 0)
      queryParam = queryParam.append('title', title);

    if (code && code.length > 0) queryParam = queryParam.append('code', code);

    return this._http.get<{
      promocodeUsages: Array<{
        createdAt: string;
        originalCost: number;
        discountedCost: number;
        promocode: {
          title: string;
          code: string;
          endDate: string;
        };
        user: {
          userName: string;
        };
      }>;
      totalCount: number;
    }>(`${environment.server}${this.PROMOCODE_TABLE}`, {
      params: queryParam,
    });
  }
}
