import { Recital } from 'models/page';
import { Properties } from 'utils/properties.type';
import { VoucherType } from './voucher-type';

export class VoucherRecitalDiscount {
  id: number;
  recital: Recital;
  promocode_id: number;
  type: VoucherType;
  discount: number;

  constructor(spec: Properties<VoucherRecitalDiscount>) {
    Object.assign(this, spec);
  }
}
