import { Injectable } from '@angular/core';
import { AdsGateway } from 'core/gateways/ads/ads-gateway.service';
import { Ad } from 'models/ads/ad';
import { BehaviorSubject } from 'rxjs';
import { mapTo, switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AdsFacade {
  private _adsSource = new BehaviorSubject<Ad[]>([]);

  constructor(private _gateway: AdsGateway) {}

  ads$() {
    return this._adsSource.asObservable();
  }

  list(): ReturnType<AdsGateway['ads']> {
    return this._gateway
      .ads()
      .pipe(tap({ next: (ads) => this._adsSource.next(ads) }));
  }

  add(...args: Parameters<AdsGateway['add']>): ReturnType<AdsGateway['add']> {
    return this._gateway
      .add(...args)
      .pipe(switchMap((response) => this.list().pipe(mapTo(response))));
  }
  edit(
    ...args: Parameters<AdsGateway['edit']>
  ): ReturnType<AdsGateway['edit']> {
    return this._gateway
      .edit(...args)
      .pipe(switchMap((response) => this.list().pipe(mapTo(response))));
  }
}
