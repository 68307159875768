import { Voucher } from 'models/vouchers/voucher';
import { VoucherDiscount } from 'models/vouchers/voucher-discount';
import { VoucherRecitalDiscount } from 'models/vouchers/voucher-recital-discount';
import { SubscriptionAdapter } from '../subscription/subscription-adapter';
import { VoucherDTO } from './voucher-dto';

export namespace VoucherAdapters {
  export function voucher(dto: VoucherDTO.Base): Voucher {
    return new Voucher({
      id: dto.id,
      code: dto.code,
      description: dto.description,
      end_date: new Date(dto.endDate),
      start_date: new Date(dto.startDate),
      is_expired: dto.isExpired,
      max_number_of_usages: dto.maxNumberOfUsage,
      number_of_usages: dto.numberOfUsage,
      max_number_of_usages_per_user: dto.maxNumberOfUsagePerUser,
      title: dto.title,
      subscriptions: dto.subscriptions
        ? SubscriptionAdapter.subscriptions(dto.subscriptions)
        : undefined,
      discounts: voucherDiscounts(dto.discounts),
      recitals_discounts: voucherRecitalDiscounts(dto.recitalsDiscounts),
      deactivated: dto.deactivated,
    });
  }
  export function vouchers(dtos: VoucherDTO.Base[]): Voucher[] {
    return dtos.map((dto) => voucher(dto));
  }
  export function voucherRecitalDiscount(
    dto: VoucherDTO.VoucherRecitalDiscountBase,
  ): VoucherRecitalDiscount {
    return new VoucherRecitalDiscount({
      discount: dto.discount,
      id: dto.id,
      promocode_id: dto.promocodeId,
      recital: dto.recital,
      type: dto.type,
    });
  }
  export function voucherRecitalDiscounts(
    dtos: VoucherDTO.VoucherRecitalDiscountBase[],
  ): VoucherRecitalDiscount[] {
    return dtos.map((dto) => voucherRecitalDiscount(dto));
  }
  export function voucherDiscount(
    dto: VoucherDTO.VoucherDiscountBase,
  ): VoucherDiscount {
    return new VoucherDiscount({
      count: dto.count,
      discount: dto.discount,
      id: dto.id,
      promocode_id: dto.promocodeId,
      type: dto.type,
    });
  }
  export function voucherDiscounts(
    dtos: VoucherDTO.VoucherDiscountBase[],
  ): VoucherDiscount[] {
    return dtos.map((dto) => voucherDiscount(dto));
  }
}
