import { PageMetaFile__Reading__Pause } from 'core/page-meta-file-resolver';

export class Pause {
  /**
   *
   * @param word_id
   * @param duration in seconds
   * @param start in seconds
   * @param auto_calculated_start
   * @param auto_calculated_duration
   */
  constructor(
    public word_id: string,
    public duration: number,
    public start: number,
    public auto_calculated_start: boolean,
    public auto_calculated_duration: boolean,
  ) {}

  public setDuration(duration: number, auto: boolean = false) {
    this.duration = duration;
    this.auto_calculated_duration = auto;
  }
  public setStart(start: number, auto: boolean = false) {
    this.start = start;
    this.auto_calculated_start = auto;
  }

  public toFileJson(): PageMetaFile__Reading__Pause {
    return {
      duration: +(this.duration * 1_000_000).toFixed(0),
      start: +(this.start * 1_000_000).toFixed(0),
      word_id: this.word_id,
    };
  }

  public static fromFileJson(json: PageMetaFile__Reading__Pause): Pause {
    return new Pause(
      json.word_id,
      json.duration / 1_000_000,
      json.start / 1_000_000,
      false,
      false,
    );
  }
}
