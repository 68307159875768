<nb-card [nbSpinner]="loading$ | async">
  <nb-card-header>
    <ng-container *ngIf="voucher; else add">
      {{ voucher.title }} | {{ voucher.code }}
    </ng-container>
    <ng-template #add> Add new voucher </ng-template>
  </nb-card-header>

  <nb-card-body [formGroup]="form">
    <div class="single-column">
      <div class="single-row">
        <div class="single-column compact">
          <label>Name:</label>
          <input
            formControlName="title"
            placeholder="Discount 10% for all"
            nbInput
          />
        </div>
        <div class="single-column compact">
          <label>Promocode:</label>
          <nb-form-field status>
            <input
              [status]="IsCodeInvalid ? 'danger' : 'basic'"
              formControlName="code"
              placeholder="A2E33B"
              nbInput
            />
            <span
              style="
                position: relative;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
              "
              [nbSpinner]="IsCodeValidationPending"
              nbSuffix
            >
              <ng-container>
                <nb-icon
                  *ngIf="IsCodeValid"
                  icon="checkmark-circle-2-outline"
                  pack="eva"
                ></nb-icon>
                <nb-icon
                  nbTooltip="Code already exists"
                  *ngIf="IsCodeInvalid"
                  icon="alert-circle-outline"
                  pack="eva"
                ></nb-icon>
              </ng-container>
            </span>
          </nb-form-field>
        </div>
      </div>
      <div class="single-row">
        <div class="single-column compact">
          <label>Max use:</label>
          <input
            type="number"
            formControlName="max_usages"
            placeholder="10"
            nbInput
          />
        </div>
        <div class="single-column compact">
          <label>Max for user:</label>
          <input
            type="number"
            formControlName="max_usages_per_user"
            placeholder="2"
            nbInput
          />
        </div>
      </div>
      <div class="single-row">
        <div class="single-column compact">
          <label>Active start at:</label>
          <input
            nbInput
            formControlName="start_date"
            placeholder="Aug 31, 2020"
            [nbDatepicker]="dateTimePicker"
          />
          <nb-datepicker #dateTimePicker></nb-datepicker>
        </div>
        <div class="single-column compact" formGroupName="valid_for">
          <label>Valid for below period:</label>
          <div class="single-row">
            <input
              type="number"
              formControlName="year"
              placeholder="year"
              nbInput
            />
            <input
              type="number"
              formControlName="month"
              placeholder="month"
              nbInput
            />
          </div>
        </div>
      </div>
      <div class="single-column compact">
        <label>Subscriptions:</label>
        <nb-tag-list (tagRemove)="removeSubscription($event.text)">
          <nb-tag
            *ngFor="let subscription of SelectedSubscriptions"
            [text]="subscription.name"
            removable
          >
          </nb-tag>
          <input
            (tagAdd)="addSubscription($event.value)"
            [formControl]="subscriptions_auto_ctrl"
            [nbAutocomplete]="auto"
            type="text"
            nbTagInput
            fullWidth
          />
        </nb-tag-list>

        <nb-autocomplete #auto (selectedChange)="addSubscription($event)">
          <nb-option
            *ngFor="let subscription of subscriptions_options$ | async"
            [value]="subscription.name"
          >
            {{ subscription.name }}
          </nb-option>
        </nb-autocomplete>
      </div>
      <div class="single-column compact">
        <label>Description:</label>
        <textarea
          formControlName="description"
          placeholder="description"
          nbInput
        ></textarea>
      </div>
      <div class="single-column">
        <label>Discount Value:</label>
        <div class="recitals-discounts" formArrayName="recitals_discounts">
          <ng-container
            [formGroupName]="index"
            *ngFor="
              let control of RecitalsDiscountsFormArray.controls;
              let index = index
            "
            class="single-row"
          >
            <label>{{ control.value.recital }}:</label>
            <nb-button-group
              [disabled]="mode === 'edit'"
              (valueChange)="control.get('type').setValue($event[0])"
            >
              <button
                nbButtonToggle
                [pressed]="control.value.type === $VoucherType.Fixed"
                [value]="$VoucherType.Fixed"
              >
                {{ $VoucherType.Fixed }}
              </button>
              <button
                nbButtonToggle
                [pressed]="control.value.type === $VoucherType.Percentage"
                [value]="$VoucherType.Percentage"
              >
                {{ $VoucherType.Percentage }}
              </button>
            </nb-button-group>
            <nb-form-field>
              <input formControlName="discount" type="number" nbInput />
              <nb-icon
                nbSuffix
                [icon]="
                  control.value.type === $VoucherType.Fixed
                    ? 'credit-card'
                    : 'percent'
                "
                pack="eva"
              >
              </nb-icon>
            </nb-form-field>
          </ng-container>
        </div>
        <div class="discounts" formArrayName="discounts">
          <ng-container
            [formGroupName]="index"
            *ngFor="
              let control of DiscountsFormArray.controls;
              let index = index
            "
            class="single-row"
          >
            <label>{{ control.value.count }} recitals selected: </label>
            <nb-button-group
              [disabled]="mode === 'edit'"
              (valueChange)="control.get('type').setValue($event[0])"
            >
              <button
                nbButtonToggle
                [pressed]="control.value.type === $VoucherType.Fixed"
                [value]="$VoucherType.Fixed"
              >
                {{ $VoucherType.Fixed }}
              </button>
              <button
                nbButtonToggle
                [pressed]="control.value.type === $VoucherType.Percentage"
                [value]="$VoucherType.Percentage"
              >
                {{ $VoucherType.Percentage }}
              </button>
            </nb-button-group>
            <nb-form-field>
              <input formControlName="discount" type="number" nbInput />
              <nb-icon
                nbSuffix
                [icon]="
                  control.value.type === $VoucherType.Fixed
                    ? 'credit-card'
                    : 'percent'
                "
                pack="eva"
              >
              </nb-icon>
            </nb-form-field>
          </ng-container>
        </div>
      </div>
      <div class="single-row">
        <nb-toggle formControlName="deactivated">Deactive</nb-toggle>
      </div>
    </div>
  </nb-card-body>

  <nb-card-footer>
    <button (click)="submit()" class="action" nbButton ghost status="info">
      Save
      <nb-icon pack="eva" icon="checkmark-outline"></nb-icon>
    </button>
    <button (click)="close()" class="action" nbButton ghost status="danger">
      Cancel
      <nb-icon pack="eva" icon="slash-outline"></nb-icon>
    </button>
  </nb-card-footer>
</nb-card>
