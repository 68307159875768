<div class="box">
  <div class="actions">
    <button (click)="deleteTween()" class="action" nbButton status="danger">
      <nb-icon icon="trash-2"></nb-icon>
    </button>
    <button (click)="toggleAnimation()" class="action" nbButton status="info">
      <nb-icon
        [icon]="
          expanded ? 'arrow-ios-upward-outline' : 'arrow-ios-downward-outline'
        "
      ></nb-icon>
    </button>
  </div>
  <div [formGroup]="form" class="form">
    <div style="flex-grow: 1">
      <input
        formControlName="start"
        type="number"
        fullWidth
        nbInput
        placeholder="start"
      />
    </div>
    <div style="flex-grow: 1">
      <input
        formControlName="end"
        type="number"
        fullWidth
        nbInput
        placeholder="end"
      />
    </div>
  </div>
</div>

<div class="animation-list" *ngIf="expanded">
  <ngx-animation-view-list-item
    (onLetterClick)="viewLetter(animtaion)"
    *ngFor="let animtaion of tween.animations"
    (onDelete)="deleteAnimation(animtaion)"
    [animation]="animtaion"
  ></ngx-animation-view-list-item>
</div>
