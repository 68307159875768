import { Element } from '@svgdotjs/svg.js';
import { fromEvent, Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

export class NgxHandler {
  $destroy = new Subject();
  picked: boolean = false;

  $move = new Subject<{ position: number }>();
  $pick = new Subject<{ position: number }>();
  $release = new Subject<{ position: number }>();

  maxmin: { position: number; max?: true; min?: true };

  constructor(
    public element: Element,
    private track: Element,
    position: number,
  ) {
    this.element.mousedown(($event) => this.handleMousePick($event));
    fromEvent(document, 'mouseup')
      .pipe(
        takeUntil(this.$destroy),
        filter(() => this.picked),
      )
      .subscribe(($event: MouseEvent) => this.handleMouseRelease($event));
    fromEvent(document, 'mousemove')
      .pipe(
        takeUntil(this.$destroy),
        filter(() => this.picked),
      )
      .subscribe(($event: MouseEvent) => this.handleMouseMove($event));
    this.$move.next({ position });
    this.$pick.next({ position });
    this.$release.next({ position });
  }

  public setMaxPosition(position: number) {
    this.maxmin = { max: true, position };
  }
  public setMinPosition(position: number) {
    this.maxmin = { min: true, position };
  }
  /**
   * @returns position on 100 ex: 26.63
   */
  public Position() {
    return (this.element.cx() / this.track.bbox().width) * 100;
  }
  public onPick(): Observable<{ position: number }> {
    return this.$pick.asObservable();
  }
  public onRelease(): Observable<{ position: number }> {
    return this.$release.asObservable();
  }
  public onMove(): Observable<{ position: number }> {
    return this.$move.asObservable();
  }

  private handleMousePick($event: MouseEvent) {
    this.pick({ emit_event: true });
  }
  private handleMouseRelease($event: MouseEvent) {
    this.release({ emit_event: true });
  }
  private handleMouseMove($event: MouseEvent) {
    const precent = this.percentage($event);
    this.move(precent, { emit_event: true });
  }
  public percentage($event: MouseEvent) {
    return ($event.offsetX / this.track.bbox().width) * 100;
  }

  public pick(options: { emit_event: boolean }) {
    this.picked = true;
    if (options.emit_event) this.$pick.next({ position: this.Position() });
  }
  public release(options: { emit_event: boolean }) {
    this.picked = false;
    if (options.emit_event) this.$release.next({ position: this.Position() });
  }

  public move(precent: number, options: { emit_event: boolean }) {
    /* TOFIX not preventing overflow */
    precent = this.minmax(precent);
    this.element.cx((precent / 100) * this.track.bbox().width);
    if (options.emit_event) this.$move.next({ position: precent });
  }

  public minmax(precent: number) {
    if (this.maxmin.max) {
      precent = Math.min(precent, this.maxmin.position);
      precent = Math.max(0, precent);
    } else if (this.maxmin.min) {
      precent = Math.max(precent, this.maxmin.position);
      precent = Math.min(100, precent);
    }
    return precent;
  }
}
