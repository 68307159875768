import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'fourqan-coupon-table',
  templateUrl: './coupon-prime-table.component.html',
  styleUrls: ['./coupon-prime-table.component.scss'],
})
export class CouponPrimeTableComponent {
  @Input() totalCount: number = 200;
  @Input() data!: Array<{
    createdAt: string;
    originalCost: number;
    discountedCost: number;
    promocode: {
      title: string;
      code: string;
      endDate: string;
    };
    user: {
      userName: string;
    };
  }>;
  @Input() loading: boolean = false;

  @Output() pageEvent = new EventEmitter<{ limit: number; skip: number }>();
  @Output() titleEvent = new EventEmitter<string | null>();
  @Output() userEvent = new EventEmitter<string | null>();
  @Output() codeEvent = new EventEmitter<string | null>();

  filteredTitle: string | null = null;
  filteredCode: string | null = null;
  filteredUserName: string | null = null;

  readonly COLUMNS: Array<string> = [
    'Title',
    'Coupon Code',
    'Price',
    'Use Date',
    'Expiration Date',
    'User Name',
  ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public changePage(pageObject: any): void {
    this.pageEvent.emit({ limit: pageObject.rows, skip: pageObject.first });
  }

  public changeTitle(name: string | null): void {
    this.titleEvent.emit(name);
  }

  public changeName(name: string | null): void {
    this.userEvent.emit(name);
  }

  public changeCode(code: string | null): void {
    this.codeEvent.emit(code);
  }
}
