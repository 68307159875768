import { Properties } from "utils/properties.type";

export class Statistic{
    creatAt:Date;
    paied:number;
    price:number;
    discount:number;
    plan:{
        name:string;
        planDiscount:number;

    };
    user:{
        userName:string;
    };
    paymentReference:string
  constructor(spec: Statistic) {
    return spec
  }
}