import { Component, OnInit } from '@angular/core';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { ExcelService } from 'app/services/excel.service';
import { SubscriptionsService } from 'app/services/subscriptions.service';
import { SubscriptionDTO } from 'core/gateways/subscription/subscription-dto';
import { Entity } from 'models/entity';
import { Recital } from 'models/page';
import { Subscription } from 'models/subscription';
import { DiscountType } from 'models/subscription-pricing-discount';
import { filter } from 'rxjs/operators';
import { toMinutes } from 'utils/date';
import {
  PricingDiscountFormValue,
  RecitalFormValue,
} from '../../components/subscription-card/subscription-card.component';
import {
  SubscriptionEditDialogComponent,
  SubscriptionFormValue,
} from '../../dialogs/subscription-edit-dialog/subscription-edit-dialog.component';

@Component({
  selector: 'ngx-subscription-container',
  templateUrl: './subscription-container.component.html',
  styleUrls: ['./subscription-container.component.scss'],
})
export class SubscriptionContainerComponent implements OnInit {
  subscriptions: Entity<Subscription[]> = new Entity([]);

  constructor(
    private subscriptions_service: SubscriptionsService,
    private nb_dialog: NbDialogService,
    private _toaster: NbToastrService,
    private excelService: ExcelService,
  ) {}

  ngOnInit(): void {
    this.fetch();
  }

  downloadExcel(): void {
    this.excelService.generateSubscriptionExcel();
  }

  fetch(): void {
    this.subscriptions.wait();
    this.subscriptions_service.list().subscribe((subscriptions) => {
      this.subscriptions.load(subscriptions);
    });
  }

  async save(form_value: SubscriptionFormValue) {
    const dto = this._formValueToCreateDTO(form_value);
    return this.subscriptions_service.save(dto).toPromise();
  }

  async edit(subscription_id: number, form_value: SubscriptionFormValue) {
    const dto = this._formValueToCreateDTO(form_value);
    return this.subscriptions_service.edit(subscription_id, dto).toPromise();
  }

  onCommitEdit(
    subscription: Subscription,
    $event: SubscriptionFormValue,
  ): void {
    this.edit(subscription.id, $event).then(() => {
      this.fetch();
    });
  }

  onAddNew() {
    this.nb_dialog
      .open(SubscriptionEditDialogComponent, { context: {} })
      .onClose.pipe(filter((result) => !!result))
      .subscribe((result) => {
        this.subscriptions.wait();
        this.save(result.form)
          .then(() => {
            this.fetch();
          })
          .catch((error) => {
            this._toaster.danger(error.message);
            this.subscriptions.load(this.subscriptions.Data);
          });
      });
  }

  onStartEdit(subscription: Subscription) {
    this.nb_dialog
      .open(SubscriptionEditDialogComponent, { context: { subscription } })
      .onClose.pipe(filter((result) => !!result))
      .subscribe((result) => {
        this.subscriptions.wait();
        this.edit(subscription.id, result.form).then(() => {
          this.fetch();
        });
      });
  }

  onCommitNew($event: SubscriptionFormValue) {
    this.save({
      ...$event,
    }).then(() => {
      this.fetch();
    });
  }

  private _formValueToCreateDTO(
    fv: SubscriptionFormValue,
  ): SubscriptionDTO.CreateBody {
    return {
      allowanceTime: toMinutes(
        fv.allowance.years,
        fv.allowance.months,
        0,
        fv.allowance.days,
        fv.allowance.hours,
      ),
      discount:
        fv.discount.type === DiscountType.Fixed
          ? fv.discount.value
          : fv.discount.value / 100,
      discountType: fv.discount.type,
      countriesIds: fv.countries.map((country) => country.id),
      descriptionAr: fv.descriptionAr,
      descriptionFr: fv.descriptionFr,
      descriptionEn: fv.descriptionEn,
      duration: toMinutes(
        fv.duration.years,
        fv.duration.months,
        0,
        fv.duration.days,
        fv.duration.hours,
      ),
      loginTimes: fv.login_times,
      maxActiveTokens: fv.max_active_tokens,
      nameEn: fv.nameEn,
      nameFr: fv.nameFr,
      nameAr: fv.nameAr,
      pricings: Object.values(Recital).map((recital) =>
        toPricing(recital, fv[recital]),
      ),
      pricings_discounts: fv.discounts
        .filter(
          (value): value is Required<PricingDiscountFormValue> => !!value.type,
        )
        .map(({ discount, type, count }) => ({
          count,
          discount: type === DiscountType.Fixed ? discount : discount / 100,
          type,
        })),
      deactivated: fv.deactivated,
    };

    function toPricing(
      recital: Recital,
      pv: RecitalFormValue,
    ): SubscriptionDTO.SubscriptionPricingCreateBody {
      return {
        enabledByDefault: pv.default,
        price: pv.price,
        recital,
      };
    }
  }
}
