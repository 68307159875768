import { Properties } from 'utils/properties.type';

export enum DiscountType {
  Percentage = 'percentage',
  Fixed = 'fixed',
}

export class SubscriptionPricingDiscount {
  id: number;
  count: number;
  type: DiscountType;
  discount: number;
  subscriptionId: number;

  constructor(spec: Properties<SubscriptionPricingDiscount>) {
    Object.assign(this, spec);
  }
}
