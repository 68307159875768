<div class="container">
  <nb-card [formGroup]="form">
    <nb-card-header class="card-header">
      <div
        style="
          height: fit-content;
          margin-inline-end: 1rem;
          display: flex;
          flex-flow: column nowrap;
        "
      >
        <label for="new-sub-name-input" style="margin-inline-end: 1rem"
          >Name english:</label
        >
        <input
          formControlName="nameEn"
          id="new-sub-name-input"
          placeholder="name"
          nbInput
        />
      </div>
      <div
        style="
          height: fit-content;
          margin-inline-end: 1rem;
          display: flex;
          flex-flow: column nowrap;
        "
      >
        <label for="new-sub-name-input" style="margin-inline-end: 1rem"
          >Name arabic:</label
        >
        <input
          formControlName="nameAr"
          id="new-sub-name-input"
          placeholder="name"
          nbInput
        />
      </div>
      <div
        style="
          height: fit-content;
          margin-inline-end: 1rem;
          display: flex;
          flex-flow: column nowrap;
        "
      >
        <label for="new-sub-name-input" style="margin-inline-end: 1rem"
          >Name france:</label
        >
        <input
          formControlName="nameFr"
          id="new-sub-name-input"
          placeholder="name"
          nbInput
        />
      </div>
    </nb-card-header>
    <nb-card-body>
      <div style="display: flex; flex-flow: column nowrap">
        <div class="basic-info">
          <div>
            <label style="display: block">Max Login Times:</label>
            <input
              type="number"
              formControlName="login_times"
              placeholder="max logins"
              nbInput
            />
          </div>
          <div>
            <label style="display: block">Max Active Tokens:</label>
            <input
              type="number"
              formControlName="max_active_tokens"
              placeholder="max active tokens"
              nbInput
            />
          </div>
        </div>
        <div
          style="margin-top: 16px"
          class="discount-ctrl"
          formGroupName="discount"
        >
          <label>Discount:</label>
          <div class="content">
            <nb-button-group
              [multiple]="false"
              (valueChange)="onDiscountTypeChange($event[0])"
            >
              <button
                nbButtonToggle
                [pressed]="DiscountType === $Discount.Fixed"
                [value]="$Discount.Fixed"
              >
                {{ $Discount.Fixed }}
              </button>
              <button
                nbButtonToggle
                [pressed]="DiscountType === $Discount.Percentage"
                [value]="$Discount.Percentage"
              >
                {{ $Discount.Percentage }}
              </button>
            </nb-button-group>
            <nb-form-field>
              <input formControlName="value" type="number" nbInput />
              <nb-icon nbSuffix [icon]="DiscountTypeIcon" pack="eva"> </nb-icon>
            </nb-form-field>
          </div>
        </div>
        <div class="description" style="margin-top: 1rem">
          <div>
            <label style="display: block">Description English:</label>
            <input
              formControlName="descriptionEn"
              placeholder="description"
              nbInput
            />
          </div>
          <div>
            <label style="display: block">Description Arabic:</label>
            <input formControlName="descriptionAr" placeholder="وصف" nbInput />
          </div>
          <div>
            <label style="display: block">Description Frensh:</label>
            <input
              formControlName="descriptionFr"
              placeholder="description"
              nbInput
            />
          </div>
        </div>
        <div style="width: 100%; margin-top: 1rem">
          <label>Duration: </label>
          <div
            formGroupName="duration"
            style="display: flex; flex-flow: row nowrap"
          >
            <section style="margin-inline-end: 1rem">
              <input
                formControlName="years"
                placeholder="years"
                type="number"
                style="width: 5rem"
                nbInput
              />
            </section>
            <section style="margin-inline-end: 1rem">
              <input
                formControlName="months"
                placeholder="months"
                type="number"
                style="width: 5rem"
                nbInput
              />
            </section>
            <section style="margin-inline-end: 1rem">
              <input
                formControlName="days"
                placeholder="days"
                type="number"
                style="width: 5rem"
                nbInput
              />
            </section>
            <section style="margin-inline-end: 1rem">
              <input
                formControlName="hours"
                placeholder="hours"
                type="number"
                style="width: 5rem"
                nbInput
              />
            </section>
          </div>
        </div>
        <div style="width: 100%; margin-top: 1rem">
          <label>Allowance: </label>
          <div
            formGroupName="allowance"
            style="display: flex; flex-flow: row nowrap"
          >
            <section style="margin-inline-end: 1rem">
              <input
                formControlName="years"
                placeholder="years"
                type="number"
                style="width: 5rem"
                nbInput
              />
            </section>
            <section style="margin-inline-end: 1rem">
              <input
                formControlName="months"
                placeholder="months"
                type="number"
                style="width: 5rem"
                nbInput
              />
            </section>
            <section style="margin-inline-end: 1rem">
              <input
                formControlName="days"
                placeholder="days"
                type="number"
                style="width: 5rem"
                nbInput
              />
            </section>
            <section style="margin-inline-end: 1rem">
              <input
                formControlName="hours"
                placeholder="hours"
                type="number"
                style="width: 5rem"
                nbInput
              />
            </section>
          </div>
        </div>
        <div style="width: 100%; margin-top: 1rem">
          <label>Countries:</label>
          <nb-tag-list (tagRemove)="removeCountry($event.text)">
            <nb-tag
              *ngFor="let country of SelectedCountries"
              [text]="country.ISO"
              removable
            ></nb-tag>
            <input
              (tagAdd)="addCountry($event.value)"
              [formControl]="countries_auto_ctrl"
              [nbAutocomplete]="auto"
              type="text"
              nbTagInput
              fullWidth
            />
          </nb-tag-list>

          <nb-autocomplete #auto (selectedChange)="addCountry($event)">
            <nb-option
              *ngFor="let country of countries_options$ | async"
              [value]="country.ISO"
            >
              {{ country.ISO }} | {{ country.name }}
            </nb-option>
          </nb-autocomplete>
        </div>
        <div class="recitals" style="width: 100%; margin-top: 1rem">
          <ng-container
            [formGroupName]="recital"
            *ngFor="let recital of Recitals"
          >
            <nb-toggle
              style="justify-self: end"
              labelPosition="start"
              formControlName="default"
              >{{ recital }}</nb-toggle
            >
            <input formControlName="price" type="number" nbInput />
          </ng-container>
        </div>
        <div
          class="discounts"
          style="width: 100%; margin-top: 1rem"
          formArrayName="discounts"
        >
          <div
            class="discounts-discount-ctrl"
            [formGroupName]="index"
            *ngFor="
              let discount_ctrl of DiscountsCtrl.controls;
              let index = index
            "
          >
            <label>{{ discount_ctrl.value.count }} recitals selected: </label>
            <nb-button-group
              (valueChange)="discount_ctrl.get('type').setValue($event[0])"
            >
              <button
                nbButtonToggle
                [pressed]="discount_ctrl.value.type === $Discount.Fixed"
                [value]="$Discount.Fixed"
              >
                {{ $Discount.Fixed }}
              </button>
              <button
                nbButtonToggle
                [pressed]="discount_ctrl.value.type === $Discount.Percentage"
                [value]="$Discount.Percentage"
              >
                {{ $Discount.Percentage }}
              </button>
            </nb-button-group>
            <nb-form-field>
              <input formControlName="discount" type="number" nbInput />
              <nb-icon
                nbSuffix
                [min]="0"
                [max]="
                  discount_ctrl.value.type === $Discount.Percentage
                    ? 100
                    : undefined
                "
                [icon]="
                  discount_ctrl.value.type === $Discount.Fixed
                    ? 'credit-card'
                    : 'percent'
                "
                pack="eva"
              >
              </nb-icon>
            </nb-form-field>
          </div>
        </div>
        <div style="width: 100%; margin-top: 1rem">
          <nb-toggle formControlName="deactivated">Deactive</nb-toggle>
        </div>
      </div>
    </nb-card-body>
    <nb-card-footer>
      <div class="footer-actions">
        <button (click)="submit()" class="action" nbButton ghost status="info">
          Save
          <nb-icon pack="eva" icon="checkmark-outline"></nb-icon>
        </button>
        <button (click)="close()" class="action" nbButton ghost status="danger">
          Cancel
          <nb-icon pack="eva" icon="slash-outline"></nb-icon>
        </button>
      </div>
    </nb-card-footer>
  </nb-card>
</div>
