<div class="d-flex gap-1rem w-100 height-100 icon-bg">
  <div class="d-flex gap-1rem" *ngFor="let item of displayList; let i = index">
    <div class="vertical-line" *ngIf="i !== 0"></div>
    <div class="d-flex flex-column gap-05rem">
      <span class="number">
        {{ item.value | number }}
      </span>
      <span class="text">
        {{ item.text }}
      </span>
    </div>
  </div>
</div>
