import { NgModule } from '@angular/core';

import { RadialBarComponent } from './RadialBar-chart.Component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [RadialBarComponent],
  imports: [CommonModule, NgApexchartsModule],
  exports: [RadialBarComponent],
})
export class RadialBarModule {}
