import { CommonModule } from '@angular/common';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgModule } from '@angular/core';
import { DonutChartComponent } from './donut-chart.component';

@NgModule({
  declarations: [DonutChartComponent],
  imports: [NgApexchartsModule, CommonModule],
  exports: [DonutChartComponent],
})
export class DonutChartModule {}
