import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { NgxPageService } from 'app/services/pages.service';
import { Page } from 'models/page';

@Injectable()
export class PagesResolver implements Resolve<Page[]> {
  constructor(public pages_service: NgxPageService) {}

  resolve(): Promise<Page[]> {
    return this.pages_service.list().toPromise();
  }
}
