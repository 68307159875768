<h1 id="title" class="title">{{ 'AUTH.LOGIN' | translate }}</h1>
<p class="sub-title">{{'AUTH.WELCOME' | translate}}</p>

<nb-alert *ngIf="alerts.size >= 1" outline="danger" role="alert">
    <p class="alert-title"><b>{{ 'AUTH.ERROR ALERT' | translate }}</b></p>
    <ul class="alert-message-list">
        <li *ngFor="let error of alerts" class="alert-message">{{ error | translate }}</li>
    </ul>
</nb-alert>

<ngx-email-password-login-form [formControl]="email_password_control"></ngx-email-password-login-form>

<button (click)="onLogin()" nbButton fullWidth status="primary" size="large">
    {{ 'AUTH.LOGIN CAPTION' | translate }}
</button>