<nb-card class="card-container" [nbSpinner]="loading">
  <nb-card-header>
    <ng-container *ngIf="IsEditMode; else add">
      {{ ad.name }}
    </ng-container>
    <ng-template #add> Add new ad </ng-template>
  </nb-card-header>

  <nb-card-body [formGroup]="form">
    <div class="single-column">
      <div class="single-row">
        <div class="control">
          <label for="name-ctrl">Name:</label>
          <input id="name-ctrl" formControlName="name" nbInput />
        </div>
        <div class="control">
          <label for="type-ctrl">Type:</label>
          <nb-select fullWidth id="type-ctrl" formControlName="type">
            <nb-option
              *ngFor="let adtypeOption of adtypeOptions"
              [value]="adtypeOption.value"
              >{{ adtypeOption.display }}</nb-option
            >
          </nb-select>
        </div>
      </div>
      <div class="single-row">
        <div class="control">
          <label for="background-color-ctrl">Background color:</label>
          <input
            [style.background-color]="ColorPickerBackgroundColor"
            colorPicker
            (colorPickerChange)="handleBackgroundColorChange($event)"
            id="background-color-ctrl"
            formControlName="backgroundColor"
            nbInput
          />
        </div>
        <div class="control">
          <label for="title-ctrl">Title:</label>
          <input id="title-ctrl" formControlName="title" nbInput />
        </div>
        <div class="control">
          <label for="text-ctrl">Text:</label>
          <input id="text-ctrl" formControlName="text" nbInput />
        </div>
      </div>
      <div class="single-row">
        <div class="control">
          <label for="time-on-screen-ctrl">Time on screen:</label>
          <input
            type="number"
            id="time-on-screen-ctrl"
            formControlName="timeOnScreen"
            nbInput
          />
        </div>
        <div class="control">
          <label for="cool-down-time-ctrl">Cooldown time:</label>
          <input
            type="number"
            id="cool-down-time-ctrl"
            formControlName="coolDownTime"
            nbInput
          />
        </div>
      </div>
      <div class="single-row">
        <div class="control">
          <label for="active-after-ctrl">Active after:</label>
          <input
            id="active-after-ctrl"
            formControlName="activeAfter"
            nbInput
            [nbDatepicker]="activeAfterTimePicker"
          />
          <nb-datepicker #activeAfterTimePicker></nb-datepicker>
        </div>
        <div class="control">
          <label for="active-after-ctrl">Inactive after:</label>
          <input
            id="inactive-after-ctrl"
            formControlName="inactiveAfter"
            nbInput
            [nbDatepicker]="inactiveAfterTimePicker"
          />
          <nb-datepicker #inactiveAfterTimePicker></nb-datepicker>
        </div>
      </div>
      <div class="single-row">
        <div class="control">
          <label for="close-at-end-ctrl">Close at end:</label>
          <nb-toggle
            id="close-at-end-ctrl"
            formControlName="closeAtEnd"
          ></nb-toggle>
        </div>
        <div class="control">
          <label for="close-button-ctrl">Close button:</label>
          <nb-toggle
            id="close-button-ctrl"
            formControlName="closeButton"
          ></nb-toggle>
        </div>
      </div>
      <div class="single-row">
        <div class="control">
          <label for="show-at-start-ctrl">Show at start:</label>
          <nb-toggle
            id="show-at-start-ctrl"
            formControlName="showAtStart"
          ></nb-toggle>
        </div>
        <div class="control">
          <label for="deactivated-ctrl">Deactivated:</label>
          <nb-toggle
            id="deactivated-ctrl"
            formControlName="deactivated"
          ></nb-toggle>
        </div>
      </div>
      <nb-button-group (valueChange)="selectedMediaType = $event">
        <button nbButtonToggle [value]="MediaType.Image">Image</button>
        <button nbButtonToggle [value]="MediaType.Video">Video</button>
      </nb-button-group>
      <ng-container [ngSwitch]="selectedMediaType">
        <ngx-image-picker
          *ngSwitchCase="MediaType.Image"
          formControlName="media"
          class="image-picker"
        ></ngx-image-picker>

        <ngx-video-picker
          *ngSwitchCase="MediaType.Video"
          formControlName="media"
          class="video-picker"
        ></ngx-video-picker>
      </ng-container>
    </div>
  </nb-card-body>

  <nb-card-footer>
    <button
      [disabled]="!form.valid"
      (click)="submit()"
      class="action"
      nbButton
      ghost
      status="info"
    >
      Save
      <nb-icon pack="eva" icon="checkmark-outline"></nb-icon>
    </button>
    <button (click)="close()" class="action" nbButton ghost status="danger">
      Cancel
      <nb-icon pack="eva" icon="slash-outline"></nb-icon>
    </button>
  </nb-card-footer>
</nb-card>
