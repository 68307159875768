import { Component, OnInit } from '@angular/core';
import { Profile } from 'app/services/profile.service';
import { User } from 'models/user';
import { UserRoles } from 'models/user-role';
import { BehaviorSubject } from 'rxjs';
import menu from './menu-content';

@Component({
  selector: 'ngx-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent implements OnInit {
  // menu = menu;
  menu=new BehaviorSubject(menu)
  me: Partial<User> = { role: UserRoles.User };
  
  constructor(private profile: Profile, ) {}

  ngOnInit(): void {

    /* TODO Hide the Dashboard option from the minu */
    this.profile.Me().then((me) => {
      let newMenu=this.menu.getValue()
      newMenu[0].hidden=![UserRoles.SuperAdmin].includes(me.role)
      this.menu.next(newMenu)
    });

  }
}
