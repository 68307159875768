import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Lesson, LessonDTO } from 'models/lesson';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NgxLessonsService {
  private endpoint = `${environment.server}/lesson`;

  constructor(public http: HttpClient) {}

  create(dto: CreateLessonDTO): Observable<Lesson> {
    return this.http
      .post<LessonDTO>(`${this.endpoint}`, { ...dto })
      .pipe(map((_dto) => Lesson.fromDTO(_dto)));
  }

  update(id: number, dto: UpdateLessonDTO): Observable<Lesson> {
    return this.http
      .put<LessonDTO>(`${this.endpoint}/${id}`, { ...dto })
      .pipe(map((_dto) => Lesson.fromDTO(_dto)));
  }

  delete(id: number): Observable<any> {
    return this.http.delete(`${this.endpoint}/${id}`);
  }

  get(): Observable<Lesson[]> {
    return this.http
      .get<{ data: LessonDTO[] }>(`${this.endpoint}/admin`)
      .pipe(map(({ data }) => Lesson.fromDTO(data)));
  }
}

export interface UpdateLessonDTO {
  nameEn?: string;
  nameAr?: string;
  nameFr?: string;
  isFree?: boolean;
  isPublished?: boolean;
  index?: number;
  page?: number[];
}

export interface CreateLessonDTO {
  nameEn: string;
  nameAr: string;
  nameFr: string;
  isPublished: boolean;
  isFree: boolean;
  index: number;
  page: number[];
}
