import { User } from './user';

export enum UserRoles {
  Admin = 'admin',
  User = 'user',
  Teacher = 'teacher',
  Editor = 'editor',
  SuperAdmin = 'super_admin',
}

export class UserRole {
  id: number;
  role: UserRoles;
  user_id: number;
  user?: User;
}
