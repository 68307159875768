const colors_mapping = {
  '#00893e': '#e6af15',
  '#c51a1b': '#e9c26b',
  '#e30613': '#ff4d60',
  '#1d1d1b': '#ffffff',
  '#164193': '#ffd357',
  '#009540': '#7affb0',
  '#00abe6': '#ffd357',
  '#fffced': '#2e3136',
  '#000000': '#ffffff',
  '#892a81': '#b250ad',
  '#a0650d': '#e0af73',
  '#164194': '#ffd357',
  '#fffdee': '#36393e',
  '#00abe7': '#e9c26b',
  '#ffffff': '#030303',
  '#c51f1f': '#dfb967',
  '#038d41': '#dba612',
};

export function invertColor(color: string): string {
  return colors_mapping[color] || color;
}
