import { Properties } from 'utils/properties.type';
import { Recital } from './page';

export class SubscriptionPricing {
  id: number;
  recital: Recital;
  price: number;
  enabled_by_default: boolean;
  subscription_id: number;

  constructor(spec: Properties<SubscriptionPricing>) {
    Object.assign(this, spec);
  }

  static baseDTO(dto: SubscriptionPricingBaseDTO[]): SubscriptionPricing[];
  static baseDTO(dto: SubscriptionPricingBaseDTO): SubscriptionPricing;
  static baseDTO(
    dto: SubscriptionPricingBaseDTO | SubscriptionPricingBaseDTO[],
  ): SubscriptionPricing | SubscriptionPricing[] {
    if (Array.isArray(dto)) {
      return dto.map((one) => this.baseDTO(one));
    } else
      return new SubscriptionPricing({
        enabled_by_default: dto.enabledByDefault,
        id: dto.id,
        price: dto.price,
        recital: dto.recital,
        subscription_id: dto.subscriptionId,
      });
  }

  static toBaseDTO(
    pricing: SubscriptionPricing[],
  ): SubscriptionPricingBaseDTO[];
  static toBaseDTO(pricing: SubscriptionPricing): SubscriptionPricingBaseDTO;
  static toBaseDTO(
    pricing: SubscriptionPricing | SubscriptionPricing[],
  ): SubscriptionPricingBaseDTO[] | SubscriptionPricingBaseDTO {
    if (Array.isArray(pricing)) {
      return pricing.map((price) => this.toBaseDTO(price));
    } else
      return {
        enabledByDefault: pricing.enabled_by_default,
        id: pricing.id,
        price: pricing.price,
        recital: pricing.recital,
        subscriptionId: pricing.subscription_id,
      };
  }
}

export interface SubscriptionPricingBaseDTO {
  id: number;
  recital: Recital;
  price: number;
  enabledByDefault: boolean;
  subscriptionId: number;
}
