import { NgModule } from '@angular/core';
import { SvgEditorDirective } from './svg-editor/svg-editor.directive';
import { TrackEditorDirective } from './track-editor/track-editor.directive';

@NgModule({
  imports: [],
  declarations: [SvgEditorDirective, TrackEditorDirective],
  exports: [SvgEditorDirective, TrackEditorDirective],
})
export class NgxMainDirectivesModule {}
