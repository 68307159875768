import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import { Mailer } from 'app/services/mailer.service';
import { NgxPageService } from 'app/services/pages.service';
import { Page } from 'models/page';
import { LocalDataSource } from 'ng2-smart-table';
import { filter, switchMap } from 'rxjs/operators';
import { ConfirmationDialogComponent } from '../../components/confirmation-dialog/confirmation-dialog.component';
import { PagesRecitersViewCellComponent } from '../../components/pages-reciters-view-cell/pages-reciters-view-cell.component';
import { PageEditorContainerComponent } from '../page-editor-container/page-editor-container.component';

@Component({
  selector: 'ngx-pages-listing-container',
  templateUrl: './pages-listing-container.component.html',
  styleUrls: ['./pages-listing-container.component.scss'],
})
export class PagesListingContainerComponent implements OnInit {
  settings: any = {};
  source: LocalDataSource = new LocalDataSource([]);

  constructor(
    private router: Router,
    private page_service: NgxPageService,
    private mailer: Mailer,
    private dialog_service: NbDialogService,
  ) {}

  ngOnInit(): void {
    this.initSettings();
    this.list();
  }

  public onAddLesson($event: {
    newData: { name: string; index: string; is_published: boolean };
  }) {
    const dto = Page.toDTO({
      name: $event.newData.name,
      index: +$event.newData.index,
      is_published: $event.newData.is_published,
    });
    this.mailer.mail(PageEditorContainerComponent.name, 'dto', dto);
    this.router.navigate(['/pages/editor']);
  }
  public onUpdateLesson($event) {
    this.page_service
      .update($event.data.id, {
        name: $event.newData.name,
        index: +$event.newData.index,
        is_published: $event.newData.is_published,
      })
      .subscribe((lesson) => $event.confirm.resolve());
  }

  public onDeletePage($event) {
    const ref = this.dialog_service.open(ConfirmationDialogComponent);
    ref.onClose
      .pipe(
        filter((confirmed) => confirmed),
        switchMap(() => {
          return this.page_service.delete($event.data.id);
        }),
      )
      .subscribe({
        next: () => $event.confirm.resolve(),
        error: () => $event.confirm.reject(),
      });
  }

  private initSettings() {
    this.settings['add'] = {
      addButtonContent: '<i class="nb-plus"></i>',
      createButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
      confirmCreate: true,
    };
    this.settings['edit'] = {
      editButtonContent: '<i class="nb-edit"></i>',
      saveButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
      confirmSave: true,
    };
    this.settings['delete'] = {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true,
    };
    this.settings['actions'] = {
      columnTitle: 'Actions',
      edit: true,
      delete: true,
    };
    this.settings['columns'] = {
      index: {
        title: 'Order',
        type: 'text',
        editor: {
          type: 'text',
        },
      }
    } as Partial<Record<keyof Page, any>>;
  }

  private list() {
    this.page_service.list().subscribe((lessons) => {
      this.source.load(lessons);
    });
  }

  public onSelect($event: { data: Page; [key: string]: any }) {
    this.mailer.mail(PageEditorContainerComponent.name, 'page', $event.data);
    this.router.navigate([`/pages/${$event.data.id}`]);
  }
}
