<nb-card>
  <ng-container [formGroup]="form">
    <nb-card-header>
      <div style="display: flex; flex-flow: row nowrap; align-items: center">
        {{ subscription.name }}
        <div class="header-actions">
          <ng-content></ng-content>
        </div>
      </div>
    </nb-card-header>
    <nb-card-body>
      <div style="display: flex; flex-flow: row wrap; padding: 1rem 0">
        <div style="width: 50%">
          <div class="show info" style="font-size: 2rem">
            {{ subscription.login_times }}
          </div>
          <div style="text-align: center; margin-top: 0.5rem" class="sub">
            Max Login Times
          </div>
        </div>
        <div style="width: 50%">
          <div class="show info" style="font-size: 2rem">
            {{ subscription.max_active_tokens }}
          </div>
          <div style="text-align: center; margin-top: 0.5rem" class="sub">
            Max Active Tokens
          </div>
        </div>
        <div
          style="width: 100%; margin-top: 2rem; text-align: center"
          class="sub emphasis"
        >
          Gives access for
          <ng-container *ngIf="subscription.duration.years > 0"
            ><span class="show info" style="font-size: 1rem">
              {{ subscription.duration.years }} </span
            >years</ng-container
          >
          <ng-container *ngIf="subscription.duration.months > 0"
            ><span class="show info" style="font-size: 1rem">
              {{ subscription.duration.months }} </span
            >months</ng-container
          >
          <ng-container *ngIf="subscription.duration.days > 0"
            ><span class="show info" style="font-size: 1rem">
              {{ subscription.duration.days }} </span
            >days</ng-container
          >
          <ng-container *ngIf="subscription.duration.hours > 0">
            and
            <span class="show info" style="font-size: 1rem">
              {{ subscription.duration.hours }} </span
            >hours</ng-container
          >
        </div>
      </div>
    </nb-card-body>
    <!-- TO DO currently subscribed users -->
    <!-- <nb-card-footer>
      <div
        *ngIf="mode === 'display'"
        style="width: 100%; text-align: center"
        class="sub emphasis"
      >
        <span class="show warn" style="font-size: 1rem">20</span>
        Currently Subscribed Users
      </div>
    </nb-card-footer> -->
  </ng-container>
</nb-card>
