import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { Page, Recital } from 'models/page';
import { PageSubmitionDialogResult } from './page-submition-dialog.types';

@Component({
  selector: 'ngx-page-submition-dialog',
  templateUrl: './page-submition-dialog.component.html',
  styleUrls: ['./page-submition-dialog.component.scss'],
})
export class PageSubmitionDialogComponent implements OnInit {
  public data: Page;
  public form = new FormGroup({
    supported_readings: new FormControl(
      [],
      [Validators.required, Validators.minLength(1)],
    ),
    order: new FormControl(null, [Validators.required, Validators.min(0)]),
  });

  constructor(protected ref: NbDialogRef<PageSubmitionDialogComponent>) {}

  ngOnInit(): void {
    this.form.patchValue({
      supported_readings: this.data.recitals,
      order: this.data.index,
    });
  }

  cancel() {
    this.ref.close({
      canceled: true,
    } as PageSubmitionDialogResult);
  }
  submit() {
    if (this.form.invalid) return;
    this.ref.close({
      canceled: false,
      ...this.form.value,
    } as PageSubmitionDialogResult);
  }

  get RecitalOptions(): Recital[] {
    return Object.values(Recital);
  }

  get $SupportedReadingsControl() {
    return this.form.get('supported_readings');
  }
  get $OrderControl() {
    return this.form.get('order');
  }
}
