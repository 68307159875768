<div class="box">
  <div (click)="onLetterClick()" class="snapshot">
    <svg #container [id]="id"></svg>
  </div>
  <div class="actions">
    <button (click)="onDelete()" class="action" nbButton status="danger">
      <nb-icon icon="trash-2"></nb-icon>
    </button>
  </div>
</div>
