<nb-card>
  <nb-card-header>
    <div style="display: flex; flex-flow: row nowrap">
      <span>Subscriptions</span>
      <div class="header-actions">
        <button
          (click)="onAddSubscription()"
          nbPopoverTrigger="click"
          nbPopoverPlacement="top"
          class="action"
          nbButton
          ghost
          status="success"
        >
          <nb-icon icon="plus-square"></nb-icon>
        </button>
      </div>
    </div>
  </nb-card-header>
  <nb-card-body>
    <ng2-smart-table
      [settings]="settings"
      (deleteConfirm)="onDeleteSubscription($event)"
      [source]="source"
    ></ng2-smart-table>
  </nb-card-body>
</nb-card>
