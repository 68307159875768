import { Location } from '@angular/common';
import { HttpClient, HttpEventType, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NbToastrService } from '@nebular/theme';
import { InProgress, NgxPageService } from 'app/services/pages.service';
import { Page, PageDTO } from 'models/page';
import { ReadingType } from 'models/reading';
import { filter } from 'rxjs/operators';

@Injectable()
export class PageEditorService {
  public page_id: number;
  public page: Page;
  public progress = 0;
  state: 'loaded' | 'loading' | 'error' | 'saving';
  public mode: 'add' | 'edit';

  svg: string;
  zip: ArrayBuffer;

  constructor(
    private page_service: NgxPageService,
    private http: HttpClient,
    private route: ActivatedRoute,
    @Inject('mail') private mail: Map<string, any>,
    private toastr_service: NbToastrService,
    private location: Location,
  ) {}

  async init() {
    const has_id = this.route.snapshot.paramMap.has('id');
    has_id ? await this.initEditMode() : this.initAddMode();
  }

  async fetch() {
    this.page = await this.page_service.one(this.page_id).toPromise();
  }

  async downloadFiles() {
    const resource_url = this.page.resource_url;
    const zip_url = this.page.zip_url;

    const zip_promise = new Promise<ArrayBuffer>((resolve, reject) => {
      this.page_service.download(zip_url).subscribe({
        next: (event) => {
          if (event.type === HttpEventType.DownloadProgress)
            this.handleProgress(event.loaded, event.total);
          else if (event.type === HttpEventType.Response)
            resolve(event.body.arrayBuffer());
        },
        error: (error) => reject(error),
      });
    });
    const resource_promise = this.page_service
      .download(resource_url)
      .pipe(filter((event) => event.type === HttpEventType.Response))
      .toPromise()
      .then((event) => (event as HttpResponse<Blob>).body.text());

    const [zip, resource] = await Promise.all([zip_promise, resource_promise]);

    return { zip, resource };
  }
  handleProgress(loaded: number, total: number) {
    this.progress = (loaded / total) * 100;
  }

  async initEditMode() {
    this.state = 'loading';
    this.mode = 'edit';
    this.page_id = Number.parseInt(this.route.snapshot.paramMap.get('id'), 10);
    const page = this.mail.get('page') as Page;

    if (!this.page_id && !page)
      throw new Error('neither an id nor a lesson were provided in edit mode');

    if (page == null) await this.fetch();
    else this.page = page;

    const { resource, zip } = await this.downloadFiles();

    this.svg = resource;
    this.zip = zip;

    this.state = 'loaded';
  }
  initAddMode() {
    this.mode = 'add';
    const dto: PageDTO = this.mail.has('dto') ? this.mail.get('dto') : null;
    if (dto) this.page = Page.fromDTO(dto);
    else this.page = new Page();
    this.state = 'loaded';
  }

  async useFile(file: File) {
    const url = URL.createObjectURL(file);
    const text_svg = await this.http
      .get(url, { responseType: 'text' })
      .toPromise();
    this.svg = text_svg;
  }

  async loadZipFile() {
    if (this.zip != null) await this.page.fromZipFile(this.zip);
  }

  async save() {
    this.page.has_spelling_reading = this.page.readings.some(
      (reading) => reading.type === ReadingType.NormalReadingWithSpelling && reading.audio_url != undefined,
    );
    if (this.mode === 'edit') {
      const in_progress = await this.page_service.fullUpdate(
        this.page.id,
        this.page,
      );
      this.registerInProgress(in_progress);
    } else if (this.mode === 'add') {
      const in_progress = await this.page_service.save(this.page);
      this.registerInProgress(in_progress);
    }
  }

  registerInProgress(in_progress: InProgress) {
    this.progress = 0;
    in_progress.onSent.subscribe(() => {
      this.state = 'saving';
    });
    in_progress.onProgress.subscribe((event) => {
      this.progress = (event.loaded / event.total) * 100;
    });
    in_progress.onResponse.subscribe((event) => {
      this.state = 'loaded';
      this.toastr_service.success(
        `lesson was saved successfully`,
        'Lesson was saved',
        {
          duration: 5000,
        },
      );
      if (this.mode === 'add') this.convertToEditMode(event.body);
    });
    in_progress.onError.subscribe((error) => {
      this.state = 'loaded';
      this.toastr_service.danger(
        'consider saving it again',
        'Failed to save lesson',
        { duration: 5000 },
      );
    });
    in_progress.onAbort.subscribe(() => {
      this.progress = 0;
      this.state = 'loaded';
    });
    in_progress.run();
  }

  private convertToEditMode(page: Page) {
    this.mode = 'edit';
    this.location.go(`/pages/${page.id}`);
    this.page = page;
  }
}
