import * as version_1_importer from './version 1/importer';
import * as version_2_importer from './version 2/importer';
import * as version_3_importer from './version 3/importer';
import * as version_4_importer from './version 4/importer';
import { PageMetaFile } from './version 5/types';

interface Importer {
  toNextVersion: (from: any) => any;
  isSameVersion: (meta: any) => boolean;
}

/**
 * @description add them in order ( prev versions first )
 */
const versions: Importer[] = [
  version_1_importer,
  version_2_importer,
  version_3_importer,
  version_4_importer,
];

/**
 *
 * @param from a page from any version
 */
export function from(from: any): PageMetaFile {
  const to = importersFor(from).reduce((pre_version, importer) => {
    return importer.toNextVersion(pre_version);
  }, from);
  return to;
}

function importersFor(meta: any) {
  const first_match_index = versions.findIndex((importer) =>
    importer.isSameVersion(meta),
  );
  if (first_match_index === -1) return [];
  return versions.slice(first_match_index, versions.length);
}
