import { Injectable } from '@angular/core';
import { CompareColumnChartData } from 'types/chart-types';

@Injectable({ providedIn: 'root' })
export class DashboardAdapter {
  public mapTo(
    columnsData: Array<{
      month: string;
      totalSum: number;
      totalSumWithDiscount: number;
    }>,
  ): {
    labels: Array<string>;
    data: Array<CompareColumnChartData>;
  } {
    const labels: Array<string> = [];
    const data: Array<CompareColumnChartData> = [];

    columnsData.map((item) => {
      labels.push(new Date(item.month).toISOString());
    });
    data.push({
      name: 'Total',
      data: columnsData.map((item) => +item.totalSum.toFixed(0)),
    });
    data.push({
      name: 'After Discount',
      data: columnsData.map((item) => +item.totalSumWithDiscount.toFixed(0)),
    });

    return this.findMissingMonths(labels, data);
  }

  private findMissingMonths(
    labels: Array<string>,
    data: Array<CompareColumnChartData>,
  ): {
    labels: Array<string>;
    data: Array<CompareColumnChartData>;
  } {
    const newLabels: Array<string> = [];
    const newTotal: Array<number> = [];
    const newDiscounted: Array<number> = [];

    if (labels.length < 2) {
      return {
        labels: labels,
        data: data,
      };
    }

    // Fill first item
    this.fillArrayValues(
      newLabels,
      newTotal,
      newDiscounted,
      labels[0],
      data[0].data[0],
      data[1].data[0],
    );

    let currentDate: Date = new Date(labels[0]);
    for (let i: number = 1; i < labels.length; i++) {
      const nextDate: Date = new Date(labels[i]);
      const diffInMonths: number =
        nextDate.getMonth() -
        currentDate.getMonth() +
        12 * (nextDate.getFullYear() - currentDate.getFullYear());

      if (diffInMonths > 1) {
        // More than one month gap
        for (let j: number = 1; j <= diffInMonths - 1; j++) {
          // Dates
          const tempDate: Date = new Date(currentDate);
          const newFillerIsoDate: string = new Date(
            tempDate.setMonth(currentDate.getMonth() + j),
          ).toISOString();

          // Fill missing item
          this.fillArrayValues(
            newLabels,
            newTotal,
            newDiscounted,
            newFillerIsoDate,
            0,
            0,
          );
        }
      }

      // Fill the current API item
      this.fillArrayValues(
        newLabels,
        newTotal,
        newDiscounted,
        labels[i],
        data[0].data[i],
        data[1].data[i],
      );

      currentDate = nextDate;
    }

    return {
      labels: newLabels,
      data: [
        {
          name: 'Total',
          data: newTotal,
        },
        { name: 'After Discount', data: newDiscounted },
      ],
    };
  }

  private fillArrayValues(
    labels: Array<string>,
    totalList: Array<number>,
    discounted: Array<number>,
    isoDate: string,
    total: number,
    totalDiscount: number,
  ): void {
    labels.push(isoDate);
    totalList.push(+total.toFixed(0));
    discounted.push(+totalDiscount.toFixed(0));
  }
}
