<nb-card>
    <nb-card-header>
        Complete Page Info
    </nb-card-header>
    <nb-card-body>
        <form class="form-group" [formGroup]="form">
            <div class="supported-readings">
                <nb-select [status]="$SupportedReadingsControl.invalid ? 'danger' : 'success'" multiple placeholder="Readings" formControlName="supported_readings">
                    <nb-option *ngFor="let recital of RecitalOptions" [value]="recital">{{recital}}</nb-option>
                </nb-select>
            </div>
            <div class="page-index">
                <label class="label" for="input-page-index">Order:</label>
                <input [status]="$OrderControl.invalid ? 'danger' : 'success'" formControlName="order" id="input-page-index" nbInput placeholder="order" type="number">
            </div>
        </form>
    </nb-card-body>
    <nb-card-footer>
        <button style="margin-inline-end: 1rem;" class="cancel" nbButton ghost status="danger"
            (click)="cancel()">Cancel</button>
        <button nbButton status="success" (click)="submit()">Submit</button>
    </nb-card-footer>
</nb-card>