import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DefaultEditor } from 'ng2-smart-table';
import { Subject } from 'rxjs';
import { TableCustomToggleConfig } from './table-custom-toggle-cell.types';

@Component({
  selector: 'ngx-table-custom-toggle-cell',
  templateUrl: './table-custom-toggle-cell.component.html',
  styleUrls: ['./table-custom-toggle-cell.component.scss'],
})
export class TableCustomToggleCellComponent
  extends DefaultEditor
  implements OnInit, OnDestroy {
  control = new FormControl();

  $destroy = new Subject<void>();

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.control.valueChanges.subscribe((value) => {
      this.cell.newValue = value;
    });

    const pre_value = this.cell.getValue();
    if (pre_value != null && typeof pre_value === 'boolean')
      this.control.setValue(pre_value);
    else this.control.setValue(false);
  }

  ngOnDestroy() {
    this.$destroy.next();
    this.$destroy.complete();
  }

  get Config() {
    return this.cell?.getColumn()?.editor?.[
      'config'
    ] as TableCustomToggleConfig;
  }
}
