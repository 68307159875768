<nb-card>
    <nb-card-header>
        Pages
    </nb-card-header>

    <nb-card-body>
        <ng2-smart-table (userRowSelect)="onSelect($event)" (deleteConfirm)="onDeletePage($event)"
            (editConfirm)="onUpdateLesson($event)" (createConfirm)="onAddLesson($event)" [settings]="settings"
            [source]="source">
        </ng2-smart-table>
    </nb-card-body>
</nb-card>