import { Component, Input } from '@angular/core';

@Component({
  selector: 'fourqan-stats-card',
  templateUrl: './stats-card.component.html',
  styleUrls: ['./stats-card.component.scss'],
})
export class StatsCardComponent {
  @Input() displayList: Array<{ value: number; text: string }>;
}
