import { PageMetaFile__Reading } from 'core/page-meta-file-resolver';
import { flatten } from 'lodash';
import { Letter } from './letter';
import { Page } from './page';
import { Pause } from './pause';
import { Tween } from './tween';
import { Word } from './word';

export enum ReadingType {
  NormalReadingWithOutSpelling = 'normal-reading-without-spelling',
  NormalReadingWithSpelling = 'normal-reading-with-spelling',
}

export class Reading {
  public audio_url: string;
  private audio_file: Blob;
  public get audio() {
    return this.audio_file;
  }
  public set audio(file: Blob | File) {
    this.audio_file = file;
    this.audio_url = URL.createObjectURL(file);
  }

  public tweens: Tween[] = [];

  constructor(public page: Page, public type: ReadingType) {}

  public addPause(duration?: number, start?: number, word_id?: string) {
    let word: Word;
    if (word_id) {
      this.page.lines.some((line) => {
        const found = line.words.find((_word) => _word.id === word_id);
        word = found;
        return !!found;
      });
    } else {
      word = this.findNearestWord(start);
    }

    if (!word) return;

    const auto_pause = word.pauses[this.type].find(
      (pause) => pause.auto_calculated_duration || pause.auto_calculated_start,
    );

    if (auto_pause) word.removePause(auto_pause, this.type);

    word.addPause(start, duration, this.type, false);
  }
  public addTween(tween: Tween) {
    this.tweens.push(tween);
    this.tweens.sort((a, b) => {
      return a.start - b.start;
    });
  }
  public deleteTween(tween: Tween) {
    const index = this.tweens.indexOf(tween);
    if (index < 0) return;
    this.tweens.splice(index, 1);
  }

  /**
   *
   * @param time to test against
   * @returns the word with the nearest time
   */
  public findNearestWord(time: number): Word {
    let word_found: Word = null;
    const words = flatten(this.page.lines.map((line) => line.words));
    for (let index = 0; index < words.length; index++) {
      const pre_word = words[index - 1];
      const curr_word = words[index];

      const pre = pre_word?.durationOf(this.type);
      const curr = curr_word.durationOf(this.type);

      const is_during_current = time >= curr.start && time <= curr.end;
      const is_between_current_and_pre =
        pre != null ? time >= pre.end && time <= curr.start : false;

      if (is_during_current) {
        word_found = curr_word;
      } else if (is_between_current_and_pre) {
        const distance_to_pre_end = time - pre.end;
        const distance_to_current_start = curr.start - time;
        word_found =
          distance_to_current_start <= distance_to_pre_end
            ? curr_word
            : pre_word;
      } else {
        continue;
      }
    }
    return word_found ?? words[0];
  }
  public get Pauses(): Pause[] {
    const pauses: Pause[] = [];
    this.page.lines.forEach((line) =>
      line.words.forEach((word) => pauses.push(...word.pauses[this.type])),
    );
    return pauses;
  }

  toFileJson(): Omit<PageMetaFile__Reading, 'pauses'> {
    const json = {
      filename: this.type + '.m4a',
      type: this.type,
      tweens: this.tweens
        .map((tween) => tween.toFileJson())
        .filter((tween) => tween.animations.length > 0),
    };

    return json;
  }

  public static fromFileJson(
    page: Page,
    json: PageMetaFile__Reading,
    letters: Letter[],
  ): Reading {
    const tweens: Tween[] = json.tweens.map((tween_json) =>
      Tween.fromFileJson(tween_json, letters),
    );
    const reading = new Reading(page, json.type);
    reading.tweens = tweens;
    return reading;
  }

  async audioToFile(): Promise<ArrayBuffer | string | null> {
    if (!this.audio) return null;
    const reader = new FileReader();
    const promise = new Promise<ArrayBuffer | string>((res, rej) => {
      reader.onload = (event) => {
        res(event.target.result);
      };
      reader.onerror = (event) => {
        rej(event);
      };
    });
    reader.readAsArrayBuffer(this.audio);
    return promise;
  }
}
