import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { NgxLessonsService } from 'app/services/lessons.service';
import { NgxPageService } from 'app/services/pages.service';
import { Lesson } from 'models/lesson';
import { LocalDataSource } from 'ng2-smart-table';
import { filter, switchMap } from 'rxjs/operators';
import { ConfirmationDialogComponent } from '../../components/confirmation-dialog/confirmation-dialog.component';
import { LessonPagesEditCellComponent } from '../../components/lesson-pages-edit-cell/lesson-pages-edit-cell.component';
import { LessonPagesViewCellComponent } from '../../components/lesson-pages-view-cell/lesson-pages-view-cell.component';
import { TableCustomToggleCellComponent } from '../../components/table-custom-toggle-cell/table-custom-toggle-cell.component';

@Component({
  selector: 'ngx-lessons-listing-container',
  templateUrl: './lessons-listing-container.component.html',
  styleUrls: ['./lessons-listing-container.component.scss'],
})
export class LessonsListingContainerComponent implements OnInit {
  settings: any = {};
  source: LocalDataSource = new LocalDataSource([]);

  is_loading_lessons: boolean = false;

  constructor(
    public pages_service: NgxPageService,
    public lesson_service: NgxLessonsService,
    public route: ActivatedRoute,
    private toastr: NbToastrService,
    private dialog_service: NbDialogService,
  ) {}

  ngOnInit(): void {
    this.initSettings();
    this.load();
  }

  onCreateLesson($event) {
    this.lesson_service
      .create({
        index: +$event.newData.index,
        isPublished: $event.newData.is_published,
        isFree: $event.newData.is_free,
        nameEn: $event.newData.nameEn,
        nameAr: $event.newData.nameAr,
        nameFr: $event.newData.nameFr,
        page: $event.newData.pages.map((page) => page.id),
      })
      .subscribe({
        next: (lesson) => {
          $event.confirm.resolve(lesson);
          this.load();
        },
        error: () => {
          $event.confirm.reject();
        },
      });
  }
  onDeleteLesson($event) {
    const ref = this.dialog_service.open(ConfirmationDialogComponent);
    ref.onClose
      .pipe(
        filter((confirmed) => confirmed),
        switchMap(() => {
          return this.lesson_service.delete($event.data.id);
        }),
      )
      .subscribe({
        next: () => $event.confirm.resolve(),
        error: () => $event.confirm.reject(),
      });
  }
  onEditLesson($event) {
    this.lesson_service
      .update($event.data.id, {
        index: +$event.newData.index,
        isPublished: $event.newData.is_published,
        isFree: $event.newData.is_free,
        nameEn: $event.newData.nameEn,
        nameAr: $event.newData.nameAr,
        nameFr: $event.newData.nameFr,
        page: $event.newData.pages.map((page) => page.id),
      })
      .subscribe({
        next: (lesson) => {
          $event.confirm.resolve();
          this.load();
        },
        error: () => {
          $event.confirm.reject();
        },
      });
  }

  async load(): Promise<void> {
    this.is_loading_lessons = true;
    try {
      const lessons = await this.fetch();
      await this.source.load(lessons);
    } catch (error) {
      this.toastr.danger('something went wrong try again', 'Unexpected Error');
    } finally {
      this.is_loading_lessons = false;
    }
  }

  async fetch(): Promise<Lesson[]> {
    return this.lesson_service.get().toPromise();
  }

  private initSettings() {
    this.settings['add'] = {
      addButtonContent: '<i class="nb-plus"></i>',
      createButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
      confirmCreate: true,
    };
    this.settings['edit'] = {
      editButtonContent: '<i class="nb-edit"></i>',
      saveButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
      confirmSave: true,
    };
    this.settings['delete'] = {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true,
    };
    this.settings['actions'] = {
      columnTitle: 'Actions',
      edit: true,
      delete: true,
    };
    this.settings['columns'] = {
      nameEn: {
        title: 'English name',
        type: 'text',
      },
      nameAr: {
        title: 'Arabic name',
        type: 'text',
      },
      nameFr: {
        title: 'French name',
        type: 'text',
      },
      is_published: {
        title: 'Publish',
        type: 'text',
        editor: {
          type: 'custom',
          component: TableCustomToggleCellComponent,
          config: {
            labels: {
              label: '',
              off_label: 'unpublished',
              on_label: 'published',
            },
          },
        },
      },
      is_free: {
        title: 'Free',
        type: 'text',
        editor: {
          type: 'custom',
          component: TableCustomToggleCellComponent,
          config: {
            labels: {
              label: '',
              off_label: 'free',
              on_label: 'paid',
            },
          },
        },
      },
      pages: {
        title: 'Pages',
        type: 'custom',
        renderComponent: LessonPagesViewCellComponent,
        editor: {
          type: 'custom',
          component: LessonPagesEditCellComponent,
          pages: this.route.snapshot.data.pages,
        },
      },
      index: {
        title: 'Order',
        type: 'text',
        editor: {
          type: 'text',
        },
      },
    } as Partial<Record<keyof Lesson, any>>;
  }
}
