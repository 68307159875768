import {
  AfterViewInit,
  Component,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { Mail } from 'app/services/mailer.service';
import * as _ from 'lodash';
import { Animation } from 'models/animation';
import { Attribute } from 'models/attribute';
import { Letter } from 'models/letter';
import { Line } from 'models/line';
import { Reading, ReadingType } from 'models/reading';
import { SharedElement } from 'models/shared-element';
import { Tween } from 'models/tween';
import { DATA_WORD_ID, Word } from 'models/word';
import { WordLinkPosition } from 'models/word-link';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AnimationViewListItemComponent } from '../../components/animation-view-list-item/animation-view-list-item.component';
import { ConfirmationDialogComponent } from '../../components/confirmation-dialog/confirmation-dialog.component';
import {
  MakeWordFormDialogComponent,
  MakeWordFormDialogResult,
} from '../../components/make-word-form-dialog/make-word-form-dialog.component';
import { PageSubmitionDialogComponent } from '../../components/page-submition-dialog/page-submition-dialog.component';
import { PageSubmitionDialogResult } from '../../components/page-submition-dialog/page-submition-dialog.types';
import { PageSvgEditorComponent } from '../../components/page-svg-editor/page-svg-editor.component';
import { PageTrackEditorComponent } from '../../components/page-track-editor/page-track-editor.component';
import {
  DeletePauseEvent,
  SeekPauseEvent,
  TweenEditEvent,
  WordViewListItemComponent,
} from '../../components/word-view-list-item/word-view-list-item.component';
import { PageEditorPausesService } from './page-editor-pauses.service';
import { PageEditorTweenService } from './page-editor-tween.service';
import { PageEditorService } from './page-editor.service';

@Component({
  selector: 'ngx-page-editor-container',
  templateUrl: './page-editor-container.component.html',
  styleUrls: ['./page-editor-container.component.scss'],
  providers: [
    Mail(PageEditorContainerComponent),
    { provide: PageEditorTweenService, useClass: PageEditorTweenService },
    { provide: PageEditorService, useClass: PageEditorService },
    { provide: PageEditorPausesService, useClass: PageEditorPausesService },
  ],
})
export class PageEditorContainerComponent implements OnInit, AfterViewInit {
  @ViewChild(PageSvgEditorComponent, { static: false })
  svg_editor: PageSvgEditorComponent;
  @ViewChild(PageTrackEditorComponent)
  track_editor: PageTrackEditorComponent;

  @ViewChildren(WordViewListItemComponent)
  words_views: QueryList<WordViewListItemComponent>;
  @ViewChildren(AnimationViewListItemComponent)
  letters_views: QueryList<AnimationViewListItemComponent>;

  svg: string;

  current_reading_type: ReadingType;

  can_make_word = false;
  can_make_word_title = false;
  can_make_line = false;
  can_animate = false;
  can_set_word_timing = false;
  can_set_line_timing = false;
  can_link_word = false;
  can_unlink_word = false;

  mode: Mode = 'edit';

  running_tweens: Tween[] = [];
  comming_tweens: Tween[] = [];

  editor_is_bound: boolean = false;

  is_teacher_reading: boolean = false;

  line_left_break_text$: Observable<string | undefined>;
  line_right_break_text$: Observable<string | undefined>;

  constructor(
    private toastr_service: NbToastrService,
    private dialog_service: NbDialogService,
    public page_editor_service: PageEditorService,
    public page_editor_tweens_service: PageEditorTweenService,
    public page_editor_pause_service: PageEditorPausesService,
  ) {}

  async ngOnInit() {
    await this.page_editor_service.init();
    this.current_reading_type = this.$ReadingType.NormalReadingWithOutSpelling;
    this.page_editor_pause_service.onPause().subscribe(() => {
      this.track_editor.pause();
    });
    this.page_editor_pause_service.onPlay().subscribe(() => {
      this.track_editor.play();
    });
  }
  ngAfterViewInit() {}

  public onAnimateSelected() {
    const duration = this.track_editor.selection();
    const letters = this.svg_editor.selected('letters');
    const word = this.svg_editor.selected('word');

    const tween = new Tween('0', duration.begin, duration.end, word.id);
    const animations = letters.map((letter) => {
      const attr = new Attribute(
        'color',
        word.is_title
          ? this.getTitleBannerColor(letter.el.remember(SharedElement.BaseFill))
          : letter.el.remember(SharedElement.BaseFill),
      );
      const animation = new Animation(letter, [attr], tween);
      return animation;
    });
    tween.animations = animations;
    this.CurrentReading.addTween(tween);
  }
  public onSetWordsTiming() {
    const word = this.svg_editor.selected('word');
    const duration = this.track_editor.selection();
    const line = word.line;
    const line_duration = line.duration[this.current_reading_type];
    if (
      line_duration.start > duration.begin ||
      line_duration.end < duration.end
    ) {
      const words_starts_before = line_duration.start > duration.begin;
      const words_ends_after = line_duration.end < duration.end;
      const message =
        words_starts_before && words_ends_after
          ? 'Word time starts before line time starts and ends after line time ends'
          : words_starts_before
          ? 'Word time starts before line time starts'
          : 'Word time ends after line time ends';
      this.toastr_service.danger(message, "Can't set word timing !", {
        duration: 5000,
      });
      return;
    }
    word.setDuration(
      duration.begin,
      duration.end,
      false,
      this.current_reading_type,
    ),
      this.words_views.find((view) => view.word.id === word.id)?.repatch();
  }
  public onSetLinesTiming() {
    const lines = this.svg_editor.selected('lines');
    const duration = this.track_editor.selection();
    lines.forEach(
      (line) =>
        (line.duration[this.current_reading_type] = {
          start: duration.begin,
          end: duration.end,
          auto: false,
        }),
    );
  }
  public onClickWordViewListItem(word: Word) {
    // const links = this.page_editor_service.page
    //   .words()
    //   .filter((_word) => word.linked_words.includes(_word.id));
    const isSelected = this.svg_editor.isSelected(word);
    if (isSelected) {
      this.svg_editor.deselect(word);
    } else {
      this.svg_editor.deselect();
      this.svg_editor.select(word);
      const duration = {
        from: word.durationOf(this.current_reading_type)?.start,
        to: word.durationOf(this.current_reading_type)?.end,
      };
      if (!duration.from == null || duration.to == null) return;
      this.track_editor.track_editor.select(duration.from, duration.to);
    }
  }
  public onSelectReading(type: ReadingType) {
    this.current_reading_type = type;
    if (this.mode === 'edit') this.initEditMode();
    else if (this.mode === 'view') this.initViewMode();
  }
  public onBackgroundGenerated(type: 'dark' | 'light', bg: string) {
    if (type === 'dark') this.page_editor_service.page.DarkBackground = bg;
    else if (type === 'light')
      this.page_editor_service.page.LightBackground = bg;
  }
  public onSeparatorCalculated($event: { y_top: number; y_bottom: number }[]) {
    $event.forEach((boundy) => {
      this.page_editor_service.page.addBoundry(boundy.y_top, boundy.y_bottom);
    });
  }
  public onInsertPause() {
    let word = this.svg_editor.selected('word');

    if (!word) {
      word = this.CurrentReading.findNearestWord(
        this.track_editor.section_group.value.to,
      );
    }

    if (!word) {
      this.toastr_service.warning('Could not find a word for this time.');

      return;
    }
    const default_duration = word.getPauseDurationFor(
      this.track_editor.section_group.value.to,
      this.current_reading_type,
    );
    const duration = Number.parseFloat(
      prompt('add duration', default_duration.toString()),
    );
    if (!duration) {
      this.toastr_service.warning('Entered time was invalid.');

      return;
    }
    this.CurrentReading.addPause(
      duration,
      this.track_editor.section_group.value.to,
      word.id,
    );
  }
  public setLineBreakLeft() {
    const line = this.svg_editor.selected('line');
    line.setBreaks({
      left: !!!line.getBreaks().left,
    });
    this.svg_editor.deselect();
  }
  public setLineBreakRight() {
    const line = this.svg_editor.selected('line');
    line.setBreaks({
      right: !!!line.getBreaks().right,
    });
    this.svg_editor.deselect();
  }

  public getTitleBannerColor(color: string) {
    switch (color) {
      case '#c51f1f':
        return '#a01616';
      case '#038d41':
        return '#00692f';
      case '#ffffff':
        return '#ffd700';
      default:
        return color;
    }
  }

  public onDeleteLine(line: Line) {
    const ref = this.dialog_service.open(ConfirmationDialogComponent);
    ref.onClose.subscribe((confirmed) => {
      if (confirmed) {
        this.page_editor_service.page.deleteLine(line.id);
      }
    });
  }
  public onDeleteWord(word: Word) {
    const ref = this.dialog_service.open(ConfirmationDialogComponent);
    ref.onClose.subscribe((confirmed) => {
      if (confirmed) {
        word.line.disassembleWord(word.id);
      }
    });
  }
  public onDeleteAnimation(animation: Animation) {
    const ref = this.dialog_service.open(ConfirmationDialogComponent);
    ref.onClose.subscribe((confirmed) => {
      if (confirmed) {
        if (animation.tween.animations.length == 1)
          this.CurrentReading.deleteTween(animation.tween);
        else animation.tween.deleteAnimation(animation.letter.id);
      }
    });
  }
  public onDeletePause($event: DeletePauseEvent, word: Word) {
    const ref = this.dialog_service.open(ConfirmationDialogComponent);
    ref.onClose.subscribe((confirmed) => {
      if (confirmed) {
        word.removePause($event, this.current_reading_type);
      }
    });
  }

  public onSeekPause($event: SeekPauseEvent) {
    this.track_editor.seek($event.start);
    this.track_editor.select($event.start, null);
  }
  public onSeekTween(tween: Tween) {
    this.track_editor.select(tween.start, tween.end);
  }
  public onViewLetter(letter: Letter) {
    this.svg_editor.deselect(this.svg_editor.selected('element'));
    this.svg_editor.select(letter);
  }

  public onTrackPlay() {
    this.page_editor_pause_service.discard();
  }

  public onAnimationTimingChange(
    { start, end }: { start: number; end: number },
    animation: Animation,
  ) {
    animation.tween.start = start;
    animation.tween.end = end;
  }
  public onTweenEdit($event: TweenEditEvent) {
    $event.tween.start = $event.start;
    $event.tween.end = $event.end;
  }
  public onDeleteTween(tween: Tween) {
    const ref = this.dialog_service.open(ConfirmationDialogComponent);
    ref.onClose.subscribe((confirmed) => {
      if (confirmed) this.CurrentReading.deleteTween(tween);
    });
  }

  public onSvgFileChange(file: File) {
    this.page_editor_service.useFile(file);
  }

  public convertSelectedToLine() {
    const els = this.svg_editor.selected('elements');
    const line = new Line(
      this.page_editor_service.page.lines.length.toString(),
    );
    line.addLetter(els);
    this.page_editor_service.page.addLine(line);
    this.svg_editor.rebuildLinesAccessors();
    this.svg_editor.deselect(els);
  }
  public async convertSelectedToWord() {
    const result: MakeWordFormDialogResult = await this.dialog_service
      .open(MakeWordFormDialogComponent, {})
      .onClose.toPromise();
    if (result.canceled) return;
    const line = this.svg_editor.selected('line');
    const letters = this.svg_editor.selected('letters');
    const word = line.addWord(letters);
    word.text = result.text;
    this.svg_editor.deselect(word);
  }

  public async onSaveLesson() {
    const ref = this.dialog_service.open(PageSubmitionDialogComponent, {
      closeOnEsc: false,
      closeOnBackdropClick: false,
      hasBackdrop: true,
      autoFocus: true,
      context: { data: this.page_editor_service.page },
    });
    const result: PageSubmitionDialogResult = await ref.onClose.toPromise();
    if (result.canceled) return;

    const draft = this.svg_editor.svgDraft();
    this.page_editor_service.page.Draft = draft;
    this.page_editor_service.page.recitals = result.supported_readings;
    this.page_editor_service.page.index = result.order;

    this.page_editor_service.save();
  }
  public makeSelectedWordTitle() {
    const words = this.svg_editor.selected('words');
    if (!words || words.length == 0) return;
    words.forEach((word) => {
      word.is_title = true;
      this.tweenFor(word).forEach((tween) =>
        tween.animations.forEach((animation) => {
          const color_attr = animation.attributes.find(
            (attr) => attr.type == 'color',
          );
          color_attr.value = this.getTitleBannerColor(
            animation.letter.el.remember(SharedElement.BaseFill),
          );
        }),
      );
    });
  }
  public linkSelectedWords() {
    const [a, b] = this.svg_editor.selected('words');
    const reverse = b.line.index() < a.line.index();
    a.link({
      linked_word_id: b.id,
      linked_position: reverse
        ? WordLinkPosition.Before
        : WordLinkPosition.After,
    });
    b.link({
      linked_word_id: a.id,
      linked_position: reverse
        ? WordLinkPosition.After
        : WordLinkPosition.Before,
    });
    this._updateCanLinkWord(this.svg_editor.selected('words'));
  }
  public unlinkSelectedWords() {
    const [a, b] = this.svg_editor.selected('words');
    a.unlink(b.id);
    b.unlink(a.id);
    this._updateCanLinkWord(this.svg_editor.selected('words'));
  }

  public onSwitchMode(mode: Mode) {
    if (this.mode == mode) return;
    this.mode = mode;
    if (this.mode === 'edit') this.initEditMode();
    else if (this.mode === 'view') this.initViewMode();
  }

  public initEditMode() {
    this.page_editor_tweens_service.dispose();
    this.svg_editor.rebuildLinesAccessors();
  }
  public initViewMode() {
    this.svg_editor.normalize();
    this.svg_editor.removeLinesAccessors();
    this.page_editor_tweens_service.dispose();
    this.page_editor_pause_service.pauses = this.CurrentReading.Pauses;
    this.page_editor_tweens_service.tweens = this.CurrentReading.tweens;
    this.page_editor_tweens_service.words = _.flatten(
      this.page_editor_service.page.lines.map((line) => line.words),
    );
  }

  readAudioFile(files: FileList) {
    const file = files.item(0);
    if (!file) return;
    this.CurrentReading.audio = file;
  }

  private bindSvgEditor() {
    if (this.editor_is_bound) return;
    this.svg_editor.onSelectionChange().subscribe(() => {
      this.can_make_line = false;
      this.can_make_word = false;
      this.can_animate = false;
      this.can_make_word_title = false;
      this.can_set_line_timing = false;
      this.can_set_word_timing = false;
      const word = this.svg_editor.selected('word');
      const words = this.svg_editor.selected('words');
      const line = this.svg_editor.selected('line');
      const lines = this.svg_editor.selected('lines');
      if (line || lines.length > 0) this.can_set_line_timing = true;
      if (word || words.length > 0) this.can_set_word_timing = true;
      this._updateCanLinkWord(words);
    });
    this.svg_editor.onLineSelected().subscribe(() => {
      if (this.svg_editor.selected('line').words.length === 0) {
        this.can_make_word = true;
      } // selected a full free line
    });
    this.svg_editor.onFreePathsSelected().subscribe(() => {
      this.can_make_line = true;
    });
    this.svg_editor.onLineFreePathsSelected().subscribe(() => {
      this.can_make_word = true;
    });
    this.svg_editor.onWordSelected().subscribe((word) => {
      this.can_animate = true;
      this.can_make_word_title = true;
    });
    this.svg_editor.onMiscSelected().subscribe((misc) => {
      if (misc.length == 0) return;
      const word_id = misc[0].remember(DATA_WORD_ID);
      if (word_id == undefined) return;
      const all_under_same_word = misc.every(
        (element) => element.remember(DATA_WORD_ID) === word_id,
      );
      if (!all_under_same_word) return;
      this.can_animate = true;
    });
    this.editor_is_bound = true;
  }
  private bindTrackEditor() {}

  public afterSvgInit() {
    this.bindSvgEditor();
    this.line_left_break_text$ = this._getLineBreakLeftTextStream();
    this.line_right_break_text$ = this._getLineBreakRightTextStream();
    this.page_editor_service.loadZipFile();
    this.page_editor_service.page.Bbox = this.svg_editor.svg.svg().bbox();
    this.page_editor_service.page.Viewbox = this.svg_editor.svg.svg().viewbox();
  }

  public onSelectLine(line: Line) {
    this.svg_editor.toggleLine(line);
    const duration = {
      from: line.duration[this.current_reading_type]?.start,
      to: line.duration[this.current_reading_type]?.end,
    };
    this.track_editor.select(duration.from, duration.to);
  }

  public onTrackTick($event: number) {
    if (this.mode === 'edit') return;
    if (this.is_teacher_reading) this.page_editor_pause_service.doCheck($event);
    this.page_editor_tweens_service.doCheck($event, this.CurrentReading.type);
  }

  public runTweens(seek: number) {
    const finished_tweens_indexs: number[] = [];

    this.running_tweens.forEach((tween, index) => {
      if (tween.end <= seek) finished_tweens_indexs.push(index);
      tween.animations.forEach((animation) => animation.letter.fill('grey'));
    });

    let first_running_tween_index: number;
    let last_running_tween_index: number;
    this.CurrentReading.tweens.some((tween, index) => {
      if (tween.start > seek) return true;
      if (tween.start <= seek && tween.end <= seek) return false;

      if (first_running_tween_index == null) first_running_tween_index = index;
      last_running_tween_index = index;
      tween.apply();
    });

    if (first_running_tween_index && last_running_tween_index) {
      const running = this.comming_tweens.splice(
        first_running_tween_index,
        last_running_tween_index - first_running_tween_index + 1,
      );
      this.running_tweens.push(...running);
    }
  }

  public tweenFor(word: Word) {
    return this.CurrentReading.tweens.filter(
      (tween) => tween.word_id === word.id,
    );
  }

  public get CurrentReading(): Reading {
    return this.page_editor_service.page?.Reading(this.current_reading_type);
  }

  public get $ReadingType() {
    return ReadingType;
  }

  private _getLineBreakLeftTextStream(): Observable<string> {
    return this.svg_editor.onSelectionChange().pipe(
      map(() => {
        const line = this.svg_editor.selected('line');
        if (!line) return '';
        return line.getBreaks().left ? 'Unbreak left' : 'Break left';
      }),
    );
  }
  private _getLineBreakRightTextStream(): Observable<string> {
    return this.svg_editor.onSelectionChange().pipe(
      map(() => {
        const line = this.svg_editor.selected('line');
        if (!line) return '';
        return line.getBreaks().right ? 'Unbreak right' : 'Break right';
      }),
    );
  }
  private _updateCanLinkWord(words: Word[]) {
    if (words.length !== 2) {
      this.can_link_word = false;
      this.can_unlink_word = false;
    } else {
      const can_unlink_word = words[0].linked_words.some(
        (link) => link.linked_word_id === words[1].id,
      );
      this.can_link_word =
        words[0].line.id !== words[1].line.id && !can_unlink_word;
      this.can_unlink_word = can_unlink_word;
    }
  }
}
type Mode = 'edit' | 'view';
