export enum AdMediaType {
  Video = 'VIDEO',
  Image = 'IMAGE',
}

export function formatAdMediaType(adMediaType: AdMediaType): string {
  switch (adMediaType) {
    case AdMediaType.Image:
      return 'Image';
    case AdMediaType.Video:
      return 'Video';
    default:
      return 'Unknown';
  }
}
