import { HttpClient } from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { environment } from 'environments/environment';
import { User, UserDTO } from 'models/user';
import { UserRoles } from 'models/user-role';
import { LocalStorage } from './localstorage.service';

@Injectable({ providedIn: 'root' })
export class Profile {
  private payload: ProfilePayload;
  private localStorageKey: string = `user-profile:${environment.type}`;
  private me: User = null;

  constructor(private localStorage: LocalStorage, private http: HttpClient) {
    try {
      const payload_str = this.localStorage.get(this.localStorageKey);
      const payload = JSON.parse(payload_str);
      this.payload = payload;
    } catch (error) {
      this.payload = null;
      if (isDevMode()) console.error(error);
    }
  }

  /**
   *
   * @param v
   * @param remember if set to true the profile will be saved to localstorage
   */
  public setPayload(v: ProfilePayload, remember: boolean = false) {
    this.payload = v;
    if (remember) {
      this.localStorage.set(this.localStorageKey, JSON.stringify(this.payload));
    }
  }
  public getPayload() {
    return { ...this.payload };
  }

  public Me(force: boolean = false): Promise<User> {
    return this.me != null && force == false
      ? Promise.resolve(this.me)
      : this.http
          .get<UserDTO>(`${environment.server}/users`)
          .toPromise()
          .then((dto) => {
            const user = User.fromDTO(dto);
            this.me = user;
            return user;
          });
  }

  public get isAuthenticated() {
    return this.payload == null ? false : true;
  }
  public get canHidden() {
    return [UserRoles.SuperAdmin].includes(this.me.role);
  }
}

export interface ProfilePayload {
  id: string;
  email: string;
  access_token: string;
  is_new: string;
}
