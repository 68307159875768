<nb-card [nbSpinner]="loading" nbSpinnerSize="giant">
    <nb-card-header>
        <div style="display: flex; flex-flow: row nowrap;">
            <span>Students</span>
            <div class="header-actions">
                <button [nbPopover]="templateRef" nbPopoverTrigger="click" nbPopoverPlacement="top" class="action"
                    nbButton ghost status="success">
                    <nb-icon icon="plus-square"></nb-icon>
                </button>
            </div>
        </div>
    </nb-card-header>
    <nb-card-body>
        <ng2-smart-table (deleteConfirm)="onDeleteStudent($event)" [settings]="settings" [source]="source">
        </ng2-smart-table>
    </nb-card-body>
</nb-card>

<ng-template #templateRef>
    <div style="padding: 1rem; display: flex; flex-flow: column nowrap;">
        <label>Student: </label>
        <input [nbAutocomplete]="auto" [formControl]="autocomplete_form" nbInput>
        <nb-autocomplete #auto>

            <nb-option (click)="onSelectStudent(option)" *ngFor="let option of autocomplete_options" [value]="option.id">
                {{ option.username }}
            </nb-option>

        </nb-autocomplete>
    </div>
</ng-template>