import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NbButtonModule, NbIconModule } from '@nebular/theme';
import { VideoPickerComponent } from './video-picker.component';

@NgModule({
  declarations: [VideoPickerComponent],
  imports: [CommonModule, NbButtonModule, NbIconModule],
  exports: [VideoPickerComponent],
})
export class VideoPickerModule {}
