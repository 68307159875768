import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { G, SVG, Svg } from '@svgdotjs/svg.js';
import { Animation } from 'models/animation';
import { Letter } from 'models/letter';
import { Pause } from 'models/pause';
import { ReadingType } from 'models/reading';
import { Tween } from 'models/tween';
import { Word } from 'models/word';

@Component({
  selector: 'ngx-word-view-list-item',
  templateUrl: './word-view-list-item.component.html',
  styleUrls: ['./word-view-list-item.component.scss'],
})
export class WordViewListItemComponent implements OnInit, AfterViewInit {
  @Output('onDelete')
  delete_event = new EventEmitter();
  @Output('onSelect')
  select_event = new EventEmitter();
  @Output('onExpand')
  expand_event = new EventEmitter();
  @Output('onSeekPause')
  seek_pause_event = new EventEmitter<SeekPauseEvent>();
  @Output('onDeletePause')
  delete_pause_event = new EventEmitter<DeletePauseEvent>();
  @Output('onDeleteAnimation')
  delete_animation_event = new EventEmitter<DeleteAnimationEvent>();
  @Output('onSeekTween')
  seek_tween_event = new EventEmitter<SeekTweenEvent>();
  @Output('onAnimationTimeChange')
  animation_time_change_event = new EventEmitter<AnimationTimeChangeEvent>();
  @Output('onTweenEdit')
  tween_edit_event = new EventEmitter<TweenEditEvent>();
  @Output('onTweenDelete')
  tween_delete_event = new EventEmitter<Tween>();
  @Output('onViewLetter')
  view_letter_event = new EventEmitter<Letter>();

  @Input('word')
  word: Word;

  @Input('expanded')
  expanded = false;

  @Input('show')
  show: 'pauses' | 'tweens';

  @Input('tweens')
  tweens: Tween[] = [];

  @Input()
  public set reading(v: ReadingType) {
    this._reading = v;
    this.repatch();
  }
  public get reading(): ReadingType {
    return this._reading;
  }

  private _reading: ReadingType;

  form = new FormGroup({
    start: new FormControl({ value: 0, disabled: true }),
    end: new FormControl({ value: 0, disabled: true }),
    text: new FormControl({ value: '', disabled: false }),
    bright: new FormControl(),
    bleft: new FormControl(),
  });

  expand_tween: boolean = false;

  public pauses_controls = new FormArray([]);

  public rollback_control = new FormControl();

  @ViewChild('container') container: ElementRef<SVGElement>;

  /* TODO use differs */
  constructor() {}

  ngOnInit(): void {
    this.rollback_control.valueChanges.subscribe((value) => {
      this.word.rollback[ReadingType.NormalReadingWithSpelling] = value;
    });
    this.word.calcPosition();
    this.repatch();
    this.form
      .get('text')
      .valueChanges.subscribe((value) => (this.word.text = value));
    this.form.valueChanges.subscribe(({ bleft, bright }) => {
      this.word.setBound(bleft, bright);
    });
    this.word.onPausesChange().subscribe(({ pauses }) => {
      this.setupPauses(pauses[this.reading]);
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      const container: Svg = SVG(this.container.nativeElement) as Svg;
      const wrap = new G();
      container.add(wrap);
      container.viewbox(0, 0, 100, 170).width('50px').height('60px');
      this.word.letters().forEach((letter) => {
        wrap.add(letter.el.clone());
      });
      wrap.cy(85);
      wrap.cx(50);
    }, 0);
  }

  viewLetter(letter: Letter) {
    this.view_letter_event.emit(letter);
  }

  public repatch() {
    if (!this.word) return;
    const duration = this.word?.durationOf(this.reading) || {
      start: 0,
      end: 0,
      auto: false,
    };
    this.form.patchValue(
      {
        ...duration,
        text: this.word?.text || '',
        bright: this.word.bound_right,
        bleft: this.word.bound_left,
      },
      { emitEvent: false },
    );
    this.setupPauses(this.word.pauses[this.reading]);
    this._patchRollback();
  }
  public onDelete() {
    this.delete_event.emit();
  }
  public onSelect() {
    this.select_event.emit();
  }
  public expand() {
    this.expanded = true;
    this.expand_event.emit(true);
  }
  public collapse() {
    this.expanded = false;
    this.expand_event.emit(false);
  }

  public expandTweens() {
    this.expand_tween = true;
  }
  public collapseTweens() {
    this.expand_tween = false;
  }

  public setupPauses(pauses: Readonly<Pause[]> = []) {
    this.pauses_controls.clear();
    pauses.forEach((pause) => {
      const control = new FormGroup({
        start: new FormControl(pause.start),
        duration: new FormControl(pause.duration),
      });
      control.valueChanges.subscribe((value) => {
        if (value.duration != pause.duration)
          pause.setDuration(value.duration, false);
        if (value.start != pause.start) pause.setStart(value.start, false);
      });
      this.pauses_controls.push(control);
    });
  }

  public onSeekPause(index: number) {
    const pause = this.word.pauses[this.reading][index];
    this.seek_pause_event.emit(pause);
  }
  public onDeletePause(index: number) {
    const pause = this.word.pauses[this.reading][index];
    this.delete_pause_event.emit(pause);
  }
  public onTweenEdit($event: { start: number; end: number }, tween: Tween) {
    this.tween_edit_event.emit({ ...$event, tween });
  }
  public onTweenDelete(tween: Tween) {
    this.tween_delete_event.emit(tween);
  }

  onDeleteAnimation(animation: Animation) {
    this.delete_animation_event.emit(animation);
  }
  onAnimationTimingChange({ start, end }, animation: Animation) {
    this.animation_time_change_event.emit({ animation, start, end });
  }
  onSeekTween(tween: Tween) {
    this.seek_tween_event.emit(tween);
  }

  private _patchRollback() {
    if (!this.word.rollback) return;
    this.rollback_control.setValue(
      this.word.rollback[ReadingType.NormalReadingWithSpelling],
    );
  }
}

export interface SeekPauseEvent extends Pause {}
export interface DeletePauseEvent extends Pause {}
export interface DeleteAnimationEvent extends Animation {}
export interface SeekTweenEvent extends Tween {}
export interface AnimationTimeChangeEvent {
  animation: Animation;
  start: number;
  end: number;
}

export interface TweenEditEvent {
  tween: Tween;
  start: number;
  end: number;
}
