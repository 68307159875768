import * as Version3 from '../version 3/types';
import * as Version2 from './types';

export function toNextVersion(
  from: Version2.PageMetaFile,
): Version3.PageMetaFile {
  return {
    dark_background_svg: from.dark_background_svg,
    light_background_svg: from.light_background_svg,
    lines: upgradeLines(from.lines),
    readings: from.readings,
    version: '3',
  };
}

export function isSameVersion(meta: any): boolean {
  return meta.version == 2;
}

function upgradeLines(
  lines: Version2.PageMetaFile__Line[],
): Version3.PageMetaFile__Line[] {
  return lines.map((line) => ({
    ...line,
    words: upgradeWords(line.words),
  }));
}

function upgradeWords(
  words: Version2.PageMetaFile__Line__Word[],
): Version3.PageMetaFile__Line__Word[] {
  return words.map((word) => ({
    ...word,
    rollback: {},
  }));
}
