import { Component, OnInit } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { AdsFacade } from 'core/facade/ads-facade.service';
import { Ad } from 'models/ads/ad';
import { formatAdMediaType } from 'models/ads/ad-media-type';
import { formatAdType } from 'models/ads/ad-type';
import { LocalDataSource } from 'ng2-smart-table';
import { AddEditAdDialogComponent } from './dialogs/add-edit-ad-dialog/add-edit-ad-dialog.component';

@Component({
  selector: 'ngx-ads-container',
  templateUrl: './ads-container.component.html',
  styleUrls: ['./ads-container.component.scss'],
})
export class AdsContainerComponent implements OnInit {
  source: LocalDataSource = new LocalDataSource([]);

  settings: any;

  constructor(private _facade: AdsFacade, private _nbDialog: NbDialogService) {
    _facade.ads$().subscribe((ads) => this._loadAds(ads));
    this._facade.list().subscribe();
    this.settings = this._buildSettings();
  }

  ngOnInit(): void {}

  addNew() {
    this._nbDialog.open(AddEditAdDialogComponent, { context: {} });
  }

  edit(ad: Ad) {
    this._nbDialog.open(AddEditAdDialogComponent, { context: { ad } });
  }

  private _buildSettings() {
    return {
      actions: {
        add: true,
        edit: true,
        delete: false,
      },
      edit: {
        editButtonContent: '<i class="nb-edit"></i>',
      },
      add: {
        addButtonContent: '<i class="nb-plus"></i>',
      },
      mode: 'external',
      columns: {
        name: {
          title: 'Name',
          type: 'text',
        },
        typeFormated: {
          title: 'Type',
          type: 'text',
        },
        mediaType: {
          title: 'Media',
          type: 'text',
        },
        deactivated: {
          title: 'is Disabled',
          type: 'boolean',
        },
      } as Partial<Record<keyof Ad, any>>,
    };
  }
  private _loadAds(ads: Ad[]): void {
    this.source.load(
      ads.map((ad) => ({
        ...ad,
        typeFormated: formatAdType(ad.type),
        mediaType: ad.media ? formatAdMediaType(ad.media.type) : 'None',
      })),
    );
  }
}
