import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isDate } from 'date-fns';
import { environment } from 'environments/environment';
import { Ad } from 'models/ads/ad';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AdsAdapter } from './ads-adapter';
import { AdsDTO } from './ads-dto';

@Injectable({
  providedIn: 'root',
})
export class AdsGateway {
  private _endpoint = `${environment.server}/ad/admin`;

  constructor(private _http: HttpClient) {}

  ads(): Observable<Ad[]> {
    return this._http
      .get<AdsDTO.Base[]>(this._endpoint)
      .pipe(map((dtos) => AdsAdapter.ads(dtos)));
  }

  add(ad: AdsDTO.AdAddBody, media?: File) {
    const formdata = new FormData();
    this._appendToFormdata(formdata, ad);
    if (media) formdata.append('media', media);
    return this._http
      .post<AdsDTO.Base>(`${this._endpoint}`, formdata)
      .pipe(map((x) => AdsAdapter.ad(x)));
  }
  edit(adId: number, ad: AdsDTO.AdEditBody, media?: File) {
    const formdata = new FormData();
    this._appendToFormdata(formdata, ad);
    if (media) formdata.append('media', media);
    return this._http
      .put<AdsDTO.Base>(`${this._endpoint}/${adId}`, formdata)
      .pipe(map((x) => AdsAdapter.ad(x)));
  }

  private _appendToFormdata(fd: FormData, object: any, parentChain?: string) {
    for (var key in object) {
      const value = object[key];
      if (isDate(value)) {
        fd.append(key, (value as Date).toISOString());
        continue;
      }
      if (typeof value === 'object') {
        // Need testing and verification
        this._appendToFormdata(
          fd,
          value,
          parentChain ? `${parentChain}[${key}]` : `${key}`,
        );
        continue;
      }
      if (parentChain) {
        fd.append(`${parentChain}[${key}]`, object[key]);
        continue;
      }
      fd.append(key, object[key]);
    }
  }
}
