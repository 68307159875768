import { Injectable } from '@angular/core';
import { HandlerContext } from 'app/shared/forms-error-notifier/forms-error-notifier.interface';
import { FormsErrorNotifierService } from 'app/shared/forms-error-notifier/forms-error-notifier.service';
import { VoucherEditDialogErrorsToasterService } from './error-toaster.service';

@Injectable()
export class VoucherEditDialogErrorsService {
  constructor(
    private _toastrService: VoucherEditDialogErrorsToasterService,
    private _basicNotifier: FormsErrorNotifierService,
  ) {}

  addHandlers() {
    this._basicNotifier.addHandlers(
      {
        selector: (ctx) => ctx.errorKey === 'code',
        executer: this._codeHandler.bind(this),
      },
      {
        selector: (ctx) => ctx.errorKey === 'discount',
        executer: this._discountHandler.bind(this),
      },
      {
        selector: (ctx) => ctx.errorKey === 'required',
        executer: this._requiredHandler.bind(this),
      },
      {
        selector: (ctx) => ctx.errorKey === 'gt',
        executer: this._gtHandler.bind(this),
      },
    );
  }

  private _requiredHandler(ctx: HandlerContext): boolean {
    if (ctx.errorKey === 'required') {
      this._toastrService.required(ctx.field);
      return true;
    }
    return false;
  }
  private _codeHandler(ctx: HandlerContext): void {
    const value = ctx.control.value;
    this._toastrService.codeExists(value);
  }
  private _discountHandler(ctx: HandlerContext): void {
    const discount = ctx.control.value.discount;
    this._toastrService.discount(discount, ctx.field);
  }
  private _gtHandler(ctx: HandlerContext): boolean {
    if (ctx.errorKey === 'gt') {
      const value = ctx.control.value;
      const required: number = ctx.control.errors[ctx.errorKey].required;
      this._toastrService.gt(ctx.field, value, required);
      return true;
    }
  }
}
