import { PageMetaFile__Reading__Animation } from 'core/page-meta-file-resolver';
import { Attribute } from './attribute';
import { Letter } from './letter';
import { Tween } from './tween';

export class Animation {
  constructor(
    public letter: Letter,
    public attributes: Attribute[] = [],
    public tween: Tween,
  ) {}

  exec() {
    this.attributes.forEach((attr) => {
      if (attr.type === 'color') this.fill(attr);
    });
  }

  private fill(attr: Attribute) {
    this.letter.fill(attr.value);
  }

  public toFileJson(): PageMetaFile__Reading__Animation {
    const json = {
      id: `${this.letter.id}`,
      attributes: this.attributes.map((attr) => attr.toFileJson()),
    };
    return json;
  }

  public static fromFileJson(
    json: PageMetaFile__Reading__Animation,
    letters: Letter[],
    tween: Tween,
  ): Animation {
    const letter = letters.find(({ id }) => id === json.id);
    const attrs = json.attributes.map((attr) => Attribute.fromFileJson(attr));
    const animation = new Animation(letter, attrs, tween);
    return animation;
  }
}
