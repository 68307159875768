import { Subscription } from 'models/subscription';
import { Properties } from 'utils/properties.type';
import { VoucherDiscount } from './voucher-discount';
import { VoucherRecitalDiscount } from './voucher-recital-discount';

export class Voucher {
  id: number;
  title: string;
  code: string;
  start_date: Date;
  end_date: Date;
  max_number_of_usages: number;
  number_of_usages: number;
  max_number_of_usages_per_user: number;
  description: string;
  is_expired: boolean;
  deactivated: boolean;

  discounts: VoucherDiscount[];
  recitals_discounts: VoucherRecitalDiscount[];
  subscriptions?: Subscription[];

  constructor(spec: Properties<Voucher>) {
    Object.assign(this, spec);
    this.discounts = spec.discounts.map(
      (discount) => new VoucherDiscount(discount),
    );
    this.recitals_discounts = spec.recitals_discounts.map(
      (discount) => new VoucherRecitalDiscount(discount),
    );
    this.subscriptions = spec.subscriptions
      ? spec.subscriptions.map((subscription) => new Subscription(subscription))
      : undefined;
  }
}
