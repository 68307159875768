import { uniqBy } from 'lodash';
import * as Version4 from '../version 4/types';
import * as Version5 from '../version 5/types';

export function toNextVersion(
  from: Version4.PageMetaFile,
): Version5.PageMetaFile {
  return {
    dark_background_svg: from.dark_background_svg,
    light_background_svg: from.light_background_svg,
    lines: from.lines,
    readings: _removeDuplicatePauses(from.readings),
    version: '5',
  };
}

export function isSameVersion(meta: any): boolean {
  return meta.version == 4;
}

function _removeDuplicatePauses(
  readings: Version4.PageMetaFile__Reading[],
): Version5.PageMetaFile__Reading[] {
  return readings.map((reading) => ({
    ...reading,
    pauses: _removeDuplicates(reading.pauses),
  }));
}

function _removeDuplicates(
  pauses: Version4.PageMetaFile__Reading__Pause[],
): Version5.PageMetaFile__Reading__Pause[] {
  return uniqBy(
    pauses,
    (pause) => `${pause.duration}-${pause.start}-${pause.word_id}`,
  );
}
