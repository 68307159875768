import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';

import {
  ApexNonAxisChartSeries,
  ApexPlotOptions,
  ApexChart,
} from 'ng-apexcharts';

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  labels: string[];
  plotOptions: ApexPlotOptions;
};

@Component({
  selector: 'fourqan-radial-bar-chart',
  templateUrl: './RadialBar-chart.Component.html',
  styleUrls: ['./RadialBar-chart.Component.scss'],
})
export class RadialBarComponent implements OnInit, OnChanges {
  @Input() valueList!: number;
  @Input() labelList!: Array<string>;
  @Input() height!: number;
  @Input() colorList!: Array<string>;

  public chartOptions: Partial<ChartOptions>;

  ngOnInit(): void {
    this.chartOptions = {
      series: [this.valueList],
      chart: {
        height: this.height,
        type: 'radialBar',
      },
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['valueList']) {
      this.chartOptions = {
        series: [changes['valueList'].currentValue],
        chart: {
          height: this.height,
          type: 'radialBar',
        },
      };
    }
  }
}
