import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class HttpsRedirectInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (location.protocol === 'https:' && req.url.startsWith('http:')) {
      return next.handle(req.clone({ url: req.url.replace('http', 'https') }));
    }
    return next.handle(req);
  }
}
