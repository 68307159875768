const DOMAIN = {
  prod: 'https://api.qaidahnuraniah.com',
  dev: 'https://dev-api.qaidahnuraniah.com',
  local: 'http://192.168.1.111:4000',
};

export const environment = {
  type: 'dev',
  production: false,
  server: DOMAIN.dev,
  firebase: {
    apiKey: 'AIzaSyA96o5b6knNlXo8Iq-66z8ye4CTEzXMYrs',
    authDomain: 'furqantrainingsystem.firebaseapp.com',
    projectId: 'furqantrainingsystem',
    storageBucket: 'furqantrainingsystem.appspot.com',
    messagingSenderId: '567896605742',
    appId: '1:567896605742:web:14bca5ec4e7d0df4d54bbf',
    measurementId: 'G-ZLCJW8BTGP',
  },
};
