import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Page } from 'models/page';
import { DefaultEditor } from 'ng2-smart-table';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ngx-lesson-pages-edit-cell',
  templateUrl: './lesson-pages-edit-cell.component.html',
  styleUrls: ['./lesson-pages-edit-cell.component.scss'],
})
export class LessonPagesEditCellComponent
  extends DefaultEditor
  implements OnInit, OnDestroy {
  $destroy = new Subject<void>();

  control = new FormControl([]);

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.control.valueChanges
      .pipe(takeUntil(this.$destroy))
      .subscribe((value) => {
        this.selectInBetween();
        this.cell.newValue = this.control.value.map((id) =>
          this.Pages.find((page) => page.id == id),
        );
      });

    const pre_slected_ids = ((this.cell.getValue() as Page[]) || []).map(
      (page) => page.id,
    );
    this.control.patchValue(pre_slected_ids, { emitEvent: true });
  }
  ngOnDestroy(): void {
    this.$destroy.next();
    this.$destroy.complete();
  }

  selectInBetween() {
    const selected_ids: number[] = this.control.value;
    if (selected_ids.length <= 1) return;
    const selected_pages = this.Pages.filter((page) =>
      selected_ids.includes(page.id),
    );

    const first_page = selected_pages[0];
    const last_page = selected_pages[selected_pages.length - 1];

    const in_between = this.Pages.filter(
      (page,index) => index > this.Pages.indexOf(first_page) && index < this.Pages.indexOf(last_page) 
    ).map((page) => page.id);
    this.control.setValue([first_page.id, ...in_between, last_page.id], {
      emitEvent: false,
    });
  }

  get Pages() {
    const pages = this.cell?.getColumn()?.editor?.['pages'] as Page[];
    return pages.sort((a, b) => a.index - b.index);
  }
}
