import { Element } from '@svgdotjs/svg.js';
import { toDartColor } from 'utils';
import { DATA_LINE_ID, Line } from './line';
import { SharedElement } from './shared-element';

export const DATA_LETTER_ID = 'letter-id';

export class Letter {
  public el: Element;
  public id: string;
  public line: Line;

  constructor(el: Element, id: string, line: Line) {
    this.el = el;
    this.id = `letter-${id}`;
    this.line = line;
    this.setup();
  }

  private setup() {
    this.el.addClass(`letter`);
    this.el.id(`${this.id}`);
    this.el.remember(DATA_LINE_ID, `${this.line.id}`);
    this.el.remember(DATA_LETTER_ID, `${this.id}`);

    const bfill = this.el.remember(SharedElement.BaseFill);
    if (!bfill) this.el.remember(SharedElement.BaseFill, this.el.fill());
  }

  public fill(color: string): Letter;
  public fill(base: true): Letter;
  public fill(): string;
  public fill(color?: string | true): string | Letter {
    if (typeof color === 'boolean') {
      color = this.el.remember(SharedElement.BaseFill) as string;
      this.el.fill(color);
      return this;
    } else if (typeof color === 'string') {
      this.el.fill(color);
    } else {
      return this.el.fill() || this.el.remember(SharedElement.BaseFill);
    }
  }

  public stripped(): Element {
    const el = this.el.clone();
    el.id(this.id);
    el.fill(this.el.remember(SharedElement.BaseFill));
    return el;
  }

  public discard() {
    this.el.id(null);
    this.el.removeClass(`letter`);
    this.el.remember(DATA_LETTER_ID, null);
  }

  public toFileJson(): LetterJson {
    const json = {
      id: this.id,
      attributes: [
        {
          type: 'color',
          value: toDartColor(this.el.remember(SharedElement.BaseFill)),
        },
      ],
    };
    return json;
  }
}

export interface LetterJson {
  id: string;
  attributes: {
    type: string;
    value: string;
  }[];
}
