import { Properties } from 'utils/properties.type';
import { VoucherType } from './voucher-type';

export class VoucherDiscount {
  id: number;
  count: number;
  promocode_id: number;
  type: VoucherType;
  discount: number;

  constructor(spec: Properties<VoucherDiscount>) {
    Object.assign(this, spec);
  }
}
