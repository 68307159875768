import { Injectable } from '@angular/core';
import { NbToastrService } from '@nebular/theme';

/* TODO refactor and abstract */

@Injectable()
export class VoucherEditDialogErrorsToasterService {
  constructor(private _toastrService: NbToastrService) {}

  required(field: string): void {
    this.toast(`Field ${field} is required.`);
  }

  codeExists(code: string): void {
    this.toast(`Code ${code} is already used.`);
  }

  discount(value: number, field: string): void {
    this.toast(
      `${field} discount must be between 0 and 100, ${value} is invalid.`,
    );
  }

  gt(field: string, value: number, required: number) {
    this.toast(
      `${field} must be greater than ${required}, ${value} is invalid`,
    );
  }

  toast(message: string): void {
    this._toastrService.warning(message, 'Validation Error');
  }
}
