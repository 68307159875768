import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { ExcelService } from 'app/services/excel.service';
import { VoucherFacade } from 'core/facade/voucher-facade.service';
import { VoucherDTO } from 'core/gateways/voucher/voucher-dto';
import { Voucher } from 'models/vouchers/voucher';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { catchError, filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { VoucherEditDialogComponent } from '../../dialogs/voucher-edit-dialog/voucher-edit-dialog.component';
import { EMPTY_PAGINATION_OBJ, PaginationDto } from 'dtos/pagination.dto';
import { OrderProfitChartSummary } from 'app/@core/data/orders-profit-chart';

@Component({
  selector: 'fourqan-vouchers-container',
  templateUrl: './vouchers-container.component.html',
  styleUrls: ['./vouchers-container.component.scss'],
})
export class VouchersContainerComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  vouchers$ = new BehaviorSubject<Voucher[]>([]);
  private voucherPagination$ = new BehaviorSubject<PaginationDto>(
    EMPTY_PAGINATION_OBJ,
  );
  private destroy$ = new Subject<void>();
  totalCount = new BehaviorSubject<number>(10);
  loading = new BehaviorSubject<boolean>(true);

  chartPanelSummary = new BehaviorSubject<OrderProfitChartSummary[]>([
    {
      title: 'Total Vouchers Count: ',
      value: 0,
    },
  ]);

  // pagination
  title!: string;
  limit: number = 10;
  skip: number = 0;
  startDate!: Date | null;
  endDate!: Date | null;
  code!: string;
  disabled!: 0 | 1 | null;

  constructor(
    private _voucherService: VoucherFacade,
    private _nb_dialog: NbDialogService,
    private _detectorRef: ChangeDetectorRef,
    private excelService: ExcelService,
  ) {}

  ngOnInit(): void {
    this.fetchVouchers();
  }

  ngAfterViewInit(): void {
    this._detectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  downloadExcel(): void {
    this.excelService.getAllVoucher();
  }

  addNew(): void {
    this._nb_dialog
      .open(VoucherEditDialogComponent, { context: {} })
      .onClose.pipe(filter((result) => result?.refresh))
      .subscribe(() => {
        this.updatePagination();
      });
  }

  edit(voucher: Voucher): void {
    this._nb_dialog
      .open(VoucherEditDialogComponent, { context: { voucher } })
      .onClose.pipe(filter((result) => result?.refresh))
      .subscribe(() => {
        this.updatePagination();
      });
  }

  _add(body: VoucherDTO.CreateBody): void {
    this._voucherService
      .create(body)
      .subscribe((vouchers) => this.vouchers$.next(vouchers));
    return;
  }

  _edit(voucher_id: number, body: VoucherDTO.UpdateBody): void {
    this._voucherService
      .edit(voucher_id, body)
      .subscribe((vouchers) => this.vouchers$.next(vouchers));
    return;
  }

  private fetchVouchers(): void {
    this.voucherPagination$
      .pipe(
        switchMap((pagination: PaginationDto) => {
          return this._voucherService.list(pagination).pipe(
            tap((voucherList) => {
              this.loading.next(false);
              this.vouchers$.next(voucherList.data);
              this.totalCount.next(voucherList.count);
              this.chartPanelSummary.next([
                {
                  title: 'Total Vouchers Count: ',
                  value: voucherList.count,
                },
              ]);
            }),
            catchError(() => {
              this.loading.next(false);
              return of();
            }),
            takeUntil(this.destroy$),
          );
        }),
      )
      .subscribe();
  }

  public changePage(event: { limit: number; skip: number }): void {
    this.limit = event.limit;
    this.skip = event.skip;
    this.updatePagination();
  }

  public searchForTitle(title: string | null): void {
    this.title = title;
    this.updatePagination();
  }

  public searchForCode(code: string | null): void {
    this.code = code;
    this.updatePagination();
  }

  public searchForStatus(status: 0 | 1 | null): void {
    this.disabled = status;
    this.updatePagination();
  }

  public updatePagination(): void {
    this.loading.next(true);
    const pagination: PaginationDto = {
      limit: this.limit,
      skip: this.skip,
      from: this.startDate,
      to: this.endDate,
      title: this.title,
      code: this.code,
      disabled: this.disabled,
    };
    this.voucherPagination$.next(pagination);
  }
}
