<div class="row">
  <div class="w-100 d-flex gap-2rem">
    <ngx-user-profile-form
      class="flex-1"
      (onActivateUser)="onActivateUser()"
      (onDeactivateUser)="onDeactivateUser()"
      (onDeleteUser)="onDeleteUser()"
      (onEditUser)="onEditUser($event)"
      (onReloadUser)="onReloadUser()"
      [user]="user_entity.Data"
      [loading]="user_entity.IsLoading"
    >
    </ngx-user-profile-form>

    <ngx-teacher-listing
      class="flex-1"
      [teachers]="teachers"
      *ngIf="user_entity.Data?.role === $Roles.User"
    ></ngx-teacher-listing>
  </div>

  <ng-container *ngIf="user_entity.HasData">
    <div class="d-flex flex-column w-100">
      <ngx-user-students-listing
        class="w-100"
        *ngIf="user_entity.Data?.role === $Roles.Teacher"
        [students]="students_entity.Data"
        [loading]="students_entity.IsLoading"
        (onAssignStudent)="onAssignStudent($event)"
        (onDeleteStudent)="onDischargeStudent($event)"
      >
      </ngx-user-students-listing>

      <dev class="d-flex gap-1rem align-items-start">
        <ngx-user-subscription-listing
          class="flex-3"
          [subscription]="user_subscriptions"
          (onAddSubscription)="onAddUserSubscription()"
        >
        </ngx-user-subscription-listing>

        <fourqan-content-card
          *ngIf="user_subscriptions?.length > 0"
          [cardTitle]="'Subscription Left'"
          class="flex-1"
          style="margin-bottom: 1rem"
        >
          <ng-container class="card-content">
            <div
              class="d-flex gap-1rem align-items-center justify-content-center w-100"
            >
              <i
                class="pi pi-angle-left"
                (click)="previousSub()"
                [ngClass]="{ disabled: this.subscriptionIndex === 0 }"
              ></i>
              <span>Subscription #{{ subscriptionIndex + 1 }}</span>
              <i
                class="pi pi-angle-right"
                (click)="nextSub()"
                [ngClass]="{
                  disabled:
                    this.subscriptionIndex ===
                    this.user_subscriptions?.length - 1
                }"
              ></i>
            </div>
            <fourqan-radial-bar-chart
              [valueList]="this.subscriptionPercentage"
              [labelList]="[
                this.user_subscriptions[subscriptionIndex]?.subscription
                  ?.name ?? 'Hafs'
              ]"
              [colorList]="['var(--primary)']"
              [height]="300"
            >
            </fourqan-radial-bar-chart>
          </ng-container>
        </fourqan-content-card>
      </dev>
    </div>

    <div class="w-100">
      <ngx-user-tokens-listing [tokens]="user_tokens | async">
      </ngx-user-tokens-listing>
    </div>
  </ng-container>
</div>
