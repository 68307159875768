import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  NbAlertModule,
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbIconModule,
  NbInputModule,
  NbLayoutModule,
} from '@nebular/theme';
import { TranslateModule } from '@ngx-translate/core';
import { NgxAuthComponentsModule } from '../components/auth.components.module';
import { LoginContainerComponent } from './login-container/login-container.component';

@NgModule({
  imports: [
    CommonModule,
    NbLayoutModule,
    NbCardModule,
    NbCheckboxModule,
    NbAlertModule,
    NbInputModule,
    NbButtonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NbIconModule,
    NgxAuthComponentsModule,
    TranslateModule,
  ],
  declarations: [LoginContainerComponent],
})
export class NgxAuthContainersModule {}
