import { flatten } from 'lodash';
import { ReadingType } from 'models/reading';
import * as Version4 from '../version 4/types';
import * as Version3 from './types';

export function toNextVersion(
  from: Version3.PageMetaFile,
): Version4.PageMetaFile {
  return {
    dark_background_svg: from.dark_background_svg,
    light_background_svg: from.light_background_svg,
    lines: from.lines,
    readings: fromReadings(from.readings, wordsFromLines(from.lines)),
    version: '4',
  };
}

export function isSameVersion(meta: any): boolean {
  return meta.version == 3;
}

function fromReadings(
  from: Version3.PageMetaFile__Reading[],
  words: Version3.PageMetaFile__Line__Word[],
): Version4.PageMetaFile__Reading[] {
  return from.map((reading) => ({
    ...reading,
    pauses: fromPauses(reading.pauses, words, reading.type),
  }));
}
function fromPauses(
  pauses: Version3.PageMetaFile__Reading__Pause[],
  words: Version3.PageMetaFile__Line__Word[],
  type: ReadingType,
): Version4.PageMetaFile__Reading__Pause[] {
  return pauses.map((pause) => {
    const word = wordForPause(pause, words, type);
    return {
      ...pause,
      word_id: word.id,
    };
  });
}

function wordsFromLines(
  lines: Version3.PageMetaFile__Line[],
): Version3.PageMetaFile__Line__Word[] {
  return flatten(lines.map((line) => line.words));
}

/**
 *
 * @param pause
 * @param words all reading pauses
 * @returns the word that's time is the nearest to the pause time or the first word
 */
function wordForPause(
  pause: Version3.PageMetaFile__Reading__Pause,
  words: Version3.PageMetaFile__Line__Word[],
  type: ReadingType,
): Version3.PageMetaFile__Line__Word {
  const time = pause.start;

  for (let index = 0; index < words.length; index++) {
    const pre_word = words[index - 1];
    const curr_word = words[index];

    const pre = pre_word?.duration[type];
    const curr = curr_word.duration[type];

    if (!pre || !curr) continue;

    const is_during_current = time >= curr.start && time <= curr.end;
    const is_between_current_and_pre =
      pre != null ? time >= pre.end && time <= curr.start : false;

    if (is_during_current) {
      return curr_word;
    } else if (is_between_current_and_pre) {
      const distance_to_pre_end = time - pre.end;
      const distance_to_current_start = curr.start - time;
      return distance_to_current_start <= distance_to_pre_end
        ? curr_word
        : pre_word;
    } else {
      continue;
    }
  }

  // If we could not find any word add the pause to the first one
  return words[0];
}
