import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'fourqan-content-card',
  templateUrl: './content-card.component.html',
  styleUrls: ['./content-card.component.scss'],
})
export class ContentCardComponent {
  @Input() cardTitle: string | null = null;
  @Input() hasShadow: boolean = false;
  @Input() noPaddingBottom: boolean = false;
  @Input() loadingError: boolean = false;
  @Input() topExtension: boolean = false;
  @Input() bottomCard: boolean = false;

  @Output() reloadEvent = new EventEmitter();

  public reload(): void {
    this.reloadEvent.emit();
  }
}
