import { Component, forwardRef, OnInit } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validator,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'ngx-email-password-login-form',
  templateUrl: './email-password-login-form.component.html',
  styleUrls: ['./email-password-login-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EmailPasswordLoginFormComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => EmailPasswordLoginFormComponent),
      multi: true,
    },
  ],
})
export class EmailPasswordLoginFormComponent
  implements OnInit, ControlValueAccessor, Validator {
  group_control = new FormGroup({
    email: new FormControl(null, [
      Validators.required,
      Validators.pattern(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/),
    ]),
    password: new FormControl(null, [Validators.required]),
    remember: new FormControl(false),
  });

  ngOnInit(): void {}

  registerOnChange(fn: () => void) {
    return this.group_control.valueChanges.subscribe(fn);
  }
  registerOnTouched() {}
  setDisabledState() {}
  writeValue(value: EmailPasswordFormValue) {
    if (!value) this.group_control.reset();
    else if (value.email || value.password || value.remember)
      this.group_control.patchValue(value);
    else this.group_control.reset();
  }
  registerOnValidatorChange(fn) {}
  validate(control: AbstractControl) {
    const errors = {};
    if (this.EmailControl.errors) errors['email'] = this.EmailControl.errors;
    if (this.PasswordControl.errors)
      errors['password'] = this.PasswordControl.errors;
    return errors;
  }

  public get EmailControl(): FormControl {
    return this.group_control.get('email') as FormControl;
  }
  public get PasswordControl(): FormControl {
    return this.group_control.get('password') as FormControl;
  }
}

export interface EmailPasswordFormValue {
  email: string;
  password: string;
  remember?: boolean;
}

export interface EmailPasswordFormError {
  email: {
    required: boolean;
    pattern: any;
  };
  password: {
    required: boolean;
  };
}
