import { Box, Element, SVG } from '@svgdotjs/svg.js';
import { invertColor } from 'constants/color-invert-map';
import {
  PageMetaFile__Line__Word,
  PageMetaFile__Reading__Pause,
} from 'core/page-meta-file-resolver';
import { disassemble } from 'utils';
import { DATA_LETTER_ID, Letter, LetterJson } from './letter';
import { Page } from './page';
import { ReadingType } from './reading';
import { Word } from './word';

export const DATA_LINE_ID = 'line-id';
export class Line {
  /* TOFIX */
  public top_bound;
  public bottom_bound;

  public els: Element[] = [];
  public letters: Letter[] = [];
  public words: Word[] = [];
  public duration: Partial<
    Record<ReadingType, { start: number; end: number; auto?: boolean }>
  >;
  private breaks?: Partial<Record<Breaks, boolean>>;
  public page: Page;

  // private _rerender_source$ = new Subject<void>();

  constructor(public id: string) {
    this.initDurations();
  }

  initDurations() {
    this.duration = {};
    const types = Object.values(ReadingType);
    types.forEach((type) => {
      this.duration[type] = { start: 0, end: 0, auto: true };
    });
  }

  higlight() {
    this.letters.map((letter) => letter.fill('blue'));
  }
  stabilize() {
    this.letters.map((letter) => letter.fill(true));
  }

  index(): number {
    return this.page.indexOf(this);
  }

  addLetter(item: Element | Letter): Letter;
  addLetter(items: Element[] | Letter[]): Letter[];
  addLetter(items: Letter | Element | Letter[] | Element[]): Letter | Letter[] {
    if (items instanceof Element) {
      const letter = new Letter(
        items,
        `${this.letters.length}-line-${this.id}`,
        this,
      );
      this.letters.push(letter);
      this.els.push(items);
      return letter;
    } else if (items instanceof Letter) {
      const el = items.el;
      items.discard();
      const letter = new Letter(
        el,
        `${this.letters.length}-line-${this.id}`,
        this,
      );
      this.letters.push(letter);
      this.els.push(letter.el);
      return letter;
    } else if (Array.isArray(items)) {
      return (items as any).map((item) => this.addLetter(item));
    }
  }

  discard(): boolean {
    this.letters.forEach((letter) => {
      letter.discard();
    });
    return true;
  }
  disassembleWord(word_id: string) {
    const index = this.words.findIndex((word) => word.id == word_id);
    if (index < 0) return;
    this.words[index].disassemble();
    this.words.splice(index, 1);
  }

  addWord(items: Element[] | Letter[] | string[]): Word {
    if (items.length == 0) return null;
    else if (items[0] instanceof Letter) {
      const letters = items as Letter[];
      const word = new Word(
        `word-${this.id}-${this.words.length}`,
        letters,
        this,
      );
      this.words.push(word);
      return word;
    } else if (items[0] instanceof Element) {
      const els = items as Element[];
      const ids = els.map((el) => el.remember(DATA_LETTER_ID));
      const letters = this.find(ids, 'letters');
      return this.addWord(ids);
    } else if (typeof items[0] === 'string') {
      const ids = items as string[];
      const letters = this.find(ids, 'letters');
      return this.addWord(letters);
    }
  }

  remove(items: Letter | string | Letter[] | string[]): this {
    if (items instanceof Letter) {
      const letter_index = this.letters.findIndex(
        (letter) => letter.id == items.id,
      );
      const el_index = this.els.findIndex(
        (el) => el.id() == this.letters[letter_index].el.id(),
      );
      this.letters[letter_index].discard();
      this.letters.splice(letter_index, 1);
      this.els.splice(el_index, 1);
    } else if (typeof items === 'string') {
      const letter_index = this.letters.findIndex(
        (letter) => letter.id == items,
      );
      const el_index = this.els.findIndex(
        (el) => el.id() == this.letters[letter_index].el.id(),
      );
      this.letters[letter_index].discard();
      this.letters.splice(letter_index, 1);
      this.els.splice(el_index, 1);
    } else if (Array.isArray(items)) {
      items.forEach((item) => this.remove(item));
    }
    return this;
  }

  has(els: Element[] | Element | Letter[] | Letter): boolean {
    if (Array.isArray(els) && els.length == 0) {
      return false;
    } else if (Array.isArray(els) && els[0] instanceof Letter) {
      return (els as Letter[]).reduce(
        (pre, curr) => pre && this.letters.includes(curr),
        true,
      );
    } else if (Array.isArray(els) && els[0] instanceof Element) {
      const ids = this.letters.map((letter) => letter.id);
      return (els as Element[])
        .map((el) => el.remember(DATA_LETTER_ID))
        .reduce((pre, curr) => pre && ids.includes(curr));
    } else if (els instanceof Letter) {
      return this.letters.includes(els);
    } else if (els instanceof Element) {
      return this.letters
        .map((letter) => letter.id)
        .includes(els.remember(DATA_LETTER_ID));
    }
  }

  find(letters_ids: string[], as: 'letters'): Letter[];
  find(words_ids: string[], as: 'words'): Word[];
  find(ids: string[], as: 'letters' | 'words'): Letter[] | Word[] {
    if (as === 'letters') {
      return this.letters.filter((letter) => ids.includes(letter.id));
    } else if (as === 'words') {
      return this.words.filter((letter) => ids.includes(letter.id));
    }
  }

  relativeCenter(): { cy: number; cx: number } {
    const count = this.els.length;
    const cysum = this.els.reduce((pre, curr) => pre + curr.cy(), 0);
    const cxsum = this.els.reduce((pre, curr) => pre + curr.cx(), 0);
    return {
      cy: cysum / count,
      cx: cxsum / count,
    };
  }

  bbox() {
    const bbox = this.letters.reduce<Box>((pre, curr) => {
      return pre == null ? curr.el.bbox() : curr.el.bbox().merge(pre);
    }, null);
    return { x: bbox.x, x2: bbox.x2, y: this.top_bound, y2: this.bottom_bound };
  }

  setBreaks(breaks: Partial<Record<Breaks, boolean>>) {
    if (!this.breaks) this.breaks = {};
    Object.assign(this.breaks, breaks);
  }
  getBreaks() {
    if (!this.breaks) return {};
    else
      return {
        ...this.breaks,
      };
  }

  // rerender$() {
  //   return this._rerender_source$.asObservable();
  // }

  /**
   * @description returns postion relative to the page svg in from [0-100]
   */
  relativeBoundingBox(): { sx: number; ex: number; sy: number; ey: number } {
    const bbox = this.bbox();
    const svg_bbox = this.page.Viewbox;
    return {
      ex: (1 - bbox.x / svg_bbox.x2) * 100,
      ey: (bbox.y2 / svg_bbox.y2) * 100,
      sx: (1 - bbox.x2 / svg_bbox.x2) * 100,
      sy: (bbox.y / svg_bbox.y2) * 100,
    };
  }

  private svg(): string {
    const els_clone = this.letters.map((letter) => letter.stripped());
    const viewbox = this.page.Viewbox;
    const wrap = SVG().group();
    const h = this.bottom_bound - this.top_bound;
    wrap.height(h);
    wrap.cy((this.top_bound + this.bottom_bound) / 2);

    els_clone.forEach((el) => wrap.add(el));
    const container = SVG().viewbox({
      x: viewbox.x,
      y: viewbox.y,
      width: viewbox.width,
      height: h,
    });
    container.add(wrap);
    const tcy = +(h / 2);
    wrap.cy(tcy);
    return container.node.outerHTML;
  }
  private svgDark(): string {
    const els_clone = this.letters.map((letter) => {
      const el = letter.stripped();
      const { a, r, g, b } = disassemble(el.fill());
      const color = `#${r}${g}${b}`;
      const alt = invertColor(`${color.toLowerCase()}`);
      el.fill(alt || color);
      return el;
    });
    const viewbox = this.page.Viewbox;
    const wrap = SVG().group();
    const h = this.bottom_bound - this.top_bound;
    wrap.height(h);
    wrap.cy((this.top_bound + this.bottom_bound) / 2);

    els_clone.forEach((el) => wrap.add(el));
    const container = SVG().viewbox({
      x: viewbox.x,
      y: viewbox.y,
      width: viewbox.width,
      height: h,
    });
    container.add(wrap);
    const tcy = +(h / 2);
    wrap.cy(tcy);
    return container.node.outerHTML;
  }

  toFileJson(): LineJson {
    const json = {
      id: this.id,
      svg: this.svg(),
      svg_dark: this.svgDark(),
      words: this.words.map((word) => word.toFileJson()),
      letters: this.letters.map((letter) => letter.toFileJson()),
      position: this.relativeBoundingBox(),
      duration: {
        [ReadingType.NormalReadingWithOutSpelling]: {
          start: +(
            this.duration['normal-reading-without-spelling'].start * 1000000
          ).toFixed(0),
          end: +(
            this.duration['normal-reading-without-spelling'].end * 1000000
          ).toFixed(0),
        },
        [ReadingType.NormalReadingWithSpelling]: {
          start: +(
            this.duration['normal-reading-with-spelling'].start * 1000000
          ).toFixed(0),
          end: +(
            this.duration['normal-reading-with-spelling'].end * 1000000
          ).toFixed(0),
        },
      },
      breaks: this.breaks,
    };
    return json;
  }

  public static fromFileJson(
    json: LineJson,
    pauses: Record<ReadingType, PageMetaFile__Reading__Pause[]>,
  ): Line {
    const line = new Line(json.id);
    line.duration = {
      'normal-reading-with-spelling': {
        start: json.duration['normal-reading-with-spelling'].start / 1000000,
        end: json.duration['normal-reading-with-spelling'].end / 1000000,
      },
      'normal-reading-without-spelling': {
        start: json.duration['normal-reading-without-spelling'].start / 1000000,
        end: json.duration['normal-reading-without-spelling'].end / 1000000,
      },
    };
    json.letters.forEach((letter) => {
      const el = SVG(`#${letter.id}`);
      line.addLetter(el);
    });
    json.words.forEach((word_json) => {
      const ids = word_json.letters.map(({ id }) => id);
      const word = line.addWord(ids);
      word.loadFromFileJson(word_json, pauses);
    });
    line.breaks = json.breaks;
    return line;
  }
}

export interface LineJson {
  id: string;
  svg: string;
  svg_dark: string;
  duration: Partial<
    Record<
      ReadingType,
      {
        start: number;
        end: number;
      }
    >
  >;
  position: {
    sx: number;
    sy: number;
    ex: number;
    ey: number;
  };
  letters: LetterJson[];
  words: PageMetaFile__Line__Word[];
  breaks: Partial<Record<Breaks, boolean>>;
}

type Breaks = 'left' | 'right';
