import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { Profile } from 'app/services/profile.service';
import { SubscriptionsService } from 'app/services/subscriptions.service';
import { UserSubscriptionsService } from 'app/services/user-subscriptions.service';
import { UsersService } from 'app/services/users.service';
import { Entity } from 'models/entity';
import { Subscription } from 'models/subscription';
import { User } from 'models/user';
import { UserRoles } from 'models/user-role';
import { UserSubscription } from 'models/user-subscription';
import { UserToken } from 'models/userToken';
import { BehaviorSubject } from 'rxjs';
import { AddUserSubscriptionDialogComponent } from '../../components/add-user-subscription-dialog/add-user-subscription-dialog.component';
import { AddUserSubscriptionDialogResult } from '../../components/add-user-subscription-dialog/add-user-subscription-dialog.types';
import { EditUserForm } from '../../components/user-profile-form/user-profile-form.component';
import * as moment from 'moment';

@Component({
  selector: 'ngx-user-profile-container',
  templateUrl: './user-profile-container.component.html',
  styleUrls: ['./user-profile-container.component.scss'],
})
export class UserProfileContainerComponent implements OnInit {
  public id: number;
  public mine: boolean = false;
  public user_entity: Entity<User>;
  public teachers: User[];
  public students_entity: Entity<User[]>;
  public user_subscriptions: UserSubscription[] = [];
  subscriptionIndex: number = 0;
  subscriptionPercentage: number = 0;

  user_tokens = new BehaviorSubject<UserToken[]>([]);

  public available_subscription: Subscription[] = [];

  constructor(
    private users_service: UsersService,
    private profile_service: Profile,
    private route: ActivatedRoute,
    private user_subscriptions_service: UserSubscriptionsService,
    private subscriptions_service: SubscriptionsService,
    private dialog_service: NbDialogService,
    private _toastrService: NbToastrService,
  ) {}

  ngOnInit(): void {
    this.user_entity = new Entity();
    this.students_entity = new Entity();
    this.mine = this.route.snapshot.data.mine || false;
    if (!this.mine) {
      this.id = +this.route.snapshot.params.id;
    }
    this.fetchUser().then(() => this.fetchRest());
    this.fetchAvailableSubscription();
  }

  async fetchTeachers() {
    const teachers = await this.users_service.teachers(this.id).toPromise();
    this.teachers = teachers;
  }
  async fetchStudents() {
    this.students_entity.wait();
    const students = await this.users_service.students(this.id).toPromise();
    this.students_entity.load(students);
  }
  async fetchSubscriptions() {
    const subscriptions = await this.user_subscriptions_service
      .ofUser(this.id)
      .toPromise();
    this.user_subscriptions = subscriptions;
    this.calculateProgress(
      this.user_subscriptions[this.subscriptionIndex]?.created_at,
      this.user_subscriptions[this.subscriptionIndex]?.expiry_date,
    );
  }
  async fetchUser() {
    this.user_entity.wait();
    let user_promise: Promise<User> = null;
    if (this.mine) user_promise = this.profile_service.Me(true);
    else user_promise = this.users_service.one(this.id).toPromise();
    const user = await user_promise;
    this.user_tokens.next(user.tokens);
    this.user_entity.load(user);
  }
  async fetchRest() {
    this.fetchSubscriptions();
    if (this.user_entity.Data.role === UserRoles.User) {
      this.fetchTeachers();
      this.fetchSubscriptions();
    } else if (this.user_entity.Data.role === UserRoles.Teacher) {
      this.fetchStudents();
    }
  }
  async editUser(user: Partial<User>) {
    this.user_entity.wait();
    if (this.mine) {
      this.users_service.updateMe(user).subscribe({
        next: (updated) => {
          this.user_entity.load(updated);
        },
        error: (error: HttpErrorResponse) => {
          this.user_entity.load(this.user_entity.Data);
          this._toastrService.danger(error.error.message, error.statusText);
        },
      });
    } else {
      this.users_service.update(this.id, user).subscribe({
        next: (updated) => {
          this.user_entity.load(updated);
        },
        error: (error: HttpErrorResponse) => {
          this._toastrService.danger(error.error.message, error.statusText);
          this.user_entity.load(this.user_entity.Data);
        },
      });
    }
  }
  async assignStudent(student_id: number, teacher_id: number) {
    return this.users_service
      .assignStudentToTeacher(student_id, teacher_id)
      .toPromise();
  }
  async dischargeStudent(student_id: number, teacher_id: number) {
    return this.users_service
      .dischargeTeacherOfStudent(student_id, teacher_id)
      .toPromise();
  }
  async fetchAvailableSubscription() {
    return this.subscriptions_service
      .list()
      .toPromise()
      .then((subscriptions) => {
        this.available_subscription = subscriptions;
      });
  }
  async onAddUserSubscription() {
    /* TOFIX make sure subscriptions are loaded before opening the dialog */
    const ref = this.dialog_service.open(
      AddUserSubscriptionDialogComponent,
      {},
    );
    ref.componentRef.instance.options = this.available_subscription;
    const result =
      await ref.onClose.toPromise<AddUserSubscriptionDialogResult>();
    if (!result || result.canceled == true) return;
    const plane_id = result.selected.id;
    this.user_subscriptions_service
      .gift(this.id, plane_id, result.recitals)
      .subscribe(() => {
        this.fetchSubscriptions();
      });
  }

  async onDeleteUser() {}
  async onEditUser($event: EditUserForm) {
    if (Object.keys($event).length == 0) this.fetchUser();
    else this.editUser($event);
  }
  async onReloadUser() {
    this.fetchUser();
  }
  async onDischargeStudent(student: User) {
    this.students_entity.wait();
    this.dischargeStudent(student.id, this.id).then(() => this.fetchStudents());
  }
  onActivateUser() {
    this.user_entity.wait();
    this.users_service.activate(this.user_entity.Data.id).subscribe({
      next: (updated) => {
        this.user_entity.load(updated);
      },
      error: (error: HttpErrorResponse) => {
        this._toastrService.danger(error.error.message, error.statusText);
        this.user_entity.load(this.user_entity.Data);
      },
    });
  }
  onDeactivateUser() {
    this.user_entity.wait();
    this.users_service.deactivate(this.user_entity.Data.id).subscribe({
      next: (updated) => {
        this.user_entity.load(updated);
      },
      error: (error: HttpErrorResponse) => {
        this._toastrService.danger(error.error.message, error.statusText);
        this.user_entity.load(this.user_entity.Data);
      },
    });
  }

  async onAssignStudent(student: User) {
    this.students_entity.wait();
    this.assignStudent(student.id, this.id).then(() => this.fetchStudents());
  }

  public get $Roles(): typeof UserRoles {
    return UserRoles;
  }

  public previousSub(): void {
    if (this.subscriptionIndex !== 0) {
      this.subscriptionIndex--;
      this.calculateProgress(
        this.user_subscriptions[this.subscriptionIndex]?.created_at,
        this.user_subscriptions[this.subscriptionIndex]?.expiry_date,
      );
    }
  }

  public nextSub(): void {
    if (this.subscriptionIndex !== this.user_subscriptions?.length - 1) {
      this.subscriptionIndex++;
      this.calculateProgress(
        this.user_subscriptions[this.subscriptionIndex]?.created_at,
        this.user_subscriptions[this.subscriptionIndex]?.expiry_date,
      );
    }
  }

  private calculateProgress(startDate: Date, endDate: Date): void {
    const start = moment(startDate);
    const end = moment(endDate);
    const current = moment(new Date());

    const progress =
      (current.diff(start, 'days') / end.diff(start, 'days')) * 100;
    this.subscriptionPercentage = +Math.min(Math.max(progress, 0), 100).toFixed(
      1,
    );
  }
}
