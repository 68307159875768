import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Profile } from 'app/services/profile.service';
import { Observable } from 'rxjs';

@Injectable()
export class TokenizerInterceptor implements HttpInterceptor {
  constructor(private profile: Profile) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const tokenized = req.clone({
      headers: req.headers.set(
        'Authorization',
        `Bearer ${this.profile.getPayload().access_token}`,
      ),
    });
    return next.handle(tokenized);
  }
}
