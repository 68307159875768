import { Injectable } from '@angular/core';
import { VoucherGateway } from 'core/gateways/voucher/voucher-gateway.service';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { SubscriptionsService } from './subscriptions.service';
import { UsersService } from './users.service';
@Injectable({
  providedIn: 'root',
})
export class ExcelService {
  constructor(
    private usersService: UsersService,
    private voucherService: VoucherGateway,
    private subscriptions_service: SubscriptionsService,
  ) {}
  generateExcel(userList, reportName: string) {
    const title = `${reportName} Data`;

    const header = Object.keys(userList[0]);

    const workbook = new Workbook();

    const worksheet = workbook.addWorksheet(`${reportName} Report`);

    // Add new row

    const titleRow = worksheet.addRow([title]);

    // Set font family, font size, and style in title row.

    titleRow.font = { name: 'Saysettha OT', family: 4, size: 16, bold: true };

    // Blank Row

    worksheet.addRow([]);

    // Add Header Row

    const headerRow = worksheet.addRow(header);

    // Cell Style : Fill and Border

    headerRow.eachCell((cell) => {
      cell.font = { name: 'Saysettha OT', bold: true };
    });

    // Add Data and Conditional Formatting

    userList.forEach((d) => {
      const row = worksheet.addRow(Object.values(d));

      row.font = { name: 'Saysettha OT' };
    });

    workbook.xlsx.writeBuffer().then((excelData) => {
      const blob = new Blob([excelData], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      fs.saveAs(blob, `${reportName}Report.xlsx`);
    });
  }
  getAllUsers() {
    this.usersService.list().subscribe((users) => {
      /*
       *
       ** setting up the users for excel
       *
       */
      const usersForExcel = users.data.map((user) => ({
        id: user.id,
        usernamel: user.username,
        gender: user.gender,
        email: user.email,
        is_active: user.is_active,
        role: user.role,
        country: user.country.name || ' ',
        countryId: user.countryId,
        phonenumber: user.phonenumber,
      }));
      this.generateExcel(usersForExcel, 'users');
    });
  }
  getAllVoucher() {
    this.voucherService.list().subscribe((vouchers) => {
      /*
       *
       ** setting up the vouchers for excel
       *
       */
      const vouchersForExcel = vouchers.data.map((voucher) => ({
        id: voucher.id,
        code: voucher.code,
        description: voucher.description,
        start_date: voucher.start_date,
        end_date: voucher.end_date,
        is_expired: voucher.is_expired,
        max_number_of_usages: voucher.max_number_of_usages,
        number_of_usages: voucher.number_of_usages,
        max_number_of_usages_per_user: voucher.max_number_of_usages_per_user,
        title: voucher.title,
        subscriptions: voucher.subscriptions,
        two_recitals_discount: voucher.discounts[0].discount,
        two_recitals_type: voucher.discounts[0].type,
        three_recitals_discount: voucher.discounts[1].discount,
        three_recitals_type: voucher.discounts[1].type,
        qanoun_discount: voucher.recitals_discounts[0].discount,
        hafs_discount: voucher.recitals_discounts[1].discount,
        warsh_discount: voucher.recitals_discounts[2].discount,
        deactivated: voucher.deactivated,
      }));
      this.generateExcel(vouchersForExcel, 'Vouchers');
    });
  }
  generateSubscriptionExcel() {
    this.subscriptions_service.list().subscribe((subscriptions) => {
      /*
       *
       ** setting up the subscriptions for excel
       *
       */
      const subscriptionsForExcel = subscriptions.map((subscription) => ({
        id: subscription.id,
        discount_type: subscription.discount.type,
        discount_value: subscription.discount.value,

        duration_year: subscription.duration.years,
        duration_months: subscription.duration.months,
        duration_days: subscription.duration.days,
        duration_hours: subscription.duration.hours,
        duration_minutes: subscription.duration.minutes,

        allowanceTime: subscription.allowanceTime,
        allowanceTime_year: subscription.allowanceTime.years,
        allowanceTime_months: subscription.allowanceTime.months,
        allowanceTime_days: subscription.allowanceTime.days,
        allowanceTime_hours: subscription.allowanceTime.hours,
        allowanceTime_minutes: subscription.allowanceTime.minutes,

        description_arabic: subscription.descriptionAr,
        description_english: subscription.descriptionEn,
        description_france: subscription.descriptionFr,
        max_active_tokens: subscription.max_active_tokens,
        login_times: subscription.login_times,
        name: subscription.name,
        english_plan_name: subscription.nameEn,
        france_plan_name: subscription.nameFr,
        arabic_plan_name: subscription.nameAr,
        //pricing
        hafs_price: subscription.pricings[0].price,
        warsh_price: subscription.pricings[1].price,
        qanoun_price: subscription.pricings[2].price,
        ////to do pricing discount
        pricing_discount: subscription.pricings_discounts,
        two_recitals_discount: subscription.pricings_discounts[0].discount,
        two_recitals_type: subscription.pricings_discounts[0].type,
        three_recitals_discount: subscription.pricings_discounts[1].discount,
        three_recitals_type: subscription.pricings_discounts[1].type,
        deactivated: subscription.deactivated,
      }));
      this.generateExcel(subscriptionsForExcel, 'subscriptions');
    });
  }
}
