import { Page, PageDTO } from './page';

export class Lesson {
  constructor(
    public id: number,
    public nameEn: string,
    public nameAr: string,
    public nameFr: string,
    public is_published: boolean,
    public index: number,
    public created_at: Date,
    public updated_at: Date,
    public is_free: boolean,
    public pages: Page[] = [],
  ) {}

  public static fromDTO(dtos: LessonDTO[]): Lesson[];
  public static fromDTO(dto: LessonDTO): Lesson;
  public static fromDTO(
    dtos_or_dto: LessonDTO[] | LessonDTO,
  ): Lesson[] | Lesson {
    return Array.isArray(dtos_or_dto)
      ? dtos_or_dto.map((dto) => Lesson.fromDTO(dto))
      : new Lesson(
          dtos_or_dto.id,
          dtos_or_dto.nameEn,
          dtos_or_dto.nameAr,
          dtos_or_dto.nameFr,
          dtos_or_dto.isPublished,
          dtos_or_dto.index,
          new Date(dtos_or_dto.createdAt),
          new Date(dtos_or_dto.updatedAt),
          dtos_or_dto.isFree,
          dtos_or_dto.pages ? Page.fromArrayDTO(dtos_or_dto.pages) : [],
        );
  }
}

export interface LessonDTO {
  id: number;
  nameEn: string;
  nameFr: string;
  nameAr: string;
  isPublished: boolean;
  index: number;
  createdAt: string;
  updatedAt: string;
  pages: PageDTO[];
  isFree: boolean;
}
