import { Ad } from 'models/ads/ad';
import { AdMedia } from 'models/ads/ad-media';
import { Properties } from 'utils/properties.type';
import { AdsDTO } from './ads-dto';

export namespace AdsAdapter {
  export function ad(dto: AdsDTO.Base): Properties<Ad> {
    return {
      id: dto.id,
      activeAfter: new Date(dto.activeAfter),
      closeAtEnd: dto.closeAtEnd,
      closeButton: dto.closeButton,
      coolDownTime: dto.coolDownTime,
      deactivated: dto.deactivated,
      inactiveAfter: new Date(dto.inactiveAfter),
      name: dto.name,
      showAtStart: dto.showAtStart,
      timeOnScreen: dto.timeOnScreen,
      type: dto.type,
      backgroundColor: dto.backgroundColor,
      media: dto.media ? adMedia(dto.media) : undefined,
      text: dto.text,
      title: dto.title,
    };
  }
  export function ads(dtos: AdsDTO.Base[]): Properties<Ad[]> {
    return dtos.map((dto) => ad(dto));
  }

  export function adMedia(dto: AdsDTO.AdMediaBase): Properties<AdMedia> {
    return {
      id: dto.id,
      type: dto.type,
      url: dto.url,
    };
  }
}
