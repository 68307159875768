import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TableModule } from 'primeng/table';
import { CouponPrimeTableComponent } from './coupon-prime-table.component';
import { FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';

@NgModule({
  declarations: [CouponPrimeTableComponent],
  imports: [TableModule, CommonModule, FormsModule, InputTextModule],
  exports: [CouponPrimeTableComponent],
})
export class CouponPrimeTableModule {}
