import { Box } from '@svgdotjs/svg.js';
import {
  PageMetaFile__Line__Word,
  PageMetaFile__Reading__Pause,
} from 'core/page-meta-file-resolver';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Properties } from 'utils/properties.type';
import { Letter } from './letter';
import { Line } from './line';
import { Pause } from './pause';
import { ReadingType } from './reading';
import { Rollback } from './rollback';
import { WordLink } from './word-link';

export const DATA_WORD_ID = 'word-id';

export class Word {
  public bound_left: number;
  public bound_right: number;
  public linked_words: WordLink[] = [];
  public rollback?: Rollback;

  private _position: PageMetaFile__Line__Word['position'];

  private _letters: Letter[] = [];

  private duration: Partial<
    Record<ReadingType, { start: number; end: number; auto?: boolean }>
  > = {};

  public pauses: Record<ReadingType, Readonly<Pause[]>> = {
    'normal-reading-with-spelling': [],
    'normal-reading-without-spelling': [],
  };

  private _pauses_change_event = new BehaviorSubject<{
    pauses: Record<ReadingType, Readonly<Pause[]>>;
  }>({
    pauses: {
      'normal-reading-with-spelling': [],
      'normal-reading-without-spelling': [],
    },
  });

  private _bound_change_event = new Subject<void>();

  constructor(
    public id: string,
    letters: Letter[],
    public line: Line,
    public text: string = '',
    public is_title: boolean = false,
  ) {
    this._letters = letters;
    const { x, x2 } = this.bbox();
    this.bound_left = x;
    this.bound_right = x2;
    this.initLetters();
    this.initDurations();
  }

  public setBound(left: number, right: number) {
    this.bound_left = left;
    this.bound_right = right;
    this._bound_change_event.next();
  }

  public onBoundChange() {
    return this._bound_change_event.asObservable();
  }

  public setDuration(
    start: number,
    end: number,
    auto: boolean,
    type: ReadingType,
  ) {
    this.duration[type].start = start;
    this.duration[type].end = end;
    this.duration[type].auto = auto;
    this.fixAutomatedPauses(type);
  }

  public durationOf(reading_type: ReadingType) {
    return this.duration[reading_type];
  }

  private initLetters() {
    this._letters.forEach((letter) => {
      this.markLetterAsChild(letter);
    });
  }

  private initDurations() {
    const types = Object.values(ReadingType);
    types.forEach((type) => {
      this.duration[type] = { start: 0, end: 0, auto: true };
    });
  }

  public addPause(
    start: number,
    duration: number,
    reading_type: ReadingType,
    auto: boolean = false,
  ) {
    const pause = new Pause(this.id, duration, start, auto, auto);
    this.pauses[reading_type] = this.pauses[reading_type].concat(pause);
    this._pauses_change_event.next({ pauses: this.pauses });
  }
  public removePause(pause: Pause, reading_type: ReadingType) {
    this.pauses[reading_type] = this.pauses[reading_type].filter(
      (_pause) => pause !== _pause,
    );
    this._pauses_change_event.next({ pauses: this.pauses });
  }

  public addDefaultPause(reading_type: ReadingType) {
    const { duration, start } = this.calcDefaultPauseValues(reading_type);
    const pause = new Pause(this.id, duration, start, true, true);
    this.pauses[reading_type] = this.pauses[reading_type].concat(pause);
    this._pauses_change_event.next({ pauses: this.pauses });
  }

  public fixAutomatedPauses(reading_type: ReadingType) {
    this.pauses[reading_type].forEach((pause) => {
      const { duration, start } = this.calcDefaultPauseValues(reading_type);
      if (pause.auto_calculated_duration) {
        pause.setDuration(duration * 1.2, true);
      }
      if (pause.auto_calculated_start) {
        pause.setDuration(start, true);
      }
    });
  }

  public calcDefaultPauseValues(reading_type: ReadingType): {
    duration: number;
    start: number;
  } {
    const duration = this._calcPauseDuration(
      this.duration[reading_type].start,
      this.duration[reading_type].end,
    );
    const start = this.duration[reading_type].end;

    return { duration, start };
  }

  public position() {
    const svg_bbox = this.line.page.Viewbox;
    const end = (1 - this.bound_left / svg_bbox.x2) * 100;
    const start = (1 - this.bound_right / svg_bbox.x2) * 100;

    return {
      end,
      start,
    };
  }

  public getPauseDurationFor(
    position: number,
    reading_type: ReadingType,
  ): number {
    let prev_position = this.duration[reading_type].start;
    const pauses = this.SortedPausesByFirst(reading_type);
    const last = pauses[pauses.length - 1];
    if (last && last.start < position) {
      prev_position = last.start;
    }
    const duration = this._calcPauseDuration(prev_position, position) + 0.5;
    return +duration.toFixed(4);
  }

  public SortedPausesByFirst(reading_type: ReadingType): Pause[] {
    return this.pauses[reading_type].concat().sort((a, b) => a.start - b.start);
  }

  calcPosition() {
    const { x2 } = this.line.page.Viewbox;
    const position = this.position();
    const start = this._position?.start || position.start;
    const end = this._position?.end || position.end;
    this.bound_right = (1 - start / 100) * x2;
    this.bound_left = (1 - end / 100) * x2;
  }

  public bbox() {
    const word_bbox = this._letters.reduceRight<Box>(
      (pre, curr) => (pre == null ? curr.el.bbox() : pre.merge(curr.el.bbox())),
      null,
    );
    return word_bbox;
  }

  public disassemble() {
    this._letters.forEach((letter) => {
      letter.el.forget(DATA_WORD_ID);
    });
  }

  public onPausesChange(): Observable<{
    pauses: Record<ReadingType, Readonly<Pause[]>>;
  }> {
    return this._pauses_change_event.asObservable();
  }

  public link({
    linked_position: link_to_position,
    linked_word_id: link_to_id,
  }: Properties<WordLink>): void {
    if (this.linked_words.some((link) => link.linked_word_id === link_to_id))
      return;
    this.linked_words.push(
      new WordLink({
        linked_word_id: link_to_id,
        linked_position: link_to_position,
      }),
    );
  }

  public unlink(word_id: string): void {
    const index = this.linked_words.findIndex(
      ({ linked_word_id: link_to_id }) => link_to_id === word_id,
    );
    if (index === -1) return;
    this.linked_words.splice(index, 1);
  }

  private markLetterAsChild(letter: Letter) {
    letter.el.remember(DATA_WORD_ID, this.id);
  }

  letters(letter: Letter | Letter[]): this;
  letters(): Letter[];
  letters(letters?: Letter[] | Letter): this | Letter[] {
    if (!letters) {
      return this._letters;
    } else if (Array.isArray(letters)) {
      this._letters = letters;
    } else {
      const exists = this._letters.includes(letters);
      if (!exists) this._letters.push(letters);
    }
  }

  toFileJson(): PageMetaFile__Line__Word {
    const json: PageMetaFile__Line__Word = {
      id: this.id,
      letters: this._letters.map((letter) => letter.toFileJson()),
      is_title: this.is_title,
      duration: {
        [ReadingType.NormalReadingWithOutSpelling]: {
          start: +(
            this.duration['normal-reading-without-spelling'].start * 1000000
          ).toFixed(0),
          end: +(
            this.duration['normal-reading-without-spelling'].end * 1000000
          ).toFixed(0),
        },
        [ReadingType.NormalReadingWithSpelling]: {
          start: +(
            this.duration['normal-reading-with-spelling'].start * 1000000
          ).toFixed(0),
          end: +(
            this.duration['normal-reading-with-spelling'].end * 1000000
          ).toFixed(0),
        },
      },
      linked_words: this.linked_words,
      position: this.position(),
      string_text: this.text,
      rollback: this.rollback ? this.rollback.toJson() : {},
    };
    return json;
  }
  loadFromFileJson(
    json: PageMetaFile__Line__Word,
    pauses: Record<ReadingType, PageMetaFile__Reading__Pause[]>,
  ): this {
    this.duration = {
      'normal-reading-with-spelling': {
        start: json.duration['normal-reading-with-spelling'].start / 1_000_000,
        end: json.duration['normal-reading-with-spelling'].end / 1_000_000,
      },
      'normal-reading-without-spelling': {
        start:
          json.duration['normal-reading-without-spelling'].start / 1_000_000,
        end: json.duration['normal-reading-without-spelling'].end / 1_000_000,
      },
    };
    this.linked_words = json.linked_words || [];
    this.text = json.string_text;
    this.is_title = !!json.is_title;
    this._position = json.position;
    this.rollback = Rollback.fromJson(json.rollback);
    this.pauses = {
      [ReadingType.NormalReadingWithOutSpelling]: [],
      [ReadingType.NormalReadingWithSpelling]: [],
    };
    Object.values(ReadingType).forEach((type) => {
      this._addPauses(type, pauses[type]);
    });

    return this;
  }

  public static toDTO(word: Partial<Word>): WordDTO {
    return {
      word: word.text,
      wordId: word.id,
      lineIndex: word.line.index(),
    };
  }

  private _calcPauseDuration(
    prev_pause_start: number,
    pause_start: number,
  ): number {
    return pause_start - prev_pause_start;
  }

  private _addPauses(
    type: ReadingType,
    pauses: PageMetaFile__Reading__Pause[],
  ) {
    const mine = pauses.filter((pause) => this.id === pause.word_id);
    this.pauses[type] = mine.map((pause) => Pause.fromFileJson(pause));
    this._pauses_change_event.next({ pauses: this.pauses });
  }
}
export interface WordDTO {
  word: string;
  wordId: string;
  lineIndex: number;
}
