import { chunck } from './chuncker';

const RGB_REGEXP = /rgb\(\s*(?<r>\d{1,3})\s*,\s*(?<g>\d{1,3})\s*,\s*(?<b>\d{1,3})\s*\)/;
const HEX_REGEXP = /#(?<r>[A-Fa-f\d]{2})(?<g>[A-Fa-f\d]{2})(?<b>[A-Fa-f\d]{2})(?<a>[A-Fa-f\d]{0,2})/;

function toDartColor(color: string): string {
  const { a, b, g, r } = disassemble(color);
  return `0x${a}${r}${g}${b}`;
}

function fromDartColor(color: string): string {
  const stripped = color.slice(2);
  let [a, r, g, b] = chunck(stripped, 2);
  if (b == null) {
    b = g;
    g = r;
    r = a;
    a = null;
  }
  return `#${r}${g}${b}${a || ''}`;
}

function disassemble(color: string): DisassembledColor {
  let disassembled_color: DisassembledColor = null;
  disassembled_color = fromRGB(color);
  if (disassembled_color) return disassembled_color;

  disassembled_color = fromHex(color);
  if (disassembled_color) return disassembled_color;

  console.error(`could not parse ${color}`);

  return { a: '1', b: '0', g: '0', r: '0' };
}

function fromRGB(rgb: string): DisassembledColor | null {
  const match = RGB_REGEXP.exec(rgb);
  if (!match) return null;
  const { r, b, g } = match.groups;
  return {
    r: +r < 16 ? `0${(+r).toString(16)}` : (+r).toString(16),
    g: +g < 16 ? `0${(+g).toString(16)}` : (+g).toString(16),
    b: +b < 16 ? `0${(+b).toString(16)}` : (+b).toString(16),
    a: 'FF',
  };
}
function fromHex(hex: string): DisassembledColor | null {
  const match = HEX_REGEXP.exec(hex);
  if (!match) return null;
  const { r, b, g } = match.groups;
  return {
    r,
    g,
    b,
    a: 'FF',
  };
}

export { toDartColor, fromDartColor, disassemble };
interface DisassembledColor {
  r: string;
  g: string;
  b: string;
  a: string;
}
