import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgApexchartsModule } from 'ng-apexcharts';
import { CompareColumnChartComponent } from './compare-column-chart.component';

@NgModule({
  declarations: [CompareColumnChartComponent],
  imports: [NgApexchartsModule, CommonModule],
  exports: [CompareColumnChartComponent],
})
export class CompareColumnModule {}
