import { NgModule } from '@angular/core';
import { NgxAuthRouting } from './auth.routing';
import { NgxAuthContainersModule } from './containers/auth.containers.module';
import { NgxAuthService } from './services/ngx-auth.service';

@NgModule({
  imports: [NgxAuthContainersModule, NgxAuthRouting],
  exports: [NgxAuthContainersModule],
  providers: [NgxAuthService],
})
export class NgxAuthModule {}
