<div
  style="
    display: flex;
    column-gap: 1rem;
    row-gap: 1rem;
    flex-flow: row wrap;
    justify-content: center;
  "
>
  <nb-card>
    <nb-card-header> Ads </nb-card-header>

    <nb-card-body>
      <ng2-smart-table
        (create)="addNew()"
        (edit)="edit($event.data)"
        [settings]="settings"
        [source]="source"
      >
      </ng2-smart-table>
    </nb-card-body>
  </nb-card>
</div>
