import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Howl } from 'howler';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TrackEditorDirective } from '../../directives/track-editor/track-editor.directive';

@Component({
  selector: 'ngx-page-track-editor',
  templateUrl: './page-track-editor.component.html',
  styleUrls: ['./page-track-editor.component.scss'],
})
export class PageTrackEditorComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Output('onTick') tick_event = new EventEmitter<number>();

  @Output('onPlay') play_event = new EventEmitter<void>();

  @Input('url')
  public set url(v: string) {
    this._url = v;
    this.loadTrack()
      .pipe(takeUntil(this.$destroyed))
      .subscribe((howl) => {
        this.howl = howl;
        this.bindForms();
        this.bindState();
        this.bindTicker();
      });
  }
  public get url(): string {
    return this._url;
  }
  private _url: string;

  @Input('teacherReading')
  teacher_reading: boolean = false;

  @Output('teacherReadingChange')
  teacher_reading_event = new EventEmitter();

  @Input('mode')
  mode: string;

  @Output('changeMode')
  change_mode_event = new EventEmitter<string>();

  @ViewChild(TrackEditorDirective, {
    read: TrackEditorDirective,
    static: false,
  })
  track_editor: TrackEditorDirective;

  howl: Howl;

  $destroyed = new Subject<void>();

  current_seek = new FormControl({ value: 0, disabled: true });
  section_group = new FormGroup(
    {
      from: new FormControl(),
      to: new FormControl(),
    },
    { updateOn: 'blur' },
  );

  isPlaying: boolean;
  isPaused: boolean;
  isStopped: boolean;

  is_teacher_control = new FormControl();

  constructor() {}

  ngOnInit(): void {
    this.is_teacher_control.valueChanges.subscribe((v) => {
      this.teacher_reading_event.emit(v);
    });
  }

  ngAfterViewInit() {}

  ngOnDestroy() {
    if (this.howl) this.howl.unload();
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  bindForms() {
    this.track_editor
      .onSelectionResize()
      .pipe(takeUntil(this.$destroyed))
      .subscribe(({ begin, end }) => {
        const from = +((begin / 100) * this.howl.duration()).toFixed(4);
        const to = +((end / 100) * this.howl.duration()).toFixed(4);
        this.section_group.patchValue({ from, to }, { emitEvent: false });
      });
    this.section_group.valueChanges
      .pipe(takeUntil(this.$destroyed))
      .subscribe(({ from, to }) => {
        const { from: fixedf, to: fixedt } = this.track_editor.fix({
          from,
          to,
        });
        this.track_editor.select(fixedf, fixedt);
      });
    this.track_editor
      .onUpdate()
      .pipe(takeUntil(this.$destroyed))
      .subscribe(() => {
        const seek = this.howl.seek() as number;
        this.current_seek.setValue(+seek.toFixed(0));
      });

    this.section_group.patchValue({
      from: 0,
      to: this.howl.duration(),
    });
  }
  bindState() {
    this.track_editor
      .onStateChange()
      .pipe(takeUntil(this.$destroyed))
      .subscribe((state) => {
        if (state === 'stopped') {
          this.isStopped = true;
          this.isPaused = false;
          this.isPlaying = false;
        } else if (state === 'playing') {
          this.isPlaying = true;
          this.isStopped = false;
          this.isPaused = false;
        } else if (state === 'paused') {
          this.isPaused = true;
          this.isStopped = false;
          this.isPlaying = false;
        }
      });
  }
  bindTicker() {
    this.track_editor.onUpdate().subscribe(() => {
      this.tick_event.emit(this.howl.seek() as number);
    });
  }

  toggle() {
    if (this.isPlaying) this.pause();
    else if (this.isPaused || this.isStopped) this.play();
  }
  stop() {
    this.howl.stop();
  }
  replaySelection() {
    this.howl.seek(this.section_group.value.from);
  }
  forward(secs: number) {
    const next = (this.howl.seek() as number) + secs;
    this.howl.seek(next);
  }
  backward(secs: number) {
    const next = (this.howl.seek() as number) - secs;
    this.howl.seek(next);
  }

  loadTrack(): Observable<Howl> {
    if (this.howl) this.howl.unload();
    return new Observable((subscriber) => {
      this.howl = new Howl({
        autoplay: false,
        src: [this.url],
        format: ['mpeg', 'mp4', 'm4a'],
      });
      this.howl.load();
      this.howl.once('load', () => {
        subscriber.next(this.howl);
        subscriber.complete();
      });
      this.howl.once('loaderror', (id, error) => {
        subscriber.error(error);
        subscriber.complete();
      });
    });
  }

  snapEnd() {
    const seek_percent =
      ((this.howl.seek() as number) / this.howl.duration()) * 100;
    this.track_editor.end_handler.move(seek_percent, { emit_event: true });
  }
  snapBegin() {
    const seek_percent =
      ((this.howl.seek() as number) / this.howl.duration()) * 100;
    this.track_editor.begin_handler.move(seek_percent, { emit_event: true });
  }

  pause() {
    this.howl.pause();
  }
  play() {
    this.play_event.emit();
    this.howl.play();
  }

  switchToView() {
    this.change_mode_event.emit('view');
  }
  switchToEdit() {
    this.change_mode_event.emit('edit');
  }

  public selection(): { begin: number; end: number } {
    const { from, to } = this.section_group.value;
    return { begin: from, end: to };
  }

  public seek(to: number) {
    this.track_editor.howl.seek(to);
  }
  /**
   *
   * @param begin
   * @param end
   * @description moves begin and end handlers
   */
  public select(begin?: number, end?: number) {
    if (!begin) begin = this.section_group.get('from').value;
    if (!end) end = this.section_group.get('to').value;
    this.track_editor.select(begin, end);
  }
}
