import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Profile } from 'app/services/profile.service';
import { UsersService } from 'app/services/users.service';
import { CountryFacade } from 'core/facade/country-facade.service';
import { Country } from 'models/country';
import { Gender } from 'models/gender';
import { User } from 'models/user';
import { UserRoles } from 'models/user-role';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'ngx-user-profile-form',
  templateUrl: './user-profile-form.component.html',
  styleUrls: ['./user-profile-form.component.scss'],
})
export class UserProfileFormComponent {
  @Input('user')
  public set user(v: User) {
    this._user = v;
    if (this.mode == 'edit') this.toDisplayMode();
    this.setAssignableRoles();
    this.patch();
  }
  public get user(): User {
    return this._user;
  }
  private _user: User;

  @Input()
  loading: boolean;

  @Output('onEditUser') edit_user_event = new EventEmitter();
  @Output('onDeleteUser') delete_user_event = new EventEmitter();
  @Output('onReloadUser') reload_user_event = new EventEmitter();
  @Output('onActivateUser') activate_user_event = new EventEmitter();
  @Output('onDeactivateUser') deactivate_user_event = new EventEmitter();

  form = new FormGroup({
    username: new FormControl({ value: null, disabled: true }),
    email: new FormControl({ value: null, disabled: true }),
    gender: new FormControl({ value: null, disabled: true }),
    role: new FormControl({ value: null, disabled: true }),
    phonenumber: new FormControl({ value: null, disabled: true }),
    countryId: new FormControl({ value: null, disabled: true }),
  });

  mode: 'edit' | 'display' = 'display';

  roles: UserRoles[] = [];

  countries$ = new BehaviorSubject<Country[]>([]);

  constructor(
    private user_service: UsersService,
    private _countryFacade: CountryFacade,
    private profile: Profile,
  ) {
    _countryFacade.list().subscribe((countries) => {
      this.countries$.next(countries);
    });
  }

  onStartEdit() {
    this.toEditMode();
  }
  onConfirmEdit() {
    const value = this.value();
    this.edit_user_event.emit(value);
  }
  onCancelEdit() {
    this.toDisplayMode();
    this.patch();
  }
  onDeleteUser() {
    this.delete_user_event.emit();
  }
  onReloadUser() {
    this.reload_user_event.emit();
  }
  onActivateUser(): void {
    this.activate_user_event.emit(void 0);
  }
  onDeactivateUser(): void {
    this.deactivate_user_event.emit(void 0);
  }
  onToggleUserActivity(): void {
    if (this.user.is_active) {
      this.onDeactivateUser();
    } else {
      this.onActivateUser();
    }
  }

  private value(): EditUserForm {
    return this.form.value as EditUserForm;
  }
  private toEditMode() {
    this.mode = 'edit';
    this.form.enable();
    this.form.get('email').disable();
  }
  private toDisplayMode() {
    this.mode = 'display';
    this.form.disable();
  }

  private async setAssignableRoles() {
    const me = await this.profile.Me();
    this.roles = this.user_service.grantableRoles(me.role);
  }

  private patch() {
    this.form.patchValue({
      username: this.user?.username,
      email: this.user?.email,
      gender: this.user?.gender,
      role: this.user?.role,
      phonenumber: this.user?.phonenumber,
      countryId: this.user?.countryId,
    } as EditUserForm);
  }

  get $Roles(): typeof UserRoles {
    return UserRoles;
  }
  get $Gender(): typeof Gender {
    return Gender;
  }
}

export interface EditUserForm {
  username: string;
  email: string;
  gender: Gender;
  role: UserRoles;
  countryId?: number;
  phonenumber: string;
}
