import { Subscription } from 'models/subscription';
import { SubscriptionPricing } from 'models/subscription-pricing';
import { SubscriptionPricingDiscount } from 'models/subscription-pricing-discount';
import { fromMinutes } from 'utils/date';
import { CountryAdapter } from '../country/country-adapter';
import { SubscriptionDTO } from './subscription-dto';

export namespace SubscriptionAdapter {
  export function subscription(dto: SubscriptionDTO.Base): Subscription {
    return new Subscription({
      id: dto.id,
      discount: {
        type: dto.discountType,
        value: dto.discount,
      },
      duration: fromMinutes(dto.duration, {
        years: true,
        months: true,
        days: true,
        hours: true,
        minutes: true,
      }),
      allowanceTime: fromMinutes(dto.allowanceTime, {
        years: true,
        months: true,
        days: true,
        hours: true,
        minutes: true,
      }),
      descriptionAr: dto.descriptionAr,
      descriptionEn: dto.descriptionEn,
      descriptionFr: dto.descriptionFr,
      max_active_tokens: dto.maxActiveTokens,
      login_times: dto.loginTimes,
      name: dto.nameEn,
      nameEn: dto.nameEn,
      nameAr: dto.nameAr,
      nameFr: dto.nameFr,
      pricings: pricings(dto.pricings),
      countries: CountryAdapter.countries(dto.countries),
      pricings_discounts: pricingDiscounts(dto.pricings_discounts),
      deactivated: dto.deactivated,
    });
  }
  export function subscriptions(dtos: SubscriptionDTO.Base[]): Subscription[] {
    return dtos?.map((dto) => subscription(dto)) ?? [];
  }

  export function pricing(
    dto: SubscriptionDTO.SubscriptionPricingBase,
  ): SubscriptionPricing {
    return new SubscriptionPricing({
      enabled_by_default: dto.enabledByDefault,
      id: dto.id,
      price: dto.price,
      recital: dto.recital,
      subscription_id: dto.subscriptionId,
    });
  }
  export function pricings(
    dtos: SubscriptionDTO.SubscriptionPricingBase[],
  ): SubscriptionPricing[] {
    return dtos?.map((dto) => pricing(dto)) ?? [];
  }

  export function pricingDiscount(
    dto: SubscriptionDTO.SubscriptionPricingDiscountBase,
  ): SubscriptionPricingDiscount {
    return new SubscriptionPricingDiscount({
      count: dto.count,
      discount: dto.discount,
      id: dto.id,
      subscriptionId: dto.subscriptionId,
      type: dto.type,
    });
  }
  export function pricingDiscounts(
    dtos: SubscriptionDTO.SubscriptionPricingDiscountBase[],
  ): SubscriptionPricingDiscount[] {
    return dtos?.map((dto) => pricingDiscount(dto)) ?? [];
  }
}
