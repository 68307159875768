import { PageMetaFile__Reading__Tween } from 'core/page-meta-file-resolver';
import { Animation } from './animation';
import { Letter } from './letter';

export class Tween {
  animations: Animation[] = [];

  constructor(
    public id: string,
    public start: number,
    public end: number,
    public word_id: string,
  ) {}

  apply() {
    this.animations.forEach((animation) => animation.exec());
  }

  deleteAnimation(letter_id: string) {
    const index = this.animations.findIndex(
      (animation) => animation.letter.id === letter_id,
    );
    if (index < 0) return;
    this.animations.splice(index, 1);
  }

  toFileJson(): PageMetaFile__Reading__Tween {
    this.animations = this.animations.filter(
      (animation) => animation.letter != null,
    );

    const json = {
      id: this.id,
      duration: {
        start: +(this.start * 1000000).toFixed(0),
        end: +(this.end * 1000000).toFixed(0),
      },
      animations: this.animations.map((animation) => animation.toFileJson()),
      word_id: this.word_id,
    };

    return json;
  }
  public static fromFileJson(
    json: PageMetaFile__Reading__Tween,
    letters: Letter[],
  ): Tween {
    const tween = new Tween(
      json.id,
      json.duration.start / 1000000,
      json.duration.end / 1000000,
      json.word_id,
    );
    json.animations.forEach((animation_json) => {
      const animation = Animation.fromFileJson(animation_json, letters, tween);
      tween.animations.push(animation);
    });
    return tween;
  }
}
