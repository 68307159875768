<apx-chart
  *ngIf="!loading"
  [series]="chartOptions.series ?? []"
  [chart]="{
    type: 'bar',
    height: this.height
  }"
  [dataLabels]="{
    enabled: false
  }"
  [plotOptions]="{
    bar: {
      horizontal: false,
      columnWidth: '55%'
    }
  }"
  [legend]="chartOptions.legend ?? {}"
  [fill]="chartOptions.fill ?? {}"
  [stroke]="chartOptions.stroke ?? {}"
  [tooltip]="chartOptions.tooltip"
  [xaxis]="chartOptions.xAxis ?? {}"
  [colors]="this.colors"
></apx-chart>
