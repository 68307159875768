import { Country } from 'models/country';
import { CountryDTO } from './country-dto';

export namespace CountryAdapter {
  export function country(dto: CountryDTO.Base): Country {
    return new Country({
      ISO: dto.ISO,
      id: dto.id,
      name: dto.nameEn,
    });
  }
  export function countries(dtos: CountryDTO.Base[]): Country[] {
    return dtos?.map((dto) => country(dto)) ?? [];
  }
}
