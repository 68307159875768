import * as _ from 'lodash';
import { ReadingType } from 'models/reading';
import * as Version2 from '../version 2/types';
import * as Version1 from './types';

export function toNextVersion(
  from: Version1.PageMetaFile,
): Version2.PageMetaFile {
  return {
    dark_background_svg: from.dark_background_svg,
    light_background_svg: from.light_background_svg,
    lines: from.lines,
    readings: upgradeReadings(from.readings, from.lines),
    version: '2',
  };
}

export function isSameVersion(meta: any): boolean {
  return meta.version == undefined;
}

function upgradeReadings(
  readings: Version1.PageMetaFile__Reading[],
  lines: Version1.PageMetaFile__Line[],
): Version2.PageMetaFile__Reading[] {
  return readings.map((reading) => {
    return {
      ...reading,
      tweens: reading.tweens.map((tween) => {
        return {
          ...tween,
          word_id: wordIdForTween(
            reading.type,
            _.flatten(lines.map((line) => line.words)),
            tween,
          ),
        };
      }),
    };
  });
}

function wordIdForTween(
  type: ReadingType,
  words: Version1.PageMetaFile__Line__Word[],
  tween: Version1.PageMetaFile__Reading__Tween,
): string {
  let word = words.find((word) =>
    includes(word.duration[type], tween.duration),
  );
  if (!word)
    word = words.find((word) =>
      partiallyIncludes(word.duration[type], tween.duration),
    );

  // if not word was found that means the tween is beyond every word start so we add it to the last one (closest one)
  if (!word) word = words[words.length - 1];
  else return word.id;
  return undefined;
}

function includes(
  duration?: Version1.PageMetaFile__Duration,
  includes?: Version1.PageMetaFile__Duration,
) {
  if (!duration || !includes) return;
  return duration.start <= includes.start && duration.end >= includes.end;
}
function partiallyIncludes(
  outer?: Version1.PageMetaFile__Duration,
  inner?: Version1.PageMetaFile__Duration,
) {
  if (!outer || !inner) return;

  return outer.start <= inner.start && outer.end >= inner.start;
}
