<nb-card [nbSpinner]="loading | async" class="w-100">
  <nb-card-header
    style="padding-top: 0; padding-bottom: 0"
    class="d-flex align-items-center"
  >
    Vouchers
    <nb-card-body>
      <div class="buttons-row">
        <button nbButton [shape]="'rectangle'" (click)="downloadExcel()">
          export excel
        </button>
      </div>
    </nb-card-body>
  </nb-card-header>

  <nb-card-body>
    <div class="d-flex align-items-center gap-2rem" style="padding: 1rem">
      <div class="section">
        <h5>From:</h5>
        <input
          nbInput
          [(ngModel)]="startDate"
          placeholder="Start Date"
          [nbDatepicker]="formpicker"
          (ngModelChange)="updatePagination()"
        />
        <nb-datepicker #formpicker></nb-datepicker>
      </div>
      <div class="section">
        <h5>To:</h5>
        <input
          [(ngModel)]="endDate"
          nbInput
          placeholder="End Date"
          [nbDatepicker]="picker"
          (ngModelChange)="updatePagination()"
        />
        <nb-datepicker #picker></nb-datepicker>
      </div>
    </div>

    <ngx-chart-panel-summary
      [summary]="chartPanelSummary | async"
    ></ngx-chart-panel-summary>

    <!--! TABLE -->
    <fourqan-vouchers-prime-table
      [data]="vouchers$ | async"
      [totalCount]="totalCount | async"
      [loading]="loading | async"
      (add)="addNew()"
      (edit)="edit($event)"
      (pageEvent)="changePage($event)"
      (statusEvent)="searchForStatus($event)"
      (titleEvent)="searchForTitle($event)"
      (codeEvent)="searchForCode($event)"
    >
    </fourqan-vouchers-prime-table>
  </nb-card-body>
</nb-card>
