<nb-card>
  <nb-card-header style="display: flex; align-items: center">
    <span>Choose Subscription</span>
    <button
      (click)="onCancel()"
      nbButton
      ghost
      style="margin-inline-start: auto"
    >
      <nb-icon icon="close-square-outline"></nb-icon>
    </button>
  </nb-card-header>
  <nb-card-body class="container">
    <ngx-add-user-subscription-card
      *ngFor="let subscription of options"
      (giftUserEvent)="onSubmit(subscription, $event)"
      [subscription]="subscription"
    ></ngx-add-user-subscription-card>
    <!-- <nb-card class="subscription" *ngFor="let subscription of options">
      <nb-card-header>
        {{ subscription.name }}
      </nb-card-header>
      <nb-card-body>
        <div class="recitals">
          <nb-checkbox>Hafs</nb-checkbox>
          <nb-checkbox>Qaloun</nb-checkbox>
          <nb-checkbox>Warsh</nb-checkbox>
        </div>
        <div class="sub emphasis">
          Gives access for
          <ng-container *ngIf="subscription.duration.years > 0"
            ><span class="show info" style="font-size: 1rem">
              {{ subscription.duration.years }} </span
            >years</ng-container
          >
          <ng-container *ngIf="subscription.duration.months > 0"
            ><span class="show info" style="font-size: 1rem">
              {{ subscription.duration.months }} </span
            >months</ng-container
          >
          <ng-container *ngIf="subscription.duration.days > 0"
            ><span class="show info" style="font-size: 1rem">
              {{ subscription.duration.days }} </span
            >days</ng-container
          >
          <ng-container *ngIf="subscription.duration.hours > 0">
            and
            <span class="show info" style="font-size: 1rem">
              {{ subscription.duration.hours }} </span
            >hours</ng-container
          >
        </div>
      </nb-card-body>
      <nb-card-footer>
        <button (click)="onSubmit(subscription)" nbButton status="success">
          Gift User
        </button>
      </nb-card-footer>
    </nb-card> -->
  </nb-card-body>
</nb-card>
