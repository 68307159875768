<div class="box">
  <div class="snapshot" style="overflow: hidden" (click)="onSelect()">
    <svg [class.is-title]="word.is_title" #container></svg>
  </div>
  <div class="actions">
    <button (click)="onDelete()" class="action" nbButton status="danger">
      <nb-icon icon="trash-2"></nb-icon>
    </button>
    <button
      (click)="expanded ? collapse() : expand()"
      class="action"
      nbButton
      status="info"
    >
      <nb-icon
        [icon]="
          expanded ? 'arrow-ios-upward-outline' : 'arrow-ios-downward-outline'
        "
      ></nb-icon>
    </button>
  </div>
  <form [formGroup]="form" class="word-form" style="width: 100%">
    <div class="text">
      <input
        formControlName="text"
        type="text"
        fullWidth
        nbInput
        placeholder="start"
      />
    </div>
    <div class="from">
      <input
        formControlName="start"
        type="number"
        fullWidth
        nbInput
        placeholder="start"
      />
    </div>
    <div class="to">
      <input
        formControlName="end"
        type="number"
        fullWidth
        nbInput
        placeholder="end"
      />
    </div>
    <div class="bound-left">
      <input formControlName="bleft" type="number" fullWidth nbInput />
    </div>
    <div class="bound-right">
      <input formControlName="bright" type="number" fullWidth nbInput />
    </div>
  </form>
</div>

<div *ngIf="expanded && show == 'pauses'" class="rollback">
  <input
    [formControl]="rollback_control"
    type="number"
    fullWidth
    nbInput
    placeholder="rollback time"
    status="warning"
  />
</div>

<div *ngIf="expanded && show == 'pauses'" class="pauses">
  <div
    class="pause"
    *ngFor="let pause_control of pauses_controls.controls; let index = index"
  >
    <button
      (click)="onDeletePause(index)"
      class="action"
      nbButton
      status="danger"
    >
      <nb-icon icon="trash-2"></nb-icon>
    </button>
    <button (click)="onSeekPause(index)" class="action" nbButton status="info">
      <nb-icon icon="eye"></nb-icon>
    </button>
    <form [formGroup]="pause_control" class="word-form" style="width: 100%">
      <div class="from">
        <input
          formControlName="start"
          type="number"
          fullWidth
          nbInput
          placeholder="start"
        />
      </div>
      <div class="to">
        <input
          formControlName="duration"
          type="number"
          fullWidth
          nbInput
          placeholder="end"
        />
      </div>
    </form>
  </div>
</div>

<div *ngIf="expanded && show == 'tweens'" class="tweens">
  <ngx-tween-view-list-item
    (onViewLetter)="viewLetter($event)"
    [max]="word.durationOf(this.reading).end"
    [min]="word.durationOf(this.reading).start"
    (onDeleteAnimation)="onDeleteAnimation($event)"
    (onDelete)="onTweenDelete(tween)"
    (onEditTime)="onTweenEdit($event, tween)"
    [tween]="tween"
    class="tween"
    *ngFor="let tween of tweens"
  >
  </ngx-tween-view-list-item>
</div>
