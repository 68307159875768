import { Injectable } from '@angular/core';
import { CountryGateway } from 'core/gateways/country/country-gateway.service';
import { Country } from 'models/country';
import { BehaviorSubject, Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CountryFacade {
  private _countries_source = new BehaviorSubject<Country[]>([]);

  constructor(private _gateway: CountryGateway) {}

  list(): Observable<Country[]> {
    return this._countries_source.getValue().length > 0
      ? this._countries_source.asObservable().pipe(take(1))
      : this._list();
  }

  private _list(): Observable<Country[]> {
    return this._gateway.list().pipe(
      tap((countries) => {
        this._countries_source.next(countries);
      }),
    );
  }
}
