<div class="video-container">
  <ng-container
    *ngIf="IsPreview; else picker"
    [ngTemplateOutlet]="preview"
  ></ng-container>
</div>

<ng-template #preview>
  <div class="preview-container">
    <video
      autoplay="true"
      controls="true"
      [src]="previewUrl"
      class="preview"
    ></video>
    <button (click)="clearValue()" class="clear-btn" nbButton>
      <nb-icon icon="close-circle-outline"></nb-icon>
    </button>
  </div>
</ng-template>
<ng-template #picker>
  <div (click)="fileInput.click()" class="picker-container">
    <input
      #fileInput
      type="file"
      accept="video/*"
      (change)="handleFileChange($event)"
      hidden
    />
    <nb-icon class="icon" icon="film-outline"></nb-icon>
  </div>
</ng-template>
