import { Injectable } from '@angular/core';
import { Image, SVG } from '@svgdotjs/svg.js';
import { ReadingType } from 'models/reading';
import { Tween } from 'models/tween';
import { Word } from 'models/word';

@Injectable()
export class PageEditorTweenService {
  tweens: Tween[];
  words: Word[];
  accessories: {
    [word_is: string]: Image;
  } = {};

  last_seek: number;
  last_tween_index: number;

  mode: 'light' | 'box';

  constructor() {}

  doCheck(seek: number, type: ReadingType) {
    this.doCheckWord(seek, type);
    this.tweens.some((tween) => {
      if (tween.start > seek) return true;
      if (tween.start <= seek && tween.end <= seek) return false;

      tween.apply();
    });
  }

  doCheckWord(seek: number, type: ReadingType) {
    this.words.forEach((word) => {
      const accessory = this.accessories[word.id];
      const duration = word.durationOf(type);
      if (duration.start + duration.end === 0) return false;
      if (duration.start > seek) return;
      if (duration.end < seek) {
        if (accessory) accessory.remove();
        word.letters().forEach((letter) => letter.fill(true));
        delete this.accessories[word.id];
        return false;
      }

      if (!accessory) {
        this.buildBox(word);
        word.letters().forEach((letter) => letter.fill());
      }
      word.letters().forEach((letter) => letter.el.fill('grey'));
    });
  }

  buildBox(word: Word) {
    const svg = SVG(word.letters()[0].el.parent());

    const { y, y2 } = word.line.bbox();

    const image = SVG()
      .image('assets/noSh@4x.png')
      .attr('preserveAspectRatio', 'none')
      .center((word.bound_left + word.bound_right) / 2, (y + y2) / 2)
      .size(word.bound_right - word.bound_left, y2 - y);

    image.css('opacity', '0');

    svg.add(image, 0);

    image
      .size(1, 1)
      .animate(200, 0)
      .css('opacity', '1')
      .center((word.bound_left + word.bound_right) / 2, (y + y2) / 2)
      .size(word.bound_right - word.bound_left, y2 - y);

    this.accessories[word.id] = image;
  }

  dispose() {
    Object.values(this.accessories).forEach((accessory) => {
      accessory.remove();
    });
    this.accessories = {};
  }
}
