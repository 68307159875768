import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  NbAlertModule,
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbIconModule,
  NbInputModule,
  NbLayoutModule,
} from '@nebular/theme';
import { TranslateModule } from '@ngx-translate/core';
import { EmailPasswordLoginFormComponent } from './email-password-login-form/email-password-login-form.component';

@NgModule({
  imports: [
    CommonModule,
    NbLayoutModule,
    NbCardModule,
    NbCheckboxModule,
    NbAlertModule,
    NbInputModule,
    NbButtonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NbIconModule,
    TranslateModule,
  ],
  declarations: [EmailPasswordLoginFormComponent],
  exports: [EmailPasswordLoginFormComponent],
})
export class NgxAuthComponentsModule {}
