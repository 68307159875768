import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NbToastrService } from '@nebular/theme';
import { Animation } from 'models/animation';
import { Letter } from 'models/letter';
import { Tween } from 'models/tween';
import { AnimationViewListItemComponent } from '../animation-view-list-item/animation-view-list-item.component';

@Component({
  selector: 'ngx-tween-view-list-item',
  templateUrl: './tween-view-list-item.component.html',
  styleUrls: ['./tween-view-list-item.component.scss'],
})
export class TweenViewListItemComponent implements OnInit {
  @Output('onDelete')
  delete_tween_event = new EventEmitter();

  @Output('onEditTime')
  edit_time_event = new EventEmitter<{ start: number; end: number }>();

  @Output('onDeleteAnimation')
  delete_animation_event = new EventEmitter<Animation>();

  @Output('onViewLetter')
  view_letter_event = new EventEmitter<Letter>();

  @Input('tween')
  set tween(v: Tween) {
    this.useTween(v);
  }
  get tween(): Tween {
    return this._tween;
  }
  _tween: Tween;

  @Input() max: number;
  @Input() min: number;

  form = new FormGroup({
    start: new FormControl({ value: 0, disabled: false }),
    end: new FormControl({ value: 0, disabled: false }),
  });

  expanded = false;

  @ViewChildren(AnimationViewListItemComponent)
  animations: QueryList<AnimationViewListItemComponent>;

  constructor(private toastr_service: NbToastrService) {}

  ngOnInit(): void {
    this.form.valueChanges.subscribe((value) => {
      this.edit_time_event.emit({ ...value });
    });
  }

  public viewLetter(animation: Animation) {
    this.view_letter_event.emit(animation.letter);
  }

  public deleteTween() {
    this.delete_tween_event.emit();
  }
  public toggleAnimation() {
    this.expanded = !this.expanded;
    setTimeout(() => {
      this.animations.forEach((animation) => animation.reposition());
    }, 0);
  }
  public deleteAnimation(animation: Animation) {
    this.delete_animation_event.emit(animation);
  }
  public useTween(tween: Tween) {
    this._tween = tween;
    this.form.setValue(
      {
        start: tween.start,
        end: tween.end,
      },
      { emitEvent: false },
    );
  }
}
