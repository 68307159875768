<nb-card>
    <nb-card-header>
        Word Info
    </nb-card-header>
    <nb-card-body>
        <form>
            <div style="display: flex; flex-flow: column nowrap;">
                <label class="label" for="input-email">Word text:</label>
                <input [status]="control.invalid ? 'danger' : 'success'" [formControl]='control'
                    style="margin: 0.25rem;" nbInput placeholder="word text">
            </div>
        </form>
    </nb-card-body>
    <nb-card-footer>
        <button style="margin-inline-end: 1rem;" class="cancel" nbButton ghost status="danger"
            (click)="onCancel()">Cancel</button>
        <button nbButton status="success" (click)="onSubmit()">Submit</button>
    </nb-card-footer>
</nb-card>