import {
  Component,
  EventEmitter,
  Input,
  isDevMode,
  OnInit,
  Output,
} from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { Recital } from 'models/page';
import { Subscription } from 'models/subscription';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'ngx-add-user-subscription-card',
  templateUrl: './add-user-subscription-card.component.html',
  styleUrls: ['./add-user-subscription-card.component.scss'],
})
export class AddUserSubscriptionCardComponent implements OnInit {
  @Input()
  subscription!: Subscription;

  selectedRecitals!: FormGroup;

  @Output()
  giftUserEvent = new EventEmitter<AddUserSubscriptionCardGiftEvent>();

  canSubmit$: Observable<boolean>;

  constructor() {}

  ngOnInit(): void {
    if (!this.subscription && isDevMode()) {
      throw new Error('subscription input must be provided');
    }
    this.selectedRecitals = this._buildRecitalsForm();
    this.canSubmit$ = this._buildCanSubmitStream();
  }

  onSubmit() {
    this.giftUserEvent.emit({
      recitals: this._selectedRecitals(),
    });
  }

  get Recital(): typeof Recital {
    return Recital;
  }

  private _buildRecitalsForm(): FormGroup {
    const formStruct: Record<Recital, AbstractControl> = {
      [Recital.Hafs]: new FormControl(this._isEnabledByDefault(Recital.Hafs)),
      [Recital.Qanoun]: new FormControl(
        this._isEnabledByDefault(Recital.Qanoun),
      ),
      [Recital.Warsh]: new FormControl(this._isEnabledByDefault(Recital.Warsh)),
    };

    return new FormGroup(formStruct);
  }

  private _selectedRecitals(): Recital[] {
    const formValue = this.selectedRecitals.value;
    return Object.values(Recital).filter((recital) => {
      return formValue[recital];
    });
  }

  private _isEnabledByDefault(recital: Recital): boolean {
    return (
      this.subscription.pricings.find((pricing) => pricing.recital === recital)
        ?.enabled_by_default ?? false
    );
  }

  private _buildCanSubmitStream(): Observable<boolean> {
    return this.selectedRecitals.valueChanges.pipe(
      startWith(this.selectedRecitals.value),
      map((_) => this._selectedRecitals().length > 0),
    );
  }
}

export interface AddUserSubscriptionCardGiftEvent {
  recitals: Recital[];
}
