export class Entity<T> {
  private _data: T;
  private _error: any;
  private _is_loading: boolean;
  private _is_error: boolean;

  constructor(initial?: T) {
    this._data = initial;
  }

  public load(data: T) {
    this._data = data;
    this._is_loading = false;
    this._is_error = false;
  }
  public wait() {
    this._is_loading = true;
    this._is_error = false;
    this._error = false;
  }
  public error(error: any) {
    this._error = error;
    this._is_loading = false;
  }

  public get Data() {
    return this._data;
  }
  public get Error() {
    return this._error;
  }
  public get IsLoading() {
    return this._is_loading;
  }
  public get HasError() {
    return this._is_error;
  }
  public get HasData() {
    return this._data == null ? false : true;
  }
}
