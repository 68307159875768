<nb-card>
  <nb-card-header
    style="
      display: flex;
      align-items: center;
      padding-top: 0;
      padding-bottom: 0;
    "
  >
    Users
    <nb-card-body>
      <div class="buttons-row">
        <button nbButton [shape]="'rectangle'" (click)="downloadExcel()">
          export excel
        </button>
      </div>
    </nb-card-body>
    <button
      *ngIf="canAddUser"
      style="margin-inline-start: auto"
      status="success"
      nbButton
      ghost
      size="giant"
      (click)="onAddUser()"
    >
      <nb-icon [options]="{ animation: { type: 'shake' } }" icon="plus-circle">
      </nb-icon>
    </button>
  </nb-card-header>

  <nb-card-body>
    <div class="d-flex align-items-center gap-2rem" style="padding: 1rem">
      <div class="section">
        <h5>From:</h5>
        <input
          nbInput
          [(ngModel)]="startDate"
          placeholder="Start Date"
          [nbDatepicker]="formpicker"
          (ngModelChange)="updatePagination()"
        />
        <nb-datepicker #formpicker></nb-datepicker>
      </div>
      <div class="section">
        <h5>To:</h5>
        <input
          [(ngModel)]="endDate"
          nbInput
          placeholder="End Date"
          [nbDatepicker]="picker"
          (ngModelChange)="updatePagination()"
        />
        <nb-datepicker #picker></nb-datepicker>
      </div>
    </div>

    <ngx-chart-panel-summary
      [summary]="chartPanelSummary | async"
    ></ngx-chart-panel-summary>

    <!--! TABLE -->
    <fourqan-users-prime-table
      [countries]="countries | async"
      [usersTotalCount]="totalCount | async"
      [data]="users | async"
      [loading]="loading | async"
      (pageEvent)="changePage($event)"
      (editEvent)="onEdit($event)"
      (nameEvent)="searchForString($event)"
      (roleEvent)="searchRole($event)"
      (countryEvent)="searchForCountryId($event)"
    ></fourqan-users-prime-table>
  </nb-card-body>
</nb-card>
