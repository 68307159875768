import { PageMetaFile__TypedPointInTime } from 'core/page-meta-file-resolver';
import { Properties } from 'utils/properties.type';
import { ReadingType } from './reading';

export class Rollback {
  /**
   * @description in seconds
   */
  [ReadingType.NormalReadingWithOutSpelling]?: number;
  /**
   * @description in seconds
   */
  [ReadingType.NormalReadingWithSpelling]?: number;

  constructor(props: Properties<Rollback>) {
    Object.assign(this, props);
  }

  toJson(): PageMetaFile__TypedPointInTime {
    return {
      [ReadingType.NormalReadingWithSpelling]:
        this['normal-reading-with-spelling'] * 1_000_000,
      [ReadingType.NormalReadingWithOutSpelling]:
        this['normal-reading-without-spelling'] * 1_000_000,
    };
  }

  static fromJson(json: PageMetaFile__TypedPointInTime): Rollback {
    return new Rollback({
      [ReadingType.NormalReadingWithSpelling]:
        json['normal-reading-with-spelling'] / 1_000_000,
      [ReadingType.NormalReadingWithOutSpelling]:
        json['normal-reading-without-spelling'] / 1_000_000,
    });
  }
}
