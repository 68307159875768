import { Component, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { Subscription } from 'models/subscription';
import { AddUserSubscriptionCardGiftEvent } from './add-user-subscription-card/add-user-subscription-card.component';
import { AddUserSubscriptionDialogResult } from './add-user-subscription-dialog.types';

@Component({
  selector: 'ngx-add-user-subscription-dialog',
  templateUrl: './add-user-subscription-dialog.component.html',
  styleUrls: ['./add-user-subscription-dialog.component.scss'],
})
export class AddUserSubscriptionDialogComponent {
  @Input()
  public options: Subscription[];

  constructor(private ref: NbDialogRef<AddUserSubscriptionDialogComponent>) {}

  public onCancel(): void {
    this.ref.close({
      canceled: true,
      selected: null,
    } as AddUserSubscriptionDialogResult);
  }

  onSubmit(
    subscription: Subscription,
    event: AddUserSubscriptionCardGiftEvent,
  ) {
    this.ref.close({
      canceled: false,
      recitals: event.recitals,
      selected: subscription,
    } as AddUserSubscriptionDialogResult);
  }
}
