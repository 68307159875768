<nb-card>
    <nb-card-header>
      <div style="display: flex; flex-flow: row nowrap">
        <span>Logins</span>
      </div>
    </nb-card-header>
    <nb-card-body>
      <ng2-smart-table
        [settings]="settings"
        (deleteConfirm)="onDeleteToken($event)"
        [source]="source"
      ></ng2-smart-table>
    </nb-card-body>
  </nb-card>
  