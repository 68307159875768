import { Injectable } from '@angular/core';
import { decode, encode } from 'utils';

@Injectable({ providedIn: 'root' })
export class LocalStorage {
  set(key: string, value: string) {
    const encoded_key = encode(key);
    const encoded_value = encode(value);
    localStorage.setItem(encoded_key, encoded_value);
  }
  get(key: string): string {
    const encoded_key = encode(key);
    const encoded_value = localStorage.getItem(encoded_key);
    if (encoded_value) return decode(encoded_value);
    else return null;
  }
}
