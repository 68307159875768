import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {
  EmailPasswordFormError,
  EmailPasswordFormValue,
} from '../../components/email-password-login-form/email-password-login-form.component';
import { NgxAuthService } from '../../services/ngx-auth.service';

@Component({
  selector: 'ngx-login-container',
  templateUrl: './login-container.component.html',
  styleUrls: ['./login-container.component.scss'],
})
export class LoginContainerComponent implements OnInit {
  email_password_control = new FormControl(null, Validators.required);

  alerts: Set<string> = new Set([]);

  constructor(
    private ngx_auth_service: NgxAuthService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.email_password_control.statusChanges.subscribe(() => {});
  }

  onLogin() {
    if (this.email_password_control.invalid)
      return this.alertFormErrors(
        this.email_password_control.errors as EmailPasswordFormError,
      );
    const { email, password, remember } = this.FormValue;
    this.ngx_auth_service.login(email, password, remember).then(() => {
      this.router.navigate(['']);
    });
  }

  private get FormValue(): EmailPasswordFormValue {
    return this.email_password_control.value;
  }
  private alertFormErrors(errors: EmailPasswordFormError) {
    if (!errors) return;
    const messages: string[] = [];
    if (errors.email?.required) messages.push('AUTH.REQUIRED EMAIL ERROR');
    else if (errors.email?.pattern)
      messages.push('AUTH.INVALID EMAIL PATTERN ERROR');
    if (errors.password?.required)
      messages.push('AUTH.REQUIRED PASSWORD ERROR');
    messages.forEach(this.showAlert.bind(this));
  }
  private showAlert(message: string) {
    this.alerts.add(message);
    setTimeout(() => {
      this.alerts.delete(message);
    }, 5000);
  }
}
