<nb-card [nbSpinner]="loading">
  <nb-card-header>Add a new user</nb-card-header>

  <nb-card-body>
    <form [formGroup]="form">
      <div class="fc">
        <label style="display: block">Username:</label>
        <input
          fullWidth
          formControlName="username"
          placeholder="username"
          nbInput
        />
      </div>
      <div class="creds">
        <div class="fc">
          <label style="display: block">Email:</label>
          <input
            fullWidth
            formControlName="email"
            placeholder="email"
            nbInput
          />
        </div>
        <div class="fc">
          <label style="display: block">Password:</label>
          <input
            fullWidth
            formControlName="password"
            placeholder="password"
            nbInput
          />
        </div>
      </div>
      <div class="extra">
        <div class="fc">
          <label style="display: block">Role:</label>
          <nb-select fullWidth placeholder="" formControlName="role">
            <nb-option
              *ngFor="let option of rolesOptions"
              [value]="option[1]"
              >{{ option[0] }}</nb-option
            >
          </nb-select>
        </div>
        <div class="fc">
          <label style="display: block">Gender:</label>
          <nb-select fullWidth placeholder="" formControlName="gender">
            <nb-option [value]="Gender.Female">Female</nb-option>
            <nb-option [value]="Gender.Male">Male</nb-option>
          </nb-select>
        </div>
      </div>
    </form>
  </nb-card-body>

  <nb-card-footer>
    <button (click)="addUser()" class="action" nbButton ghost status="info">
      Save
      <nb-icon pack="eva" icon="checkmark-outline"></nb-icon>
    </button>
    <button (click)="cancel()" class="action" nbButton ghost status="danger">
      Cancel
      <nb-icon pack="eva" icon="slash-outline"></nb-icon>
    </button>
  </nb-card-footer>
</nb-card>
