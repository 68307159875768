import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  OnDestroy,
} from '@angular/core';
import { Circle, Element, Rect, SVG } from '@svgdotjs/svg.js';
import { Howl } from 'howler';
import { BehaviorSubject, interval, merge, Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { NgxHandler } from './handler';

@Directive({
  selector: '[ngxTrackEditor]',
})
export class TrackEditorDirective implements AfterViewInit, OnDestroy {
  @Input('howl')
  public set howl(v: Howl) {
    this.disposeHowl();
    this._howl = v;
    this.setupHowl();
  }
  public get howl(): Howl {
    return this._howl;
  }
  private _howl: Howl;

  public svg: Element;

  public track: Rect;
  public progress: Rect;
  public section: Rect;
  public begin_handler: NgxHandler;
  public end_handler: NgxHandler;

  private $state = new BehaviorSubject<HowlState>('stopped');

  private $update = new Subject<void>();
  private $seek = new Subject<SeekEvent>();

  private $selection = new Subject<Selection>();
  private $selection_resize = new Subject<Selection>();

  private $destory = new Subject();

  constructor(private _element_ref: ElementRef<SVGElement>) {
    this.svg = SVG(_element_ref.nativeElement);
    this.onSeek().subscribe(() => {
      this.resizeProgress();
    });
    this.onUpdate().subscribe(() => {
      this.resizeProgress();
      this.assertSectionBoundry();
    });
  }

  ngAfterViewInit() {
    this.findHandlers();
  }

  ngOnDestroy() {
    this.$destory.next();
    this.$destory.complete();
  }

  public onUpdate(): Observable<void> {
    return this.$update.asObservable().pipe(takeUntil(this.$destory));
  }
  public onSeek(): Observable<SeekEvent> {
    return this.$seek.asObservable().pipe(takeUntil(this.$destory));
  }
  public onSelection(): Observable<Selection> {
    return this.$selection.asObservable().pipe(takeUntil(this.$destory));
  }
  public onSelectionResize(): Observable<Selection> {
    return this.$selection_resize.asObservable().pipe(takeUntil(this.$destory));
  }
  public select(from: number, to: number) {
    const was_playing = this.howl.playing();
    if (was_playing) this.howl.pause();

    const fpercent = from / this.howl.duration();
    const tpercent = to / this.howl.duration();

    this.begin_handler.setMaxPosition(tpercent * 100);
    this.end_handler.setMinPosition(fpercent * 100);

    this.end_handler.pick({ emit_event: false });
    this.begin_handler.pick({ emit_event: false });

    this.end_handler.move(tpercent * 100, { emit_event: false });
    this.begin_handler.move(fpercent * 100, { emit_event: false });

    this.end_handler.release({ emit_event: false });
    this.begin_handler.release({ emit_event: false });

    this.resizeSection();
    if (was_playing) this.howl.play();
  }

  public fix(selection: { from: number; to: number }): {
    from: number;
    to: number;
  } {
    const { from, to } = selection;
    const pfrom = (from / this.howl.duration()) * 100;
    const pto = (to / this.howl.duration()) * 100;
    return {
      from: (this.begin_handler.minmax(pfrom) / 100) * this.howl.duration(),
      to: (this.end_handler.minmax(pto) / 100) * this.howl.duration(),
    };
  }
  private findHandlers() {
    this.track = this.svg.findOne('#ngx-track') as Rect;
    this.section = this.svg.findOne('#ngx-section') as Rect;
    this.progress = this.svg.findOne('#ngx-progress') as Rect;

    this.begin_handler = new NgxHandler(
      this.svg.findOne('#ngx-begin-handler') as Circle,
      this.track,
      0,
    );

    this.end_handler = new NgxHandler(
      this.svg.findOne('#ngx-end-handler') as Circle,
      this.track,
      100,
    );
    this.resetHandlers();
  }
  private resetHandlers() {
    this.track.width('100%');
    this.section.width('100%');
    this.progress.width(0);

    this.begin_handler.element.cx(this.begin_handler.element.attr('r'));
    this.begin_handler.element.cy(this.begin_handler.element.attr('r'));
    this.begin_handler.setMaxPosition(100);
    this.regsiterBeginHandler();

    this.end_handler.element.cx(
      this.track.bbox().width - this.end_handler.element.attr('r'),
    );
    this.end_handler.element.cy(this.end_handler.element.attr('r'));
    this.end_handler.setMinPosition(0);
    this.regsiterEndHandler();
  }
  private setupHowl() {
    this.howl.on('play', (id) => {
      this.$state.next('playing');
      this.update(0);
    });
    this.howl.on('stop', (id) => {
      this.$state.next('stopped');
    });
    this.howl.on('pause', (id) => {
      this.$state.next('paused');
    });
    this.howl.on('seek', (id) => {
      this.$seek.next({
        to: this.howl.seek() as number,
      });
    });
  }
  private disposeHowl() {
    if (!this.howl) return;
    this.howl.off('play');
    this.howl.off('stop');
    this.howl.off('pause');
    this.howl.off('seek');
  }

  public onStateChange() {
    return this.$state.asObservable();
  }
  private resizeProgress() {
    const progress =
      ((this.howl.seek() as number) / this.howl.duration()) * 100;
    this.progress.attr('width', progress + '%');
  }
  private assertSectionBoundry() {
    const max = this.end_handler.Position() / 100;
    const current = (this.howl.seek() as number) / this.howl.duration();
    if (current >= max) this.howl.stop();
  }
  private update(rate: number) {
    interval(rate)
      .pipe(
        takeUntil(
          merge(
            this.$state.pipe(
              filter((state) => ['stopped', 'paused'].includes(state)),
            ),
            this.$destory,
          ),
        ),
      )
      .subscribe(() => this.$update.next());
  }
  private resizeSection() {
    const begin = this.begin_handler.Position();
    const end = this.end_handler.Position();
    let width = end - begin;
    if (width <= 0) {
      width = 0;
    } else if (width >= 100) {
      width = 100;
    }
    this.section.width(width + '%');
    this.section.x(begin + '%');
    this.seek(begin);
    this.$selection_resize.next({ begin, end });
  }
  private regsiterEndHandler() {
    this.end_handler
      .onPick()
      .pipe(takeUntil(this.$destory))
      .subscribe(() => {
        this.howl.pause();
      });
    this.end_handler
      .onMove()
      .pipe(takeUntil(this.$destory))
      .subscribe(({ position }) => {
        this.begin_handler.setMaxPosition(position);
        const begin = this.begin_handler.Position();
        this.howl.seek((begin / 100) * this.howl.duration());
        this.resizeSection();
      });
    this.end_handler
      .onRelease()
      .pipe(takeUntil(this.$destory))
      .subscribe(({ position }) => {
        const begin = this.begin_handler.Position();
        const end = this.end_handler.Position();
        this.$selection.next({ begin, end });
      });
  }
  private regsiterBeginHandler() {
    this.begin_handler
      .onPick()
      .pipe(takeUntil(this.$destory))
      .subscribe(() => {
        this.howl.pause();
      });
    this.begin_handler
      .onMove()
      .pipe(takeUntil(this.$destory))
      .subscribe(({ position }) => {
        this.end_handler.setMinPosition(position);
        this.resizeSection();
      });
    this.begin_handler
      .onRelease()
      .pipe(takeUntil(this.$destory))
      .subscribe(({ position }) => {
        const begin = this.begin_handler.Position();
        const end = this.end_handler.Position();
        this.$selection.next({ begin, end });
      });
  }
  private seek(precent: number) {
    const seek = (precent / 100) * this.howl.duration();
    this.howl.seek(seek);
  }
}

/**
 * @description all values are between (0, 100)
 */
interface Selection {
  begin: number;
  end: number;
}
interface SeekEvent {
  to: number;
}

export type HowlState = 'playing' | 'paused' | 'stopped';
