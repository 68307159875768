import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ChartOptions, CompareColumnChartData } from 'types/chart-types';

@Component({
  selector: 'fourqan-compare-column-chart',
  templateUrl: './compare-column-chart.component.html',
  styleUrls: ['./compare-column-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompareColumnChartComponent implements OnInit, OnChanges {
  @Input() compareColumnData!: Array<CompareColumnChartData>;
  @Input() labelsList!: Array<string>;
  @Input() height!: number;
  @Input() loading: boolean = true;
  @Input() legendOn: boolean = true;
  @Input() legendPosition: 'right' | 'left' | 'top' | 'bottom' = 'right';
  @Input() colors: Array<string> = [
    'var(--primary)',
    'var(--secondary)',
    'var(--lime)',
    'var(--yellow)',
    'var(--red)',
    'var(--cyan)',
    'var(--female-pink)',
    'var(--inpatient)',
    'var(--deep-dark-blue)',
  ];

  chartOptions!: ChartOptions;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['compareColumnData'] && this.chartOptions) {
      this.chartOptions.series = this.compareColumnData;
    }

    if (changes['labelsList'] && this.chartOptions) {
      this.chartOptions.xAxis = {
        categories: this.labelsList,
      };
    }
  }

  ngOnInit(): void {
    this.chartOptions = {
      series: this.compareColumnData,
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      xAxis: {
        categories: this.labelsList,
      },
      legend: {
        show: this.legendOn,
        position: this.legendPosition,
      },
      fill: {
        opacity: 1,
      },
    };
  }
}
