import { Inject, Injectable, isDevMode, Optional } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormGroup,
  ValidationErrors,
} from '@angular/forms';
import { VALIDATION_NOTIFIER_HANDLERS } from 'app/shared/forms-error-notifier/handlers.inject';
import { Handler, HandlerContext } from './forms-error-notifier.interface';

@Injectable({
  providedIn: 'root',
})
export class FormsErrorNotifierService {
  constructor(
    @Optional()
    @Inject(VALIDATION_NOTIFIER_HANDLERS)
    private _handlers: Handler[] = [],
  ) {}

  public addHandlers(...handlers: Handler[]) {
    this._handlers.push(...handlers);
  }

  public validateAndNotifyFormGroup(form: FormGroup): void {
    Object.keys(form.controls).forEach((field) => {
      const control = form.controls[field];
      this.validateAndNotifyField(control, field);
    });
  }
  public validateAndNotifyFormArray(form: FormArray, field: string): void {
    form.controls.forEach((control) => {
      this.validateAndNotifyField(control, field);
    });
  }
  public validateAndNotifyField(control: AbstractControl, field: string) {
    if (isDevMode()) {
      console.log({ control, field });
    }
    if (control.errors) {
      this._handleErrors(field, control, control.errors);
    }
    if (control instanceof FormGroup) {
      this.validateAndNotifyFormGroup(control);
    } else if (control instanceof FormArray) {
      this.validateAndNotifyFormArray(control, field);
    }
  }

  private _handleErrors(
    field: string,
    control: AbstractControl,
    errors: ValidationErrors,
  ) {
    const keys = Object.keys(errors);
    keys.forEach((key) =>
      this._chain(
        {
          control,
          field,
          errorKey: key,
        },
        this._handlers,
      ),
    );
  }

  private _chain(ctx: HandlerContext, handlers: Handler[]) {
    return handlers.some((handler) => {
      if (handler.selector(ctx)) {
        handler.executer(ctx);
        return true;
      }
      return false;
    });
  }
}
