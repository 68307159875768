export class Payment {
  id: number;

  externalId: string;

  reference: string;

  constructor(spec: Payment) {
    Object.assign(this, spec);
  }
}
