
export class UserToken {
  id: number;
  user_id: number;
  granted_subscription: boolean;
  created_at: Date;
  deleted_at: Date;
  updated_at: Date;
  public static fromDTO(dto: UserTokenDTO[]): UserToken[];
  public static fromDTO(dto: UserTokenDTO): UserToken;
  public static fromDTO(
    dto: UserTokenDTO | UserTokenDTO[],
  ): UserToken | UserToken[] {
    if (Array.isArray(dto)) return dto.map((one) => this.fromDTO(one));

    const user_token = new UserToken();
    user_token.id = dto.id;
    user_token.user_id = dto.userId;
    user_token.created_at =dto.createdAt? new Date(dto.createdAt):undefined;
    user_token.deleted_at = dto.deletedAt?new Date(dto.deletedAt):undefined;
    user_token.updated_at = dto.updatedAt?new Date(dto.updatedAt):undefined;
    user_token.granted_subscription = dto.grantedSubscription;
   
    return user_token;
  }

  public static toDTO(user_token: UserToken[]): UserTokenDTO[];
  public static toDTO(user_token: UserToken): UserTokenDTO;
  public static toDTO(
    user_token: UserToken | UserToken[],
  ): UserTokenDTO | UserTokenDTO[] {
    if (Array.isArray(user_token))
      return user_token.map((sub) => this.toDTO(sub));
    else
      return {
        id: user_token.id,
        userId: user_token.user_id,
        grantedSubscription: user_token.granted_subscription,
        createdAt: user_token.created_at.toISOString(),
        deletedAt: user_token.deleted_at.toISOString(),
        updatedAt: user_token.updated_at.toISOString(),
      };
  }
}

export interface UserTokenDTO {
  id: number;
  createdAt: string;
  deletedAt: string;
  grantedSubscription: boolean;
  updatedAt: string;
  userId: number;
}
