import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-make-word-form-dialog',
  templateUrl: './make-word-form-dialog.component.html',
  styleUrls: ['./make-word-form-dialog.component.scss'],
})
export class MakeWordFormDialogComponent implements OnInit {
  control = new FormControl('');

  constructor(private dialog_ref: NbDialogRef<MakeWordFormDialogComponent>) {}

  ngOnInit(): void {}

  onSubmit() {
    this.dialog_ref.close({ canceled: false, text: this.control.value });
  }
  onCancel() {
    this.dialog_ref.close({ canceled: true });
  }
}

// export interface MakeWordFormDialogData {}
export interface MakeWordFormDialogResult {
  text: string;
  canceled: boolean;
}
