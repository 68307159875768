import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { gt } from 'validators';
import { SubscriptionsService } from 'app/services/subscriptions.service';
import { FormsErrorNotifierService } from 'app/shared/forms-error-notifier/forms-error-notifier.service';
import { VoucherFacade } from 'core/facade/voucher-facade.service';
import { VoucherDTO } from 'core/gateways/voucher/voucher-dto';
import { differenceInMonths, differenceInYears } from 'date-fns';
import { Recital } from 'models/page';
import { Subscription } from 'models/subscription';
import { Voucher } from 'models/vouchers/voucher';
import { VoucherType } from 'models/vouchers/voucher-type';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { VoucherEditDialogErrorsToasterService } from './error-toaster.service';
import { VoucherEditDialogErrorsService } from './errors-handlers.service';

@Component({
  selector: 'ngx-voucher-edit-dialog',
  templateUrl: './voucher-edit-dialog.component.html',
  styleUrls: ['./voucher-edit-dialog.component.scss'],
  providers: [
    FormsErrorNotifierService,
    VoucherEditDialogErrorsService,
    VoucherEditDialogErrorsToasterService,
  ],
})
export class VoucherEditDialogComponent implements OnInit {
  form: FormGroup;

  voucher: Voucher;

  mode: 'edit' | 'add' = 'add';

  subscriptions_auto_ctrl = new FormControl();
  subscriptions_options$: Observable<Subscription[]>;
  private _subscriptions: Subscription[] = [];

  loading$: Observable<boolean>;
  private _loadingSource = new Subject<boolean>();

  constructor(
    private _ref: NbDialogRef<any>,
    private subscriptions_facade: SubscriptionsService,
    private _voucher_facade: VoucherFacade,
    private _formsErrorNotifierService: FormsErrorNotifierService,
    errorsService: VoucherEditDialogErrorsService,
  ) {
    errorsService.addHandlers();
    this.form = this._buildForm();
    this.subscriptions_options$ = this._buildSubscriptionOptionsStream();
    this.loading$ = this._buildLoadingStream();
  }
  ngOnInit(): void {
    if (this.voucher) {
      this._patch(this.voucher);
      this._disableEditControls();
      this.mode = 'edit';
    }
  }

  close() {
    this._ref.close();
  }
  submit() {
    if (this.form.invalid) {
      this._showErrors();
    } else if (this.mode === 'add') {
      this._addVoucher();
    } else if (this.mode === 'edit') {
      this._editVoucher();
    }
  }

  addSubscription(title: string) {
    const subscription = this.UnselectedSubscriptions.find(
      (_subscription) => _subscription.name === title,
    );
    if (!subscription) {
      return;
    }
    const selected = this.SelectedSubscriptions.concat(subscription);
    this.form.get('subscriptions').setValue(selected);
    this.subscriptions_auto_ctrl.setValue('');
  }
  removeSubscription(title: string) {
    const selected = this.SelectedSubscriptions.concat();
    const index = selected.findIndex(
      (subscription) => subscription.name === title,
    );
    if (index === -1) {
      return;
    }
    selected.splice(index, 1);
    this.form.get('subscriptions').setValue(selected);
  }

  get SelectedSubscriptions(): Subscription[] {
    return this.form.get('subscriptions').value;
  }

  get UnselectedSubscriptions(): Subscription[] {
    const selected_ids = this.SelectedSubscriptions.map(({ id }) => id);
    return this._subscriptions.filter(({ id }) => !selected_ids.includes(id));
  }
  get $VoucherType(): typeof VoucherType {
    return VoucherType;
  }
  get RecitalsDiscountsFormArray(): FormArray {
    return this.form.get('recitals_discounts') as FormArray;
  }
  get DiscountsFormArray(): FormArray {
    return this.form.get('discounts') as FormArray;
  }
  get IsCodeValidationPending() {
    return this.form.get('code').pending;
  }
  get IsCodeValid(): boolean {
    return this.form.get('code').valid;
  }
  get IsCodeInvalid(): boolean {
    return (
      this.form.get('code').invalid && this.form.get('code').hasError('code')
    );
  }

  /**
   *
   * @returns base proprties of dto body that are shared between add and edit opertations
   */
  private _getBaseDTO(): VoucherDTO.BaseEditableBody {
    const fv = this.form.value as FormValue;
    return {
      code: fv.code,
      deactivated: fv.deactivated,
      description: fv.description,
      endDate: new Date(
        fv.start_date.getFullYear() + fv.valid_for.year,
        fv.start_date.getMonth() + fv.valid_for.month,
        fv.start_date.getDate(),
      ).toISOString(),
      maxNumberOfUsage: fv.max_usages,
      maxNumberOfUsagePerUser: fv.max_usages_per_user,
      startDate: fv.start_date.toISOString(),
      title: fv.title,
    };
  }
  private _getEditDTO(): VoucherDTO.UpdateBody {
    return this._getBaseDTO();
  }
  private _getaddDTO(): VoucherDTO.CreateBody {
    const fv = this.form.value as FormValue;
    return {
      ...this._getBaseDTO(),
      discounts: fv.discounts.map(({ discount, count, type }) => ({
        count,
        discount: type === VoucherType.Percentage ? discount / 100 : discount,
        type,
      })),
      recitalsDiscounts: fv.recitals_discounts.map(
        ({ recital, type, discount }) => ({
          discount: type === VoucherType.Percentage ? discount / 100 : discount,
          type,
          recital,
        }),
      ),
      subscriptionsIds: fv.subscriptions.map((subscription) => subscription.id),
    };
  }

  private _buildForm(): FormGroup {
    return new FormGroup({
      title: new FormControl('', [Validators.required]),
      code: new FormControl(
        '',
        [Validators.required],
        [this._codeValidator.bind(this)],
      ),
      max_usages: new FormControl('', [Validators.required]),
      max_usages_per_user: new FormControl('', [Validators.required]),
      start_date: new FormControl('', [Validators.required]),
      valid_for: new FormGroup(
        {
          year: new FormControl('', [Validators.required]),
          month: new FormControl('', [Validators.required]),
        },
        [Validators.required],
      ),
      subscriptions: new FormControl([]),
      description: new FormControl('', [Validators.required]),
      discounts: new FormArray([
        new FormGroup(
          {
            type: new FormControl(VoucherType.Fixed, [Validators.required]),
            discount: new FormControl(0, [Validators.required, gt(0)]),
            count: new FormControl(2, [Validators.required]),
          },
          [this._discountValidator],
        ),
        new FormGroup(
          {
            type: new FormControl(VoucherType.Fixed, [Validators.required]),
            discount: new FormControl(0, [Validators.required, gt(0)]),
            count: new FormControl(3, [Validators.required]),
          },
          [this._discountValidator],
        ),
      ]),
      recitals_discounts: new FormArray(
        Object.values(Recital).map(
          (recital) =>
            new FormGroup(
              {
                recital: new FormControl(recital),
                type: new FormControl(VoucherType.Fixed, [Validators.required]),
                discount: new FormControl(0, [Validators.required, gt(0)]),
              },
              [this._discountValidator],
            ),
        ),
      ),
      deactivated: new FormControl(false),
    });
  }
  private _getSubscriptionFilterFor(search: string) {
    return (subscription: Subscription) => {
      return subscription.name.toLowerCase().includes(search.toLowerCase());
    };
  }

  private _discountValidator(control: AbstractControl): ValidationErrors {
    const type = control.value.type;
    const discount = control.value.discount;
    if (type === VoucherType.Fixed) return {};
    else if (discount > 100 || discount < 0) return { discount: true };
    return {};
  }

  private _codeValidator(
    control: AbstractControl,
  ): Observable<ValidationErrors | undefined> {
    if (!control.value || (this.voucher && this.voucher.code === control.value))
      return of(undefined);
    return this._voucher_facade
      .exists(control.value)
      .pipe(map((exists) => (exists ? { code: true } : undefined)));
  }

  private _showErrors() {
    this._formsErrorNotifierService.validateAndNotifyFormGroup(this.form);
  }

  private _disableEditControls() {
    this.form.get('subscriptions').disable();
    (this.form.get('discounts') as FormArray).disable();
    (this.form.get('recitals_discounts') as FormArray).disable();
    this.subscriptions_auto_ctrl.disable();
  }
  private _patch(voucher: Voucher) {
    this.form.patchValue({
      code: voucher.code,
      deactivated: voucher.deactivated,
      description: voucher.description,
      discounts: voucher.discounts.map(({ discount, count, type }) => ({
        type: type,
        discount: type === VoucherType.Percentage ? discount * 100 : discount,
        count: count,
      })),
      max_usages: voucher.max_number_of_usages,
      max_usages_per_user: voucher.max_number_of_usages_per_user,
      recitals_discounts: voucher.recitals_discounts.map(
        ({ discount, type, recital }) => ({
          discount: type === VoucherType.Percentage ? discount * 100 : discount,
          recital: recital,
          type: type,
        }),
      ),
      start_date: voucher.start_date,
      subscriptions: voucher.subscriptions,
      title: voucher.title,
      valid_for: {
        month: differenceInMonths(voucher.end_date, voucher.start_date) % 12,
        year: differenceInYears(voucher.end_date, voucher.start_date),
      },
    } as FormValue);
  }
  private _buildSubscriptionOptionsStream(): Observable<Subscription[]> {
    return combineLatest([
      this.subscriptions_facade
        .list()
        .pipe(tap((subscriptions) => (this._subscriptions = subscriptions))),
      this.subscriptions_auto_ctrl.valueChanges,
    ]).pipe(
      map(([_, search]) => {
        return this.UnselectedSubscriptions.filter(
          this._getSubscriptionFilterFor(search),
        );
      }),
    );
  }
  private _buildLoadingStream(): Observable<boolean> {
    return this._loadingSource.asObservable();
  }

  private _editVoucher(): void {
    this._loadingSource.next(true);
    this._voucher_facade.edit(this.voucher.id, this._getEditDTO()).subscribe({
      next: () => {
        this._ref.close({
          refresh: true,
        });
      },
      error: () => {
        this._loadingSource.next(false);
      },
    });
  }
  private _addVoucher(): void {
    this._loadingSource.next(true);
    this._voucher_facade.create(this._getaddDTO()).subscribe({
      next: () => {
        this._ref.close({
          refresh: true,
        });
      },
      error: () => {
        this._loadingSource.next(false);
      },
    });
  }
}

interface FormValue {
  title: string;
  code: string;
  max_usages: number;
  max_usages_per_user: number;
  start_date: Date;
  valid_for: {
    year: number;
    month: number;
  };
  subscriptions: Subscription[];
  description: string;
  discounts: {
    count: number;
    type: VoucherType;
    discount: number;
  }[];
  recitals_discounts: {
    recital: Recital;
    type: VoucherType;
    discount: number;
  }[];
  deactivated: boolean;
}

export interface VoucherEditDialogResult {
  /**
   * @description used to indicate the container to refresh the vouchers list
   */
  refresh: boolean;
}
