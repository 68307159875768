<nb-card>
    <nb-card-header>
        <nb-card-body class="head">
            <div class="left">Subscriptions</div>
            <div class="right">
              <button  nbButton [shape]="'rectangle'" (click)="downloadExcel()">
                export excel
              </button>
            </div>
          </nb-card-body>
    </nb-card-header>
</nb-card>
<div [nbSpinner]="subscriptions.IsLoading" style="display: flex; flex-flow: row wrap; justify-content: center;">
    <button style="position: fixed; bottom: 5rem; right: 2.5rem;" status="success" nbButton ghost size="giant"
        (click)="onAddNew()">
        <nb-icon [options]="{ animation: { type: 'shake' } }" icon='plus-circle'>
        </nb-icon>
    </button>
    <ngx-subscription-card (onEdit)="onCommitEdit(subscription, $event)"
        *ngFor="let subscription of this.subscriptions.Data" [subscription]="subscription"
        style="flex: 1; margin: 0 .5rem; min-width: 20rem; max-width: 30rem;">

        <button (click)="onStartEdit(subscription)" nbButton ghost status="info">
            <nb-icon icon="edit"></nb-icon>
        </button>

    </ngx-subscription-card>
</div>
