import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Country } from 'models/country';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CountryAdapter } from './country-adapter';
import { CountryDTO } from './country-dto';

@Injectable({
  providedIn: 'root',
})
export class CountryGateway {
  private _endpoint = `${environment.server}/country`;

  constructor(private _http: HttpClient) {}

  list(): Observable<Country[]> {
    return this._http
      .get<CountryDTO.Base[]>(this._endpoint)
      .pipe(map((dto) => CountryAdapter.countries(dto)));
  }
}
