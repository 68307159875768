import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { CountryFacade } from 'core/facade/country-facade.service';
import { Country } from 'models/country';
import { Recital } from 'models/page';
import { Subscription } from 'models/subscription';
import { SubscriptionPricing } from 'models/subscription-pricing';
import { DiscountType } from 'models/subscription-pricing-discount';
import { combineLatest, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { SubscriptionFormValue } from '../../dialogs/subscription-edit-dialog/subscription-edit-dialog.component';
@Component({
  selector: 'ngx-subscription-card',
  templateUrl: './subscription-card.component.html',
  styleUrls: ['./subscription-card.component.scss'],
})
export class SubscriptionCardComponent implements OnInit {
  @Input()
  public set subscription(v: Subscription) {
    this._subscription = v;
    this._patchForm();
    this._updateMode();
  }
  public get subscription() {
    return this._subscription;
  }
  private _subscription: Subscription;

  @Output('onDelete') delete_event = new EventEmitter();
  @Output('onDiscard') discard_event = new EventEmitter();
  @Output('onEdit') edit_event = new EventEmitter<SubscriptionFormValue>();

  mode: 'edit' | 'display';

  form = new FormGroup({
    name: new FormControl(),
    descriptionEn: new FormControl(),
    descriptionAr: new FormControl(),
    descriptionFr: new FormControl(),
    duration: new FormGroup({
      years: new FormControl(),
      months: new FormControl(),
      days: new FormControl(),
      hours: new FormControl(),
    }),
    allowance: new FormGroup({
      years: new FormControl(),
      months: new FormControl(),
      days: new FormControl(),
      hours: new FormControl(),
    }),
    countries: new FormControl([]),
    login_times: new FormControl(),
    max_active_tokens: new FormControl(),
    [Recital.Hafs]: new FormGroup({
      default: new FormControl(false),
      price: new FormControl(0),
    }),
    [Recital.Qanoun]: new FormGroup({
      default: new FormControl(false),
      price: new FormControl(0),
    }),
    [Recital.Warsh]: new FormGroup({
      default: new FormControl(false),
      price: new FormControl(0),
    }),
    discounts: new FormArray([
      new FormGroup({
        count: new FormControl(2),
        type: new FormControl(DiscountType.Fixed),
        discount: new FormControl(0),
      }),
      new FormGroup({
        count: new FormControl(3),
        type: new FormControl(DiscountType.Fixed),
        discount: new FormControl(0),
      }),
    ]),
    deactivated: new FormControl(false),
  });

  countries_auto_ctrl = new FormControl();

  countries_options$: Observable<Country[]>;

  private _countries: Country[] = [];

  constructor(private country_facade: CountryFacade) {
    this.countries_options$ = combineLatest([
      this.country_facade
        .list()
        .pipe(tap((countries) => (this._countries = countries))),
      this.countries_auto_ctrl.valueChanges,
    ]).pipe(
      map(([countries, search]) => {
        return this.UnselectedCountries.filter(
          this._getCountryFilterFor(search),
        );
      }),
    );
  }

  ngOnInit(): void {
    this.mode = this.subscription != null ? 'display' : 'edit';
  }

  onStartEdit() {
    this.mode = 'edit';
  }
  onDelete() {
    this.delete();
  }
  onConfirmEdit() {
    this.edit();
  }
  onCancelEdit() {
    if (this.subscription == null) this.discard();
    else this.mode = 'display';
  }

  discard() {
    this.discard_event.emit();
  }
  delete() {
    this.delete_event.emit(this.subscription);
  }
  edit() {
    this.edit_event.emit(this.form.value);
  }

  addCountry(ISO: string) {
    ISO = ISO.toUpperCase();
    const country = this.UnselectedCountries.find(
      (_country) => _country.ISO === ISO,
    );
    if (!country) {
      return;
    }
    const selected = this.SelectedCountries.concat(country);
    this.form.get('countries').setValue(selected);
    this.countries_auto_ctrl.setValue('');
  }
  removeCountry(ISO: string) {
    ISO = ISO.toUpperCase();
    const selected = this.SelectedCountries.concat();
    const index = selected.findIndex((country) => country.ISO === ISO);
    if (index === -1) {
      return;
    }
    selected.splice(index, 1);
    this.form.get('countries').setValue(selected);
  }

  private _updateMode() {
    this.mode = this.subscription ? 'edit' : this.mode;
  }
  private _patchForm() {
    if (!this.subscription) {
      return;
    }
    const fv: Partial<SubscriptionFormValue> = {
      allowance: this.subscription.allowanceTime,
      duration: this.subscription.duration,
      countries: this.subscription.countries,
      login_times: this.subscription.login_times,
      descriptionAr: this.subscription.descriptionAr,
      descriptionEn: this.subscription.descriptionEn,
      descriptionFr: this.subscription.descriptionFr,
      max_active_tokens: this.subscription.max_active_tokens,
      nameEn: this.subscription.nameEn,
      nameAr: this.subscription.nameAr,
      nameFr: this.subscription.nameFr,
      discounts: this.subscription.pricings_discounts.map(
        ({ type, discount, count }) => ({ count, discount, type }),
      ),
    };
    this.subscription.pricings.forEach((pricing) => {
      fv[pricing.recital] = fromPricing(pricing);
    });
    this.form.patchValue(fv);

    function fromPricing(pricing: SubscriptionPricing): RecitalFormValue {
      return {
        default: pricing.enabled_by_default,
        price: pricing.price,
      };
    }
  }

  private _getCountryFilterFor(search: string) {
    return (country: Country) => {
      return (
        country.name.toLowerCase().includes(search.toLowerCase()) ||
        country.ISO.toLowerCase().includes(search.toLowerCase())
      );
    };
  }

  get SelectedCountries(): Country[] {
    return this.form.get('countries').value;
  }

  get UnselectedCountries(): Country[] {
    const selected_ISOs = this.SelectedCountries.map(({ ISO }) => ISO);
    return this._countries.filter(({ ISO }) => !selected_ISOs.includes(ISO));
  }

  get Recitals(): Recital[] {
    return Object.values(Recital);
  }

  get DiscountsCtrl(): FormArray {
    return this.form.get('discounts') as FormArray;
  }

  get $Discount(): typeof DiscountType {
    return DiscountType;
  }
}
export interface RecitalFormValue {
  default: boolean;
  price: number;
}

export interface PricingDiscountFormValue {
  count: number;
  discount: number;
  type?: DiscountType;
}
