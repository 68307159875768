import { CountryDTO } from 'core/gateways/country/country-dto';
import { Country } from './country';
import { Gender } from './gender';
import { UserRoles } from './user-role';
import { UserSubscription } from './user-subscription';
import { UserToken } from './userToken';
import { environment } from 'environments/environment';

export class User {
  id!: number;
  username!: string;
  photo!: string;
  gender!: Gender;
  email!: string;
  is_active!: boolean;
  subscriptions?: UserSubscription[];
  tokens!: UserToken[] | undefined;
  role?: UserRoles;
  countryId!: number;
  subscriptionType!: 'free' | 'premium' | 'discount' | 'free-pass';

  country?: Country;
  phonenumber?: string;

  public static fromDTO(json: UserDTO): User {
    const user = new User();
    user.id = json.id;
    user.username = json.userName;
    user.photo = environment.server + '/users/resource' + json.photo;
    user.gender = json.gender;
    user.email = json.email;
    user.is_active = json.isActive;
    user.subscriptions = json.userSubscriptions;
    user.tokens = json.userTokens
      ? UserToken.fromDTO(json.userTokens)
      : undefined;
    user.role = json.role;
    user.country = json.country ? Country.fromDto(json.country) : undefined;
    user.countryId = json.countryId;
    user.phonenumber = json.phonenumber;
    user.subscriptionType = json.subscriptionType ?? 'free';
    return user;
  }
  public static fromDTOArray(jsons: UserDTO[]): User[] {
    return jsons.map((json) => User.fromDTO(json));
  }
  public static toDTO(user: Partial<User>): UserDTO {
    return {
      id: user.id,
      email: user.email,
      gender: user.gender,
      isActive: user.is_active,
      photo: user.photo,
      userName: user.username,
      role: user.role,
      userSubscriptions: user.subscriptions,
      userTokens: user.tokens,
      countryId: user.countryId,
      phonenumber: user.phonenumber,
      country: {
        nameEn: user.country?.name,
        nameAr: user.country?.name,
        id: user.country?.id,
        ISO: user.country?.ISO,
      },
    };
  }

  public toDTO(): UserDTO {
    return User.toDTO(this);
  }
}

export interface UserDTO {
  id: number;
  userName: string;
  photo: string;
  gender: Gender;
  email: string;
  isActive: boolean;
  userSubscriptions: any;
  userTokens: any;
  role: UserRoles;
  phonenumber?: string;
  countryId: number;
  country?: CountryDTO.Base;
  subscriptionType?: 'free' | 'premium' | 'discount' | 'free-pass';
}
