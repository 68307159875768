<div class="box">
    <div class="actions">
        <button (click)="onSelect()" class="action" nbButton status="success">
            <nb-icon icon="eye"></nb-icon>
        </button>
        <button (click)="onDelete()" class="action" nbButton status="danger">
            <nb-icon icon="trash-2"></nb-icon>
        </button>
    </div>
    <div class="forms" [formGroup]='duration_group'>
        <div style="flex-grow: 1; margin: 2.5px;">
            <input formControlName="start" type="number" fullWidth nbInput placeholder="start">
        </div>
        <div style="flex-grow: 1; margin: 2.5px;">
            <input formControlName="end" type="number" fullWidth nbInput placeholder="end">
        </div>
    </div>
</div>