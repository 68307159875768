import { NgModule } from '@angular/core';
import { NbMenuModule } from '@nebular/theme';
import { ThemeModule } from 'app/@theme/theme.module';
import { NgxMainContainersModule } from './containers/main.containers.module';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { NgxMainRouting } from './main.routing';

@NgModule({
  declarations: [MainLayoutComponent],
  imports: [NgxMainRouting, NgxMainContainersModule, ThemeModule, NbMenuModule],
})
export class NgxMainModule {}
