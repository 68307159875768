import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Country } from 'models/country';
import { User } from 'models/user';

import { ROLE } from 'types/roles';

@Component({
  selector: 'fourqan-users-prime-table',
  templateUrl: './users-prime-table.component.html',
  styleUrls: ['./users-prime-table.component.scss'],
})
export class UsersPrimeTableComponent implements OnChanges {
  @Input() usersTotalCount: number = 200;
  @Input() data: User[] = [];
  @Input() loading: boolean = false;
  @Input() countries: Array<Country> = [];

  @Output() pageEvent = new EventEmitter<{ limit: number; skip: number }>();
  @Output() nameEvent = new EventEmitter<string | null>();
  @Output() roleEvent = new EventEmitter<ROLE | string | null>();
  @Output() countryEvent = new EventEmitter<Country | null>();
  @Output() editEvent = new EventEmitter<Partial<User>>();

  editing: boolean = false;

  // editing vars
  id: number | null = null;
  userEditForm: FormGroup;
  roles: Array<ROLE> = ['teacher', 'admin', 'editor', 'super_admin', 'user'];
  rolesFilter: Array<ROLE | string> = [
    'Role',
    'teacher',
    'admin',
    'editor',
    'super_admin',
    'user',
  ];

  filteredUserName: string | null = null;
  filteredRole: ROLE | null = null;
  filteredCountry: Country | null = null;

  readonly COLUMNS: Array<string> = [
    'Name',
    'Email',
    'Phone',
    'Country',
    'Role',
    'Gender',
  ];

  constructor(
    private readonly _fb: FormBuilder,
    private readonly _router: Router,
  ) {
    this.userEditForm = this._fb.group({
      name: ['', [Validators.required, Validators.minLength(3)]],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required, Validators.minLength(10)]],
      country: [0, [Validators.required]],
      role: ['', [Validators.required]],
      gender: ['', [Validators.required]],
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['countries']) {
      this.countries = [
        {
          id: 0,
          name: 'Country',
          ISO: '',
        },
        ...(changes['countries'].currentValue ?? []),
      ];
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public changePage(pageObject: any): void {
    this.pageEvent.emit({ limit: pageObject.rows, skip: pageObject.first });
  }

  public changeCountry(country: Country): void {
    this.countryEvent.emit(
      country?.name === 'Country' || !country ? null : country,
    );
  }

  public changeRole(role: ROLE | string): void {
    this.roleEvent.emit(role === 'Role' || !role ? null : role);
  }

  public changeName(name: string | null): void {
    this.nameEvent.emit(name);
  }

  public onRowEditInit(user: Partial<User>): void {
    this.editing = true;
    this.id = user.id;
    this.userEditForm.controls['name'].setValue(user.username ?? '');
    this.userEditForm.controls['phone'].setValue(user.phonenumber ?? '');
    this.userEditForm.controls['email'].setValue(user.email ?? '');
    this.userEditForm.controls['country'].setValue(user.country ?? '');
    this.userEditForm.controls['role'].setValue(user.role);
    this.userEditForm.controls['gender'].setValue(user.gender ?? 'male');
  }

  public onRowEditCancel(): void {
    this.editing = false;
    this.id = null;
    this.userEditForm.reset();
  }

  public onRowEditSave(): void {
    this.editing = false;
    const form = this.userEditForm.value;

    const user: Partial<User> = {
      id: this.id,
      countryId: form.country.id,
      email: form.email,
      gender: form.gender,
      phonenumber: form.phone,
      role: form.role,
      username: form.name,
    };
    this.editEvent.emit(user);

    this.id = null;
  }

  public onRowSelect(user: User): void {
    this._router.navigate([`users/${user.id}`]);
  }
}
