import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NbButtonModule, NbIconModule } from '@nebular/theme';
import { ImagePickerComponent } from './image-picker.component';

@NgModule({
  declarations: [ImagePickerComponent],
  imports: [CommonModule, NbButtonModule, NbIconModule],
  exports: [ImagePickerComponent],
})
export class ImagePickerModule {}
